import React, { Component } from 'react';

import { connect } from 'react-redux';

import { Modal, Col, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import LoadingButton from '@mui/lab/LoadingButton';
import ContractSheetCheck from './ContractSheetCheck';
import ContractSheetDataCheck from './ContractSheetDataCheck';
import ContractFileUploads from './ContractFileUploads';
import SwapHorizontalCircleIcon from '@mui/icons-material/SwapHorizontalCircle';
import Dropzone from 'react-dropzone';
import Grid from '@mui/material/Grid';
// import CustomerInfoEdit from './CustomerInfoEditComponent';
// import PdfReadComponent from '../Pdf/PdfReadComponent';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CloseIcon from '@mui/icons-material/Close';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import IconButton from '@mui/material/IconButton';
import InputLabel from '@mui/material/InputLabel';
import * as Contracts from './Saga/Contracts';
// import * as Customers from '../../Store/Customers';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import AddCircleIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircleOutline';
import FormControl from '@mui/material/FormControl';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Tooltip from '@mui/material/Tooltip';
// import './Css/Contracts.css';
var moment = require('moment-timezone');
const zoomlist=[
    {value:75, label:"75%"},
    {value:100, label:"100%"},
    {value:125, label:"125%"},
    {value:150, label:"150%"},
    {value:200, label:"200%"},
    {value:300, label:"300%"},
    {value:400, label:"400%"},
  ]
class ReSend extends Component {

  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      numPages: 1,
      rotate: 0,
      scale: 125,
      confirm: false,
      sheet:0,
      check:true,
      base64:null,
      typeset:null,
      buttonState:true,
      sendModal:false,
      switch:this.props.rent_contract_details ? this.props.rent_contract_details.settlement_lock : false,
      contract_files:null,
      uploadFile:undefined,
      swapFiles:false,
      name:'',
      dispSwitch:false,
      dispSwitchIndex:0
    };
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.numPages = this.numPages.bind(this);
    this.ChangeButtonClick = this.ChangeButtonClick.bind(this);
    this.SendButtonClick = this.SendButtonClick.bind(this);
    this.CloseButtonClick = this.CloseButtonClick.bind(this);
    this.filesGet = this.filesGet.bind(this);
    this.checkState = this.checkState.bind(this);
    this.handleZoom = this.handleZoom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.fileupload = this.fileupload.bind(this);
    this.getupload = this.getupload.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.addFiles = this.addFiles.bind(this)
    this.contract_DelFiles = this.contract_DelFiles.bind(this)
    this.contract_ViewFiles = this.contract_ViewFiles.bind(this)
  }
  componentDidMount() {
    if(this.props.check_contract_sheet_file && !this.props.check_contract_sheet_file[0]){
        this.setState({swapFiles:true})
    }
  }
  componentDidUpdate(prevProps) {
    console.log(prevProps,"props")
    if(this.props.check_contract_sheet){
        if(prevProps.check_contract_sheet_file.length < this.props.check_contract_sheet_file.length){
        }else if (prevProps.check_contract_sheet_file.length < this.props.check_contract_sheet_file.length) {
            var sheet = this.state.sheet+1
            this.setState({ sheet: sheet })
            this.setState({ scale: 125 })
            this.setState({ rotate: 0 })
            this.setState({ page: 1 })
        }else if(prevProps.check_contract_sheet_file.length > this.props.check_contract_sheet_file.length){
            var sheet = this.state.sheet > 1 ? this.state.sheet - 1 : 1
            this.setState({ sheet: sheet })
            this.setState({ scale: 125 })
            this.setState({ rotate: 0 })
            this.setState({ page: 1 })
        }
    }
  }
  addFiles(){
    this.setState({
        swapFiles:true,
        sheet:this.state.sheet + 1,
        name: ''
    });
    this.props.contract_AddFiles(this.state.sheet);
  }
  change_new(e) {
    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  SendButtonClick(){
    this.setState({sendModal: false})
    this.props.resend_contract_sheet();
  }
  CloseButtonClick(){
    this.setState({ sheet: 0 })
    this.props.change_check_contract_send_mail({from:'',to:'',cc:'',bcc:'',subject:'',body:''})
    this.props.change_check_contract_send_mail_confirm({from:'',to:'',cc:'',bcc:'',subject:'',body:''})
    this.props.change_check_contract_send_mail_template({})
    this.props.change_check_contract_sheet(false)
  }
  handleButtonClick(page) {
    this.setState({ page })
  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  checkState(state){
    this.setState({check : state})
  }
  handleChange(state){
    this.props.change_settlement_lock(state.target.checked);
    this.setState({switch : state.target.checked})
  }
  handleResend(payment){
    if(payment == 4){
        console.log(this.props.rent_contract_details.counting)
        var counting = this.props.rent_contract_details.counting
        var check_contract_sheet_text;
        if(counting == 1){
            if(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814){
                this.props.contracts_sheet_check();
            }else{
                check_contract_sheet_text = this.props.check_contract_sheet_text
                check_contract_sheet_text.name = this.props.rent_contract_details.name1
                check_contract_sheet_text.title = '賃貸借契約書'
                check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
                check_contract_sheet_text.company = ''
                check_contract_sheet_text.message = '重要事項説明後、契約内容をご確認の上、署名・捺印よろしくお願いいたします。'
                this.props.change_check_contract_sheet_text(check_contract_sheet_text);
                this.props.contracts_sheet_check();
            }
        }else if(counting == -1 || counting == 2 || counting == 6){
            check_contract_sheet_text = this.props.check_contract_sheet_text
            check_contract_sheet_text.name = this.props.rent_contract_details.name1
            check_contract_sheet_text.title = '駐車場賃貸借契約書'
            check_contract_sheet_text.Mail = this.props.rent_contract_details.mail1
            check_contract_sheet_text.company = ''
            check_contract_sheet_text.message = 'メール受信後２日以内に契約書に入力してください。'
            this.props.change_check_contract_sheet_text(check_contract_sheet_text);
            this.props.contracts_resend_parking({responses_id:this.props.rent_contract_details.rent_response_id,payment:payment})
            
        }
    //    var check_contract_sheet_text = this.props.check_contract_sheet_text
    //    check_contract_sheet_text.name = this.props.rent_contract_details.contractor_name1
    //    check_contract_sheet_text.title = '駐車場賃貸借契約書'
    //    check_contract_sheet_text.Mail = this.props.rent_contract_details.contractor_mail1
    //    check_contract_sheet_text.company = ''
    //    check_contract_sheet_text.message = 'メール受信後２日以内に契約書に入力してください。'
    //    this.props.change_check_contract_sheet_text(check_contract_sheet_text)

        
    }else{
        this.props.contracts_resend_parking({responses_id:this.props.rent_contract_details.rent_response_id,payment:payment})
    }
    
  }
  numPages(state) {
    this.setState({ numPages:state })
  }
  ChangeButtonClick(value){
    this.setState({ name: this.props.check_contract_sheet_file[value].file_name })
    this.setState({ sheet: value })
    this.setState({ scale: 125 })
    this.setState({ rotate: 0 })
    this.setState({ page: 1 })
    this.filesGet(value)
  }
  filesGet(state){
    if(this.props.check_contract_sheet_file.length > 0){
        if(this.props.check_contract_sheet_file[state].file){
            const reader = new FileReader()
            reader.readAsDataURL(this.props.check_contract_sheet_file[state].file)
            reader.onload = () => {
                this.setState({
                base64: reader.result,
                })
            }
        }else{
            this.setState({swapFiles:true})
            this.setState({name:''})

        }
    }
  }
  handleZoom(type){
    var index = zoomlist.findIndex(a=>a.value == this.state.scale)
    if(type == 1){
      this.setState({ scale: zoomlist[index - 1].value })
    }else if(type == 2){
      this.setState({ scale: zoomlist[index + 1].value })
    }
  }
  handleFileChange(key){
    this.setState({sheet: key})
    this.setState({rotate: 0})
    this.setState({scale: 125})
    this.setState({ page: 1 })
  }
  fileupload(){
    if(this.state.getuploadfile){
        var upfile = {}
        upfile.file = this.state.getuploadfile
        upfile.file_name = this.props.rent_contract_details.id + '_賃貸借契約書'
        upfile.type = "賃貸借契約書"
        var files = this.props.check_contract_sheet_file
        
        files.splice(1,0,upfile)
        
        this.props.change_check_contract_sheet_file(files)
        this.props.change_contract_upload_files_modal(false)
    }
  }
  getupload(a){
    this.setState({getuploadfile:a})
  }
  onDrop = (files) => {
    console.log(files)
    const file = files[0]
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => {
      this.setState({
        base64: reader.result,
        name: file.name,
        swapFiles:false
      })
    }

    let sendfile = [];
    sendfile.file = files;
    if(files){
        console.log('ファイル変更')
        const blob = new Blob([files[0]], { type: files[0].type });
        console.log('Blob:', blob);
        console.log('Sheet:', this.state.sheet);
        let check_contract_sheet_file = this.props.check_contract_sheet_file
        if(!check_contract_sheet_file[0] || !check_contract_sheet_file[0].file_name){
            check_contract_sheet_file[0] = {file:blob,file_name:file.name.replace(/\.[^/.]+$/, "")}
        }else{
            check_contract_sheet_file.push({file:blob,file_name:file.name.replace(/\.[^/.]+$/, "")})
        }
        this.props.change_check_contract_sheet_file(check_contract_sheet_file)
        // this.props.contract_file_upload({blob:blob,sheet:this.state.sheet,check_contract_sheet_file:this.props.check_contract_sheet_file,name:file.name.replace(/\.[^/.]+$/, "")}) 
        this.setState({uploadFile:files})
    }
  };
  contract_DelFiles(index,file){
    let check_contract_sheet_file = file
    let array = check_contract_sheet_file.filter((v,i) => i !== index);
    this.props.change_check_contract_sheet_file(array)
  }
  contract_ViewFiles(index){
    
    this.setState({
        dispSwitch:!this.state.dispSwitch,
        dispSwitchIndex:index
    })
  }

  render() {
    let ver = []
    for (var i = 1; i <= this.props.rent_contract_details.ver; i++) {
      ver.push(i)
    }
    //PDF確認証プレビュー　ボタン　送信済み契約書類の確認
    const PDFPreview = () => (<Grid xs={3} md={3} item className="item-outer-outer" >
        <Card>    
            <CardContent>
            {this.props.check_contract_sheet_file.map((value, key) => <div className="selectFiles" onClick={()=> this.handleFileChange(key)}>{value.file_name}</div>)}
            </CardContent>
        </Card>
    </Grid>)
    //法人用　ファイルアップロード
    const CorpContract = () => (
        <div style={{ height: "100%", margin: 5 }}>
            <Dropzone
                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ height: 100 }}>
                    <p>{"ファイル添付　クリックまたはドラッグ&ドロップ"}</p>
                    <ul style={{ padding: 0, marginTop: 3 }}  >
                    </ul>
                    </div>
                </div>
                )}
            </Dropzone>
            <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {this.props.check_contract_sheet_file.length ? this.props.check_contract_sheet_file.map((value,index) => {console.log(value,"データ");return (value.file_name ? 
                    <ListItem
                        key={value}
                        disableGutters
                        secondaryAction={
                            <IconButton aria-label="comment"sx={{
                                backgroundColor: 'transparent',
                                '&:hover': {
                                    backgroundColor: '#CCC', // ホバー時の背景色
                                },
                            }}>
                                <CloseIcon onClick={()=>this.contract_DelFiles(index,this.props.check_contract_sheet_file)}/>
                            </IconButton>
                        }
                        sx={{
                            '&:hover': {
                            backgroundColor: '#EEE', // ホバー時の背景色
                            }
                        }}
                        >
                        <ListItemText primary={<><IconButton aria-label="comment"sx={{
                            backgroundColor: 'transparent',
                            '&:hover': {
                                backgroundColor: '#CCC', // ホバー時の背景色
                            },
                        }}>
                            <InsertDriveFileIcon onClick={()=>this.contract_ViewFiles(index)}/>
                        </IconButton>{value.file_name}</>} />
                    </ListItem>
                :'')}):''}
            </List>
        </div>
    )
    //法人用　送信基準
    const CorpSendContract = () => (
        <ModalFooter>
            <div style={{width:'100%'}}>
                <Button variant="contained" onClick={()=>this.CloseButtonClick()} color="inherit" style={{ float: "left", margin: 5 }}>
                閉じる
                </Button>
                <Button variant="contained" onClick={()=>this.setState({sendModal: true})} color="primary" disabled={!(this.props.check_contract_sheet_text.procedureNum && this.props.check_contract_sheet_file && this.props.check_contract_sheet_file.length !== 0 && this.props.check_contract_sheet_file[0].file_name)}
                style={{ float: "right", margin: 5 }}>
                    送信
                </Button>
            </div>
        </ModalFooter>
    )
    return (
      <div>
        {this.props.rent_contract_details.settlement_lock != undefined ?
        // this.props.rent_contract_details.counting === 2 || Number(this.props.rent_contract_details.room_id) !== -1 ?
        //     this.props.rent_contract_details.apply_payment_type === 2 || this.props.rent_contract_details.month_payment_type === 2 ?
                <FormGroup>
                    <FormControlLabel control={<Switch defaultChecked={this.props.rent_contract_details.settlement_lock} checked={this.state.switch} onChange={(e)=>this.handleChange(e)} color="primary" />} label={this.props.rent_contract_details.settlement_lock ? "クレジット決済のロック中" : "クレジット決済のロック解除"} />
                </FormGroup>
            : ''
        // : ''
       }
        <br/>
    　 {this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.counting ===2 ? Number(this.props.rent_contract_details.room_id) !== -1 ?
        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" 
        disabled={this.props.rent_contract_details.apply_payment_type === 2 || this.props.rent_contract_details.month_payment_type === 2 ? false : true} color="primary" onClick={()=>this.handleResend(0)}
        >クレジット再登録{"(月々のみ)"}</Button>
         : '' : ''
       }
       {this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.counting ===2 ? Number(this.props.rent_contract_details.room_id) !== -1 ?
        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" 
        disabled={this.props.rent_contract_details.apply_payment_type === 2 || this.props.rent_contract_details.month_payment_type === 2 ? false : true} color="primary" onClick={()=>this.handleResend(2)}
        >クレジット再送{"(初期費用引落込み)"}</Button>
         : '' : ''
       }
       {this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.counting ===2 ? Number(this.props.rent_contract_details.room_id) !== -1  ?
        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={()=>this.handleResend(3)}
        disabled={this.props.rent_contract_details.month_payment_type === 3 ? false : true}>口座振替再送</Button>
         : '' : ''
       }
       {this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.counting ===2 ? Number(this.props.rent_contract_details.room_id) !== -1 ?
        <Button style={{ float: "right", margin: 5 }} size="small" variant="contained" color="primary" onClick={()=>this.handleResend(1)}
        disabled={this.props.rent_contract_details.apply_payment_type === 1 || this.props.rent_contract_details.month_payment_type === 1 ? false : true}
        >請求書  再送</Button>
         : '' : ''
       }
       <Modal fade={false} isOpen={this.props.check_contract_sheet} toggle={() => this.CloseButtonClick()} size="xxl" style={{minHeight:'90%'}} >
       <ModalHeader toggle={() => { this.props.change_check_contract_sheet(false);}}>契約書確認
        
        </ModalHeader>
        <ModalBody>
        <Grid container spacing={2}>

        {
            this.props.cloudsign_document_id != null ? 
          <PDFPreview/>
          
        :""
        }
          <Grid xs={this.props.cloudsign_document_id != null ? 9 : 7} md={this.props.cloudsign_document_id != null ? 9 : 7} item className="item-outer-outer" style={{ paddingRight: 0 }}>

            <Card>
                <CardContent>
                {
                    this.props.check_contract_sheet_file && (this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814)? 

                        this.state.dispSwitch ? 
                        <ContractSheetCheck dispSwitch={this.state.dispSwitch} switch={this.contract_ViewFiles} numPages={this.numPages} scale={this.state.scale} page={this.state.page} rotate={this.state.rotate} sheet={this.state.sheet} base64={this.state.base64} check_contract_sheet_file_data={this.props.check_contract_sheet_file[this.state.dispSwitchIndex]}></ContractSheetCheck>
                        :<CorpContract/>
                     : 
                (this.props.check_contract_sheet_file[this.state.sheet]  ? 
                    <div style={{ overflow: 'auto', height: window.innerHeight-(this.props.cloudsign_document_id != null ? 210 : 280), textAlign:"center" }}>
                    <ContractSheetCheck numPages={this.numPages} scale={this.state.scale} page={this.state.page} rotate={this.state.rotate} sheet={this.state.sheet} base64={this.state.base64} check_contract_sheet_file_data={this.props.check_contract_sheet_file[this.state.sheet]}></ContractSheetCheck>
                    </div> : '')
                }
                {
                    this.props.check_contract_sheet_file && this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ? 
                
                    this.state.dispSwitch ? 
                    <div style={{textAlign:"center", padding:10, backgroundColor:"white"}}>
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page <= 1}
                    onClick={() => this.handleButtonClick(this.state.page - 1)}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                  {this.state.page || 1} / {this.state.numPages || '-'}
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page >= this.state.numPages || !this.state.numPages}
                    onClick={() => this.handleButtonClick(this.state.page + 1)}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>

                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate - 90)}
                    size="large">
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate + 90)}
                    size="large">
                    <RotateRightIcon />
                  </IconButton>
                  <IconButton
                    disabled={!(this.state.scale > 75)}
                    onClick={(e)=>this.handleZoom(1)}
                    style={{marginLeft:15}}
                    size="large">
                  <RemoveCircleIcon />
                  </IconButton>
                  <FormControl variant="outlined" style={{ marginTop: 5 }}>
                    <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
                    <Select
                      style={{ width: 90, }}
                      value={this.state.scale}
                      onChange={(e) => this.setState({ scale: e.target.value })}
                      label="ズーム"
                      margin='dense'
                    >
                      {zoomlist.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

                    </Select>
                  </FormControl>
                  <IconButton
                    disabled={!(this.state.scale < 400)}
                    onClick={(e)=>this.handleZoom(2)}
                    size="large">
                  <AddCircleIcon />
                  </IconButton>

                  <Tooltip title="ファイルプレビューをやめる">
                  <IconButton
                    onClick={(e)=>this.contract_ViewFiles(0)}
                    size="large">
                  <SwapHorizontalCircleIcon />
                  </IconButton>
                  </Tooltip>

                </div>
                :''
                     : this.props.check_contract_sheet_file ? 
                <div style={{textAlign:"center", padding:10, backgroundColor:"white"}}>
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page <= 1}
                    onClick={() => this.handleButtonClick(this.state.page - 1)}
                    size="large">
                    <ChevronLeftIcon />
                  </IconButton>
                  {this.state.page || 1} / {this.state.numPages || '-'}
                  <IconButton
                    aria-label="left"
                    disabled={this.state.page >= this.state.numPages || !this.state.numPages}
                    onClick={() => this.handleButtonClick(this.state.page + 1)}
                    size="large">
                    <ChevronRightIcon />
                  </IconButton>

                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate - 90)}
                    size="large">
                    <RotateLeftIcon />
                  </IconButton>
                  <IconButton
                    aria-label="left"
                    onClick={() => this.handleRotateClick(this.state.rotate + 90)}
                    size="large">
                    <RotateRightIcon />
                  </IconButton>
                  <IconButton
                    disabled={!(this.state.scale > 75)}
                    onClick={(e)=>this.handleZoom(1)}
                    style={{marginLeft:15}}
                    size="large">
                  <RemoveCircleIcon />
                  </IconButton>
                  <FormControl variant="outlined" style={{ marginTop: 5 }}>
                    <InputLabel id="demo-simple-select-outlined-label">ズーム</InputLabel>
                    <Select
                      style={{ width: 90, }}
                      value={this.state.scale}
                      onChange={(e) => this.setState({ scale: e.target.value })}
                      label="ズーム"
                      margin='dense'
                    >
                      {zoomlist.map((value, key) => <MenuItem key={key} value={value.value}>{value.label}</MenuItem>)}

                    </Select>
                  </FormControl>
                  <IconButton
                    disabled={!(this.state.scale < 400)}
                    onClick={(e)=>this.handleZoom(2)}
                    size="large">
                  <AddCircleIcon />
                  </IconButton>

                </div>
                : ""}
                </CardContent>
            </Card>
          </Grid>
          {
            this.props.cloudsign_document_id == null ? 
          <Grid xs={5} md={5} item className="item-outer-outer" >
            <Card>    
                <CardContent>
                    <ContractSheetDataCheck checkState={this.checkState} sheet={this.state.sheet} ChangeButtonClick={this.ChangeButtonClick}/>
                </CardContent>
            </Card>
          </Grid>
          
        :""
        }
        </Grid>
        </ModalBody>
        {
            this.props.cloudsign_document_id == null ? 
            (this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814)? 
        <CorpSendContract/>
        :
        (this.state.sheet + 1) == this.props.check_contract_sheet_file.length ?
        
        <ModalFooter>
            <div style={{width:'100%'}}>
              {this.state.sheet > 0 && (this.state.sheet+1) == this.props.check_contract_sheet_file.length ? 
            <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet-1)} color="secondary" style={{ float: "left", margin: 5 }}>
                前へ
            </Button>
            :
            <Button variant="contained" onClick={()=>this.CloseButtonClick()} color="inherit" style={{ float: "left", margin: 5 }}>
              閉じる
            </Button>}
              <Button variant="contained" onClick={()=>this.setState({sendModal: true})} color="primary" 
              disabled={!this.state.check } 
            style={{ float: "right", margin: 5 }}>
                送信
              </Button>
              </div>
        </ModalFooter>
        :
        <ModalFooter>
            <div style={{width:'100%'}}>
                <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet+1)} color="secondary" style={{ float: "right", margin: 5 }}>
                    次へ
                </Button>
                {this.state.sheet > 0 && this.props.check_contract_sheet_file.length > 1 ? 
                <Button variant="contained" onClick={()=>this.ChangeButtonClick(this.state.sheet-1)} color="secondary" style={{ float: "left", margin: 5 }}>
                    前へ
                </Button>
                :<Button variant="contained" onClick={()=>this.CloseButtonClick()} color="inherit" style={{ float: "left", margin: 5 }}>
                閉じる
            </Button>}
            </div>
        </ModalFooter>
        :""
        }
        </Modal>
        <Modal fade={false} isOpen={this.state.sendModal} toggle={() => { this.setState({sendModal: false})}} size="lg" style={{minHeight:'90%',transform:'translate(0, 0)',display:'flex',alignItems: 'center',justifyContent: 'center'}} >
       <ModalHeader toggle={() => { this.setState({sendModal: false})}}>契約書送信
        
        </ModalHeader>
        <ModalBody>
            <p style={{fontSize:'16px'}}>{this.props.check_contract_sheet_text.name}様宛て、{this.props.check_contract_sheet_text.Mail}へ電子契約を送信します。</p>
            <p style={{fontSize:'16px'}}>この内容でよろしければ、送信ボタンを押してください。</p>
        </ModalBody>
        <ModalFooter>
        <div style={{width:'100%'}}>
            <Button variant="contained" onClick={()=>this.SendButtonClick()} color="primary" style={{ float: "right", margin: 5 }}>
                送信
            </Button>
            <Button variant="contained" onClick={()=>this.setState({sendModal: false})} color="secondary" style={{ float: "left", margin: 5 }}>
                閉じる
            </Button>
              </div>
        </ModalFooter>
        </Modal>      
        {/* <Modal fade={false} isOpen={this.props.contract_upload_files_modal} toggle={() => { this.props.change_contract_upload_files_modal(false);this.props.change_check_contract_sheet(false);}} size="xxl" style={{minHeight:'90%',transform:'translate(0, 0)',display:'flex',alignItems: 'center',justifyContent: 'center',width:'700px'}} >
            <ModalHeader toggle={() => { this.props.change_contract_upload_files_modal(false);this.props.change_check_contract_sheet(false);}}>契約書アップロード
            </ModalHeader>
            <ModalBody>
                <p>契約書の添付をお願いします。</p>
                <ContractFileUploads file_upload={(file) => this.getupload(file)}/>
            </ModalBody>
            <ModalFooter>
                <div style={{width:'500'}}>
                <Button variant="contained" onClick={()=>this.fileupload()} color="primary" style={{ float: "right", margin: 5 }}>
                    アップロード
                </Button> 
                </div>
            </ModalFooter>
        </Modal> */}
      </div>
    );
  }
}


//コンテナ
const mapStateToProps = state => {
  return {

    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    customerdetail: state.customersReducer.customerdetail,
    responsedetail: state.responsesReducer.responsedetail,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_details_old: state.contracts.rent_contract_details_old,
    rent_contract_details_new: state.contracts.rent_contract_details_new,
    rent_contract_logs_new: state.contracts.rent_contract_logs_new,
    rent_contract_logs_old: state.contracts.rent_contract_logs_old,
    contracts_cancel_confirm: state.contracts.contracts_cancel_confirm,
    check_contract_sheet: state.contracts.check_contract_sheet,
    check_contract_button:state.contracts.check_contract_button,
    check_contract_sheet_text:state.contracts.check_contract_sheet_text,
    check_contract_sheet_file:state.contracts.check_contract_sheet_file,
    cloudsign_document_id:state.contracts.cloudsign_document_id,
    contract_upload_files_modal:state.contracts.contract_upload_files_modal,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    // change_rent_contract_logs_new(state) {
    //   dispatch(Contracts.change_rent_contract_logs_new(state))
    // },
    // change_rent_contract_logs_old(state) {
    //   dispatch(Contracts.change_rent_contract_logs_old(state))
    // },
    // change_rent_contract_logs_ver(state) {
    //   dispatch(Contracts.change_rent_contract_logs_ver(state))
    // },
    // contracts_send_parking(state) {
    //   dispatch(Contracts.contracts_send_parking(state))
    // },
    // download_docs(state) {
    //   dispatch(Contracts.download_docs(state))
    // },
    // download_bill(state) {
    //   dispatch(Contracts.download_bill(state))
    // },
    // download_contract(state) {
    //   dispatch(Contracts.download_contract(state))
    // },
    // change_contracts_cancel_confirm(state) {
    //   dispatch(Contracts.change_contracts_cancel_confirm(state))
    // },
    // contracts_cancel(state) {
    //   dispatch(Contracts.contracts_cancel(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
    
    change_settlement_lock(state){
        dispatch(Contracts.change_settlement_lock(state))
    },
    change_check_contract_sheet_text(state){
        dispatch(Contracts.change_check_contract_sheet_text(state))
    },
    change_check_contract_sheet(state) {
        dispatch(Contracts.change_check_contract_sheet(state))
      },
    contracts_resend_parking(state){
        dispatch(Contracts.contracts_resend_parking(state))
    },
    resend_contract_sheet(state){
        dispatch(Contracts.resend_contract_sheet(state))
    },
    contracts_send_parking(state) {
        dispatch(Contracts.contracts_send_parking(state))
    },
    contracts_sheet_check(state) {
        dispatch(Contracts.contracts_sheet_check(state))
    },
    change_contract_upload_files_modal(state){
        dispatch(Contracts.change_contract_upload_files_modal(state))
    },
    change_check_contract_sheet_file(state){
        dispatch(Contracts.change_check_contract_sheet_file(state))
    },
    change_check_contract_send_mail(state){
        dispatch(Contracts.change_check_contract_send_mail(state))
    },
    change_check_contract_send_mail_confirm(state){
        dispatch(Contracts.change_check_contract_send_mail_confirm(state))
    },
    change_check_contract_send_mail_template(state){
        dispatch(Contracts.change_check_contract_send_mail_template(state))
    },
    contract_file_upload(state){
        dispatch(Contracts.contract_file_upload(state))
    },
    // contract_AddFiles(state){
    //     dispatch(Contracts.contract_AddFiles(state))
    // },
    // contract_DelFiles(state){
    //     dispatch(Contracts.contract_DelFiles(state))
    // }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReSend);
