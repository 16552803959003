import React, { Component } from 'react';
import { connect } from 'react-redux';
import MaskedInput from 'react-text-mask';
import './Css/Grid.css'
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import CardHeader from '@mui/material/CardHeader';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/material/Checkbox';
import * as ContractFollow from './Saga/ContractFollow';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Tooltip from '@mui/material/Tooltip';
import Autocomplete from '@mui/material/Autocomplete';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import MaterialSelect from '@mui/material/Select';
import FollowCalendar from '../Calendar/FollowCalendar';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import * as Responses from '../ResponseDetail/Saga/Responses';
import * as Contracts from '../Contracts/Saga/Contracts';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import CheckIcon from '@mui/icons-material/Check';
import WebIcon from '@mui/icons-material/Web';



var moment = require('moment-timezone');
let columns = []
// Table data as a list of array.
function timeMask(value) {
  const chars = value.split('');
  const hours = [
    /[0-2]/,
    chars[0] == '2' ? /[0-3]/ : /[0-9]/
  ];

  const minutes = [/[0-5]/, /[0-9]/];

  return hours.concat(':').concat(minutes);
}





function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={timeMask}
      // placeholderChar={'\u2000'}
      showMask
    />
  );
}
class Editor extends Component {
  constructor(props) {
    super(props);



    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,

      cardx: window.innerWidth - 600,
      colSortDirs: {},
      focus_start: false,
      focus_end: false,
      validation: false,
      validation_text: '',
      ownshop: this.props.users.filter(a => a.amSectionId === this.props.user_details.amSectionId && a.id === this.props.contract_follow_editer.user_id)[0] ? true : false,
      first_date: this.props.contract_follow_editer.date
      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    // this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
    // this.contract_follow_editer = this.contract_follow_editer.bind(this);

    this.togglereserve = this.togglereserve.bind(this);


    this.handleOnChange = this.handleOnChange.bind(this);
    // this.contractdetail = this.contractdetail.bind(this);
    this.handle_change_user_id = this.handle_change_user_id.bind(this);
    this.handle_change_client_user_id = this.handle_change_client_user_id.bind(this);

    this.change_schedule = this.change_schedule.bind(this);

    this.today = this.today.bind(this);

    // this.change_datetime_start = this.change_datetime_start.bind(this);

    this.change_datetime_start_time = this.change_datetime_start_time.bind(this);

    this.change_datetime_end_time = this.change_datetime_end_time.bind(this);


    this.focus_start = this.focus_start.bind(this);
    this.blur_start = this.blur_start.bind(this);
    this.focus_end = this.focus_end.bind(this);
    this.blur_end = this.blur_end.bind(this);

    this.change_date = this.change_date.bind(this);

    this.change_datetime_start_from_list = this.change_datetime_start_from_list.bind(this);
    this.change_datetime_end_from_list = this.change_datetime_end_from_list.bind(this);


    this.change_ownshop = this.change_ownshop.bind(this);

    this.check_validation = this.check_validation.bind(this);
    this.open_customer_window = this.open_customer_window.bind(this)

    this.onChangeWebVideoCallReservationDetail = this.onChangeWebVideoCallReservationDetail.bind(this)
    this.insertWebVideoCallReservationDetailModal = this.insertWebVideoCallReservationDetailModal.bind(this)
    this.updateWebVideoCallReservationDetailModal = this.updateWebVideoCallReservationDetailModal.bind(this)
    
    this.handleClose = this.handleClose.bind(this)

    this.props.get_users_list()

    // alert(this.props.contract_follow_editer.section_id)
  }
  componentDidMount() {
    this.check_validation()
  }
  focus_start(e) {

    this.check_validation()
    this.setState({ focus_start: true })
  }
  blur_start(e) {

    setTimeout(() => {
      this.setState({ focus_start: false })
    }, 200)

  }
  focus_end(e) {
    console.log(e.target)

    this.setState({ focus_end: true })
  }
  blur_end(e) {
    setTimeout(() => {
      this.setState({ focus_end: false })
    }, 200)

  }

  check_validation() {

    let contract_follow_editer = this.props.contract_follow_editer
    let validation = true
    let validation_text = ''
    validation_text = contract_follow_editer.user_id ? validation_text + "" : validation_text + "担当を選択してください。"
    // validation_text = (contract_follow_editer.progress === 1 || contract_follow_editer.progress === 2) && !contract_follow_editer.date ? validation_text + "日付を選択してください。" : validation_text + ""
    // validation_text = contract_follow_editer.progress === 0 ? validation_text + "日付または状況を選択してください。" : validation_text + ""

    validation_text = (contract_follow_editer.datetime_start == null || (contract_follow_editer.datetime_start && moment(contract_follow_editer.datetime_start).isValid())) && (contract_follow_editer.datetime_end == null || (contract_follow_editer.datetime_end && moment(contract_follow_editer.datetime_end).isValid())) ? validation_text + "" : validation_text + "時間が不正です。"
    let v = 0
    if (!contract_follow_editer.user_id) {
      v = 1
    }

    // if ((contract_follow_editer.progress === 1 || contract_follow_editer.progress === 2) && !contract_follow_editer.date) {
    //   v = 1
    // }
    // if (contract_follow_editer.progress === 0) {
    //   v = 1
    // }
    // alert(contract_follow_editer.datetime_start)
    if (!((contract_follow_editer.datetime_start == null || (contract_follow_editer.datetime_start && moment(contract_follow_editer.datetime_start).isValid())) && (contract_follow_editer.datetime_end == null || (contract_follow_editer.datetime_end && moment(contract_follow_editer.datetime_end).isValid())))) {
      v = 1
    }
    if (v) {
      validation = false
    }


    this.setState({ validation: validation, validation_text: validation_text })

  }


  change_ownshop() {
    if (!this.state.ownshop) {
      if (this.props.users.filter(a => a.amSectionId === this.props.user_details.amSectionId && a.id === this.props.contract_follow_editer.user_id)[0]) {

      } else {
        let contract_follow_editer = this.props.contract_follow_editer
        contract_follow_editer.user_id = 0
        this.props.change_contract_follow_editer(contract_follow_editer)
      }
    }
    this.setState({ ownshop: !this.state.ownshop })
    this.check_validation()
  }


  handle_change_user_id(e, newValue) {

    if (newValue) {
      let contract_follow_editer = this.props.contract_follow_editer
      contract_follow_editer.user_id = newValue ? newValue.id : 0
      contract_follow_editer.section_id = this.props.users.filter(a => a.id == newValue.id)[0].amSectionId
      this.props.change_contract_follow_editer(contract_follow_editer)
    }
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }

  handle_change_client_user_id(e, newValue) {

      let contract_follow_editer = this.props.contract_follow_editer
      contract_follow_editer.client_user_id = newValue ? newValue.id : 0
      this.props.change_contract_follow_editer(contract_follow_editer)

    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }



  change_date(e) {
    // alert()
    let contract_follow_editer = this.props.contract_follow_editer

    contract_follow_editer.date = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    // contract_follow_editer.datetime_start = moment({ years: moment(e).tz("Asia/Tokyo").format('YYYY'), months: moment(e).tz("Asia/Tokyo").format('MM') - 1, days: moment(e).tz("Asia/Tokyo").format('DD'), hours: moment(contract_follow_editer.datetime_start).format('H'), minutes: moment(contract_follow_editer.datetime_start).format('m') }).format()
    // contract_follow_editer.datetime_end = moment({ years: moment(e).tz("Asia/Tokyo").format('YYYY'), months: moment(e).tz("Asia/Tokyo").format('MM') - 1, days: moment(e).tz("Asia/Tokyo").format('DD'), hours: moment(contract_follow_editer.datetime_end).format('H'), minutes: moment(contract_follow_editer.datetime_end).format('m') }).format()

    if (e !== null) {
      if (moment(e).format("YYYY/MM/DD") > moment().format("YYYY/MM/DD")) {
        contract_follow_editer.progress = 1
      } else {
        contract_follow_editer.progress = 2
        if (String(this.props.contract_follow_editer.rent_m_contract_follow_id) == "2") {
          // this.props.contract_follow_editer.way = -1
          this.props.contract_follow_editer.way = 0
        }

      }
    }

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  // change_datetime_start(e) {
  //   // alert(e.target.value)

  //   let contract_follow_editer = this.props.contract_follow_editer
  //   if (e.target.name == "datetime_start") {

  //     if (contract_follow_editer.datetime_start) {
  //       // alert("ari")
  //       contract_follow_editer.date = moment(contract_follow_editer.datetime_start).tz("Asia/Tokyo").format("YYYY/MM/DD")
  //       // alert(moment(contract_follow_editer.datetime_start).tz("Asia/Tokyo").format("YYYY/MM/DD"))
  //       contract_follow_editer.datetime_start = moment(moment(contract_follow_editer.datetime_start).tz("Asia/Tokyo").format("YYYY/MM/DD ") + e.target.value.split('_').join(0))
  //     } else {

  //       // alert(e.target.value.split('_').join(0))
  //       contract_follow_editer.date = moment().tz("Asia/Tokyo").format("YYYY/MM/DD")
  //       // contract_follow_editer.datetime_start = moment(moment().tz("Asia/Tokyo").format("YYYY/MM/DD ") + e.target.value.split('_').join(0))
  //       contract_follow_editer.datetime_start = moment(moment(contract_follow_editer.datetime_start).format("YYYY/MM/DD ") + e.target.value.split('_').join(0)).format('YYYY/MM/DD HH:mm')
  //     }

  //   } else {

  //     if (contract_follow_editer.datetime_end) {
  //       // alert("ari")
  //       contract_follow_editer.date = moment(contract_follow_editer.datetime_end).tz("Asia/Tokyo").format("YYYY/MM/DD")
  //       // alert(moment(contract_follow_editer.datetime_start).tz("Asia/Tokyo").format("YYYY/MM/DD"))
  //       // contract_follow_editer.datetime_end = moment(moment(contract_follow_editer.datetime_end).tz("Asia/Tokyo").format("YYYY/MM/DD ") + e.target.value.split('_').join(0))
  //       contract_follow_editer.datetime_end = moment(moment(contract_follow_editer.datetime_end).format("YYYY/MM/DD ") + e.target.value.split('_').join(0)).format('YYYY/MM/DD HH:mm')
  //     } else {

  //       // alert(e.target.value.split('_').join(0))
  //       contract_follow_editer.date = moment().tz("Asia/Tokyo").format("YYYY/MM/DD")
  //       contract_follow_editer.datetime_end = moment(moment().tz("Asia/Tokyo").format("YYYY/MM/DD ") + e.target.value.split('_').join(0))

  //     }
  //   }


  //   // contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
  //   this.props.change_contract_follow_editer(contract_follow_editer)
  //   this.setState({ reflesh: !this.props.reflesh })
  //   this.check_validation()
  // }

  change_datetime_start_time(value) {
// alert(value)
    let contract_follow_editer = this.props.contract_follow_editer
    // alert(moment(contract_follow_editer.datetime_end).isValid())

    contract_follow_editer.datetime_start = moment(this.props.contract_follow_editer.date).format("YYYY-MM-DD ") + value
    if (contract_follow_editer.datetime_end == null || !moment(contract_follow_editer.datetime_end).isValid() || moment(contract_follow_editer.datetime_end).isSame(moment(contract_follow_editer.datetime_start)) || moment(contract_follow_editer.datetime_end).isBefore(moment(contract_follow_editer.datetime_start))) {

      contract_follow_editer.datetime_end = contract_follow_editer.datetime_start ? moment(contract_follow_editer.datetime_start).add(30, 'm') : null
    }

    // contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  change_datetime_end_time(value) {

    let contract_follow_editer = this.props.contract_follow_editer

    contract_follow_editer.datetime_end = moment(this.props.contract_follow_editer.date).format("YYYY-MM-DD ") + value

    if (contract_follow_editer.datetime_start == null || !moment(contract_follow_editer.datetime_start).isValid() || moment(contract_follow_editer.datetime_start).isSame(moment(contract_follow_editer.datetime_end)) || moment(contract_follow_editer.datetime_start).isAfter(moment(contract_follow_editer.datetime_end))) {
      contract_follow_editer.datetime_start = contract_follow_editer.datetime_end ? moment(contract_follow_editer.datetime_end).add(-30, 'm') : null
    }
    // contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()

  }
  change_datetime_start_from_list(e) {

    let contract_follow_editer = this.props.contract_follow_editer

    let minu = 0
    if (contract_follow_editer.datetime_start && contract_follow_editer.datetime_end && moment(contract_follow_editer.datetime_start).format("YYYY/MM/DD") === moment(contract_follow_editer.datetime_end).format("YYYY/MM/DD")) {
      minu = moment(contract_follow_editer.datetime_end).diff(moment(contract_follow_editer.datetime_start), 'm')
    }
    contract_follow_editer.datetime_start = moment(moment(contract_follow_editer.date).format("YYYY/MM/DD " + e.label))
    if (minu <= 0) {
      contract_follow_editer.datetime_end = moment(contract_follow_editer.datetime_start).add(30, 'm')
    } else {
      contract_follow_editer.datetime_end = moment(contract_follow_editer.datetime_start).add(minu, 'm')

    }
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }
  change_datetime_end_from_list(e) {

    let contract_follow_editer = this.props.contract_follow_editer

    contract_follow_editer.datetime_end = moment(moment(contract_follow_editer.date).format("YYYY/MM/DD " + e.label))
    let minu = 0
    if (contract_follow_editer.datetime_start && contract_follow_editer.datetime_end && moment(contract_follow_editer.datetime_start).format("YYYY/MM/DD") === moment(contract_follow_editer.datetime_end).format("YYYY/MM/DD")) {
      minu = moment(contract_follow_editer.datetime_end).diff(moment(contract_follow_editer.datetime_start), 'm')
    }
    if (minu <= 0) {
      contract_follow_editer.datetime_start = moment(contract_follow_editer.datetime_end).add(-30, 'm')

    }

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  change_schedule(date) {
    let contract_follow_editer = this.props.contract_follow_editer

    // alert(date.start)
    contract_follow_editer.date = date.start
    contract_follow_editer.datetime_start = date.start
    contract_follow_editer.datetime_end = date.end

    if (date.start !== null) {
      if (moment(date.start).format("YYYY/MM/DD HH:mm") > moment().format("YYYY/MM/DD HH:mm")) {
        contract_follow_editer.progress = 1
      } else {
        contract_follow_editer.progress = 2
      }
    }

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  togglereserve() {
    this.setState({ modalreserve: !this.state.modalreserve })
  }

  handleOnChange(e) {

    let contract_follow_editer = this.props.contract_follow_editer
    contract_follow_editer[e.target.name] = e.target.value
    if (e.target.name === "progress" && e.target.value === 2 && !contract_follow_editer.date) {
      contract_follow_editer.date = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
      contract_follow_editer.datetime_start = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
      contract_follow_editer.datetime_end = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
    }

    if (e.target.name === "progress"){
      if(e.target.value == 2){//完了
        contract_follow_editer.completed_at = moment().tz("Asia/Tokyo").format("YYYY/MM/DD")
      }else{//完了以外
        contract_follow_editer.completed_at = null
      }
    }
    // if (e.target.name === "progress" && e.target.value === 2 && String(contract_follow_editer.rent_m_contract_follow_id) == "2") {

    //   this.props.contract_follow_editer.way = 0
    // }
    if (e.target.name === "progress_n" && e.target.value === 2 && !contract_follow_editer.date_n) {
      contract_follow_editer.date_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
      contract_follow_editer.datetime_start_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")
      contract_follow_editer.datetime_end_n = moment(e).tz("Asia/Tokyo").format("YYYY/MM/DD")

    }


    if (e.target.name === "progress" && e.target.value === 4) {

      contract_follow_editer.date = null
      contract_follow_editer.datetime_start = null
      contract_follow_editer.datetime_end = null

    }
    if (e.target.name === "progress_n" && e.target.value === 4) {
      contract_follow_editer.date_n = null
      contract_follow_editer.datetime_start_n = null
      contract_follow_editer.datetime_end_n = null

    }

    if (e.target.name === "progress" && e.target.value === 2) {

      if(contract_follow_editer.user_id > 0){
        if(!this.props.users_list.filter(a => a.id == contract_follow_editer.user_id)[0].license_number){
          contract_follow_editer.user_id = 0
        }
        
      }

    }

    if (e.target.name === "datetime_end" || e.target.name === "datetime_start") {

      contract_follow_editer[e.target.name] = contract_follow_editer.date + " " + e.target.value


      if (contract_follow_editer.datetime_end == null || 
        !moment(contract_follow_editer.datetime_end).isValid() || 
        moment(contract_follow_editer.datetime_end).isSame(moment(contract_follow_editer.datetime_start)) || 
        moment(contract_follow_editer.datetime_end).isBefore(moment(contract_follow_editer.datetime_start))) {
  
        contract_follow_editer.datetime_end = contract_follow_editer.datetime_start ? moment(contract_follow_editer.datetime_start).add(30, 'm') : null
      }


      
    }

    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  today(nom) {
    let contract_follow_editer = this.props.contract_follow_editer
    contract_follow_editer.date = moment()
    contract_follow_editer.datetime_start = moment()
    contract_follow_editer.datetime_end = moment()
    if (contract_follow_editer.progress !== 1 && contract_follow_editer.progress !== 2) {
      contract_follow_editer.progress = 2
    }
    this.props.change_contract_follow_editer(contract_follow_editer)
    this.setState({ reflesh: !this.props.reflesh })
    this.check_validation()
  }


  open_customer_window(rent_response_id) {
    window.open(`/customer/` + rent_response_id, '_blank', 'noopener')
  }

  insertWebVideoCallReservationDetailModal(){

    let web_video_call_reservation_detail = {...this.props.web_video_call_reservation_detail}
    web_video_call_reservation_detail.topic = this.props.contract_follow_editer.name + "様 " + this.props.contract_follow_editer.tatemono_name + ' ' + this.props.contract_follow_editer.room_name
    web_video_call_reservation_detail.reservation_at = this.props.contract_follow_editer.date ? this.props.contract_follow_editer.date + (this.props.contract_follow_editer.datetime_start ? moment(this.props.contract_follow_editer.datetime_start).format(" HH:mm") : "") : "" 
    web_video_call_reservation_detail.rent_contract_id = this.props.contract_follow_editer.rent_contract_id

    this.props.change_web_video_call_reservation_detail(web_video_call_reservation_detail)

    this.props.change_web_video_call_reservation_detail_modal(true)
    this.setState({ refresh: !this.state.refresh })
  }

  updateWebVideoCallReservationDetailModal(){

    this.props.get_web_video_call_reservation_detail(this.props.contract_follow_editer.web_video_call_reservation_uuid)
    this.props.change_web_video_call_reservation_detail_modal(true)

  }

  onChangeWebVideoCallReservationDetail(e) {

    let web_video_call_reservation_detail = {...this.props.web_video_call_reservation_detail}
    
    web_video_call_reservation_detail[e.target.name] = e.target.value 

    this.props.change_web_video_call_reservation_detail(web_video_call_reservation_detail)

  }

  // onChangeWebVideoCallReservationDetailDate(value) {

  //   let web_video_call_reservation_detail = {...this.props.web_video_call_reservation_detail}
    
  //   web_video_call_reservation_detail.reservation_at = value 

  //   this.props.change_web_video_call_reservation_detail(web_video_call_reservation_detail)

  // }

  handleClose(){
    this.props.change_web_video_call_reservation_detail_modal(false)
    this.setState({ refresh: !this.state.refresh })
  }

  render() {
    // if (this.myRef.current) {
    //   console.log(this.myRef.current.getBoundingClientRect())
    //   alert()
    // }
    return (
      <div id="contract_follow_list_editor" >





        <Card style={{ width: "100%", height: "100%" }}>
          {/* {
            this.props.contract_follow_editer_loading ? <div style={{
              position: "absolute", zIndex: 3001, opacity: 0.7, width: "100%", height: "100%",
              background: "#FFF"
            }}></div> : ""
          } */}
          <CardHeader

            action={
              <IconButton
                aria-label="settings"
                onClick={() => this.props.onClose(false)}
                size="large">
                <CloseIcon />
              </IconButton>
            }
            title={<span>
              {(() => {
                            let shop = "";
                            if (this.props.contract_follow_editer.section_id === 1) {
                                shop = <span className="badge badge-success">番</span>
                            } else if (this.props.contract_follow_editer.section_id === 2) {
                                shop = <span className="badge badge-warning">レ</span>
                            } else if (this.props.contract_follow_editer.section_id === 3) {
                                shop = <span className="badge badge-danger">駅</span>
                            } else if (this.props.contract_follow_editer.section_id === 4) {
                                shop = <span className="badge badge-dark">東</span>
                            } else if (this.props.contract_follow_editer.section_id === 6) {
                                shop = <span className="badge badge-light">公</span>
                            } else if (this.props.contract_follow_editer.section_id === 17) {
                                shop = <span className="badge badge-info">サ</span>
                            } else if (this.props.contract_follow_editer.section_id === 47) {
                                shop = <span className="badge badge-secondary">通</span>
                            }
                            return <span style={{ fontSize: "16px", marginRight:3 }}>{shop}</span>
                        })()}
              {this.props.contract_follow_editer.tatemono_name + ' ' + this.props.contract_follow_editer.room_name}
              </span>}
            subheader={<div style={{ color: "#000", fontWeight: "bold", fontSize: 20 }}><CalendarTodayIcon /> {this.props.contract_follow_editer.title}　　<IconButton
              onClick={() => this.open_customer_window(this.props.contract_follow_editer.rent_response_id)}
              size="large"><WebIcon color="primary" /></IconButton></div>}
          />
          <CardContent style={{ paddingTop: 0 }}>
            <Card >
              <CardContent >

                <div style={{ marginBottom: 10 }}>
                  <div>{this.props.contract_follow_editer.name}様
                    {this.props.contract_follow_editer.nyuukyosha ? "(入居者)" : this.props.contract_follow_editer.contract_form ? "(契約者)" : ""}<span style={{ marginLeft: 20 }}>{this.props.contract_follow_editer.agent_name ? this.props.contract_follow_editer.agent_name : this.props.contract_follow_editer.agent_etc ? this.props.contract_follow_editer.agent_etc : ""}</span></div>

                </div>
                <Typography component="div">
                  <Grid container alignItems="center" spacing={1}>
                    {this.props.contract_follow_editer.is_time ? <FormControl size="small" style={{ marginTop:10, marginBottom: "10px", marginLeft: "10px", width: "100px" }}>
                      <InputLabel id="demo-simple-select-label">来店</InputLabel>
                      <MaterialSelect
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        name="way"
                        value={this.props.contract_follow_editer.way > 0 ? this.props.contract_follow_editer.way : this.props.contract_follow_editer.way == -1 ? -1 : 0}
                        onChange={this.handleOnChange}
                        variant="standard"
                      >
                        <MenuItem value={0}>-</MenuItem>
                        {/* {String(this.props.contract_follow_editer.rent_m_contract_follow_id) == "2" ? <MenuItem value={-1}>-</MenuItem>:""} */}
                        <MenuItem value={1}>来店あり</MenuItem>
                        {String(this.props.contract_follow_editer.rent_m_contract_follow_id) == "2" ? <MenuItem value={3}> 郵送</MenuItem> : ""}
                        {String(this.props.contract_follow_editer.rent_m_contract_follow_id) == "2" ? <MenuItem value={4}> 電子</MenuItem> : ""}
                        {this.props.contract_follow_editer.key_name === "3" ? <MenuItem value={2}>IT重説</MenuItem> : ""}
                      </MaterialSelect>
                    </FormControl> : ""}

                    <Grid >
                      <FormControl size="small" style={{ marginTop:10, marginBottom: "10px", marginLeft: "10px", width: "100px" }}>
                        <InputLabel id="demo-simple-select-label">状況</InputLabel>
                        <MaterialSelect
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          name="progress"
                          value={this.props.contract_follow_editer.progress ? this.props.contract_follow_editer.progress : 0}
                          onChange={this.handleOnChange}
                          variant="standard"
                        >
                          {String(this.props.contract_follow_editer.rent_m_contract_follow_id) != "3" || this.props.contract_follow_editer.progress == 0 ? <MenuItem value={0}>-</MenuItem> :""}
                          {/* <MenuItem value={0}>-</MenuItem> */}
                          <MenuItem value={1}>予定</MenuItem>
                          <MenuItem value={2}>完了</MenuItem>
                          <MenuItem value={4}>不使用</MenuItem>
                        </MaterialSelect>
                      </FormControl>
                    </Grid>
                    <Grid >
                      {this.props.contract_follow_editer.way === 1 ? <Avatar ><DirectionsWalkIcon /></Avatar> : ""}

                    </Grid>
                    <Grid >
                      {this.props.contract_follow_editer.progress === 1 ? <Avatar ><AccessAlarmIcon /></Avatar> : ""}

                    </Grid>

                    <Grid >
                      {this.props.contract_follow_editer.progress === 2 ? <Avatar ><CheckIcon /></Avatar> : ""}

                    </Grid>

                    {/* {this.props.contract_follow_editer.key_name === "3" && this.props.contract_follow_editer.way == 2 && this.props.contract_follow_editer.progress == 1 ? <Grid style={{paddingleft:20}}>
                      
          
                      {this.props.contract_follow_editer.web_video_call_reservation_uuid ? 
                      <>
                      <MuiButton startIcon={<VideocamIcon />} variant="contained" color="warning" style={{ marginLeft: 20 }} >ビデオ通話</MuiButton>
                      <IconButton aria-label="today" onClick={()=>this.updateWebVideoCallReservationDetailModal()}>
                        <EditIcon />
                      </IconButton>
                      </>
                      // <Button variant="contained" color="secondary" style={{ marginLeft: 5 }} onClick={()=>this.updateWebVideoCallReservationDetailModal()}>ビデオ通話更新</Button>
                      :
                      <MuiButton startIcon={<AddCircleOutlineIcon />} variant="contained" color="warning" style={{ marginLeft: 20 }} onClick={()=>this.insertWebVideoCallReservationDetailModal()}>ビデオ通話作成</MuiButton>}
                    </Grid> : ""} */}

                  </Grid>

                </Typography>

                <FormControl style={{ marginBottom: "10px", width: "160px" }}>
                  {this.props.users_list.length > 0 && this.props.user_details ? <Autocomplete
                    id="combo-box-demo"
                    options={String(this.props.contract_follow_editer.rent_m_contract_follow_id) != "3" ?
                      this.state.ownshop ? this.props.users_list.filter(a => a.am_section_id === this.props.user_details.amSectionId) : this.props.users_list :
                      this.state.ownshop ? 
                        this.props.contract_follow_editer.progress == 2 ? this.props.users_list.filter(a => ((a.license_number && a.am_section_id != 27) || a.id == this.props.contract_follow_editer.user_id) && a.am_section_id === this.props.user_details.amSectionId) : this.props.users_list.filter(a => ((a.am_section_id != 27) || a.id == this.props.contract_follow_editer.user_id) && a.am_section_id === this.props.user_details.amSectionId)
                         : 
                         this.props.contract_follow_editer.progress == 2 ? this.props.users_list.filter(a => (a.license_number && a.am_section_id != 27)) : this.props.users_list.filter(a => (a.am_section_id != 27) || a.id == this.props.contract_follow_editer.user_id)}
                    getOptionLabel={(option) => option.user_name_all}
                    value={this.props.users_list.filter(a => String(a.id) === String(this.props.contract_follow_editer.user_id))[0]}
                    // defaultValue={this.props.contract_follow_editer.user_id}
                    // value={this.props.mail_template_categories.filter(a => a.value === this.props.mail_template_search.mail_template_category_id)[0]}
                    style={{ width: 150 }}
                    onChange={this.handle_change_user_id}
                    size="small"
                    renderInput={(params) => <TextField variant="standard"
                      {...params} label="担当を選択" />}

                  /> : ""}


                </FormControl>
                <FormControlLabel checked={this.state.ownshop} onChange={this.change_ownshop} control={<Checkbox name="checkedC" />} label="自店のみ" />




                {/*  */}
                <br />
                <FormControl style={{ marginBottom: "10px", width: "160px" }}>
                  {this.props.users_list.length > 0 && this.props.user_details ? <Autocomplete
                    id="combo-box-demo"
                    options={this.props.users_list.filter(a=>a.am_section_id != 27 && a.am_section_id > 0)}
                    getOptionLabel={(option) => option.user_name_all}
                    value={this.props.users_list.filter(a => String(a.id) === String(this.props.contract_follow_editer.client_user_id))[0]}
                    style={{ width: 150 }}
                    onChange={this.handle_change_client_user_id}
                    size="small"
                    renderInput={(params) => <TextField variant="standard"
                      {...params} label="依頼者を選択" />}

                  /> : ""}


                </FormControl>

                <TextField
                    id="date"
                    label="完了日"
                    type="date"
                    name="completed_at"
                    size="small"
                    disabled={!(parseInt(this.props.contract_follow_editer.progress) == 2)}
                    onChange={this.handleOnChange}
                    value={this.props.contract_follow_editer.completed_at ? moment(this.props.contract_follow_editer.completed_at).format("YYYY-MM-DD") : ""}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                />






                <br />
                {this.props.contract_follow_editer.progress !== 4 ? <>
                  <TextField
                      id="date"
                      label="日付"
                      type="date"
                      name="date"
                      size="small"
                      // onChange={this.handleOnChange}
                      onChange={(e)=>this.change_date(e.target.value)}
                      value={moment(this.props.contract_follow_editer.date).format("YYYY-MM-DD")}
                      InputLabelProps={{
                          shrink: true,
                      }}
                      variant="standard"
                      style={{marginRight:20}}
                  />
                  {/* <KeyboardDatePicker

                    disableToolbar
                    variant="inline"
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline2"
                    label="日付"
                    style={{ marginLeft: "20px", marginTop: "0", width: 140, float: "left" }}
                    autoOk={true}
                    // disabled={true}
                    // error={true}
                    // helperText="申込日を選択してください"
                    showTodayButton={true}
                    name="contractionDateEnd"
                    value={this.props.contract_follow_editer.date ? moment(this.props.contract_follow_editer.date) : null}
                    InputLabelProps={{ shrink: !!this.props.contract_follow_editer.date }}
                    // value={this.props.contractfollow.contract_follow_list_form.contractionDateEnd}
                    onChange={this.change_date}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}

                    invalidDateMessage={"日付を入力してください。"}
                  /> */}
                </> : ""}

                
                {this.props.contract_follow_editer.is_time && (this.props.contract_follow_editer.way === 1 || this.props.contract_follow_editer.way === 2) ? <>


                  
                  <TextField 
                    id="datetime_start" 
                    type="time" 
                    label="開始時間"
                    name="datetime_start"
                    size="small"
                    // onChange={this.handleOnChange}
                    onChange={(e) => this.change_datetime_start_time(e.target.value)}
                    value={moment(this.props.contract_follow_editer.datetime_start).format("HH:mm")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                  />

                  {/* <TimePicker
                    label="開始時間"

                    name="datetime_start"
                    value={this.props.contract_follow_editer.datetime_start}
                    onChange={value => this.change_datetime_start_time(value)}
                    renderInput={(params) => <TextField style={{ width: 100, float: "left" }} {...params} />}
                  /> */}
                  <span style={{ }}> – </span>

                  <TextField 
                    id="datetime_end" 
                    type="time" 
                    label="終了時間"
                    name="datetime_end"
                    size="small"
                    // onChange={this.handleOnChange}
                    onChange={(e) => this.change_datetime_end_time(e.target.value)}
                    value={moment(this.props.contract_follow_editer.datetime_end).format("HH:mm")}
                    InputLabelProps={{
                        shrink: true,
                    }}
                    variant="standard"
                  />

                  {/* <TimePicker
                    label="開始時間"
                    style={{ width: 70, float: "left" }}
                    name="datetime_end"
                    value={this.props.contract_follow_editer.datetime_end}
                    onChange={value => this.change_datetime_end_time(value)}
                    renderInput={(params) => <TextField style={{ width: 100, float: "left" }} {...params} />}
                  /> */}
                </>
                
                : <Tooltip title="本日">
                  <IconButton aria-label="today" onClick={() => this.today(1)} size="large">
                    <ArrowLeftIcon />
                  </IconButton>

                </Tooltip>}
                {/* {this.props.contract_follow_editer.is_time && (this.props.contract_follow_editer.way === 1 || this.props.contract_follow_editer.way === 2) ? <div>

                    <span>



                      <TextField
                        label="終了時間"
                        style={{ width: 70, float: "left" }}
                        onChange={this.change_datetime_start}
                        // onChange={handleChange}
                        // id="formatted-text-mask-input"
                        name="datetime_start"
                        onFocus={this.focus_start}
                        onBlur={this.blur_start}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        value={moment(this.props.contract_follow_editer.datetime_start).format('HH:mm')}

                      />
                      {this.state.focus_start ? <Paper style={{
                        width: 70, position: "absolute", marginLeft: 155, marginTop: 50, zIndex: 2,
                        height: 100,
                        overflow: "auto"
                      }}>
                        <MenuList >
                          {(moment(this.props.contract_follow_editer.datetime_end).format('HHmm') > 0 ? time.filter(a => a.value < moment(this.props.contract_follow_editer.datetime_end).format('HHmm')) : time).map(function (value) {
                            return <MenuItem onClick={() => this.change_datetime_start_from_list(value)}>
                              <Typography variant="inherit">{value.label}</Typography>
                            </MenuItem>
                          }, this)}


                        </MenuList>
                      </Paper > : ""}
                    </span>
                    <div style={{ float: "left" }}> – </div>
                    <span>
                      <TextField
                        label="終了時間"
                        style={{ width: 70, float: "left" }}
                        name="datetime_end"
                        onChange={this.change_datetime_start}
                        // id="formatted-text-mask-input"
                        onFocus={this.focus_end}
                        onBlur={this.blur_end}
                        InputProps={{ inputComponent: TextMaskCustom }}
                        value={moment(this.props.contract_follow_editer.datetime_end).format('HH:mm')}
                      />
                      {this.state.focus_end ? <Paper style={{
                        width: 70, position: "absolute", marginLeft: 230, marginTop: 50, zIndex: 2,
                        height: 100,
                        overflow: "auto"
                      }}>
                        <MenuList >
                          {time.filter(a => a.value > moment(this.props.contract_follow_editer.datetime_start).format('HHmm')).map(function (value) {
                            return <MenuItem onClick={() => this.change_datetime_end_from_list(value)}>
                              <Typography variant="inherit">{value.label}</Typography>
                            </MenuItem>
                          }, this)}


                        </MenuList>
                      </Paper > : ""}
                    </span> */}

                {/* {this.props.contract_follow_editer.is_time ? <IconButton aria-label="settings" onClick={this.togglereserve} >
                      <EventNoteIcon />
                    </IconButton> : ""} */}
                {/* 
                </div> : <Tooltip title="本日">
                  <IconButton aria-label="today" onClick={() => this.today(1)}>
                    <ArrowLeftIcon />
                  </IconButton>

                </Tooltip>} */}




                {/* <FormControlLabel control={<Checkbox name="checkedC" />} label="予定" /> */}


                <TextField
                  id="outlined-multiline-flexible"
                  label="備考"
                  multiline
                  rows={3}
                  name="remarks"
                  onChange={this.handleOnChange}
                  value={this.props.contract_follow_editer.remarks}
                  style={{ width: "100%", marginTop: "10px" }}
                  variant="outlined"
                />
              </CardContent >
            </Card >
            {this.state.first_date ? (this.state.delete_set ? <Alert severity="error" style={{ margin: 5 }}>削除してよろしいですか？<Button variant="contained" style={{ float: "right", margin: 5 }} onClick={() => this.setState({ delete_set: false })}>
              キャンセル
            </Button><Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={this.props.delete_rent_contract_follow_details}>
                削除する
              </Button></Alert> : <Button variant="contained" color="secondary" style={{ float: "right", margin: 5 }} onClick={() => this.setState({ delete_set: true })}>
              削除
            </Button>) : ""}
            {this.state.validation ?
              (String(this.props.contract_follow_editer.rent_m_contract_follow_id) == "2" && parseInt(this.props.contract_follow_editer.progress) == 2 && parseInt(this.props.contract_follow_editer.way) <= 0) ? <Alert severity="error">来店状況を選択してください。</Alert> : <span>
                <Button variant="contained" color="primary" style={{ float: "right", margin: 5 }} onClick={this.props.update_rent_contract_follow_details}>保存</Button>
              </span>

              :
              <Alert severity="error" style={{ margin: 5 }}>{this.state.validation_text}</Alert>}
          </CardContent >
        </Card >







        {/* <Modal isOpen={this.props.modalResponseDetail} toggle={() => this.props.change_modalResponseDetail(false)}
          size="xxl" fade={false}

        >
          <ResponseComponent responseTabs="r4" change_modalResponseDetail={(a) => this.props.change_modalResponseDetail(a)} rentResponseId={this.props.responseid} response_modal={true} />

        </Modal> */}






        <Modal isOpen={this.state.modalreserve} toggle={this.togglereserve} size="xxl" fade={false}>

          <ModalHeader toggle={this.togglereserve}>予定</ModalHeader>
          <ModalBody>
            <div style={{ height: window.innerHeight - 200, background: 'white' }}>
              <FollowCalendar change_schedule={this.change_schedule} section_id={this.props.contract_follow_editer.section_id} /></div>
          </ModalBody>
          <ModalFooter>
            <Button variant="contained" onClick={this.togglereserve} color="primary" style={{ float: "right", margin: 5 }}>
              閉じる
            </Button>

          </ModalFooter>
        </Modal>


        <Dialog onClose={()=>this.props.change_web_video_call_reservation_detail_modal(false)} open={this.props.web_video_call_reservation_detail_modal} fade={false}>
          <DialogTitle>ビデオ通話</DialogTitle>

          <DialogContent>
          <Grid container spacing={3} style={{marginTop:10}}>
                  <Grid xs={12}  style={{ padding: 5 }}>

                    <TextField
                      size="small"
                      fullWidth
                      variant="outlined"
                      id="datetime-local"
                      label="タイトル"
                      name="topic"
                      value={this.props.web_video_call_reservation_detail.topic}
                      // style={{ width: 200, marginBottom: 5 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChangeWebVideoCallReservationDetail}
                    />

                  </Grid>
                  <Grid xs={12} style={{ padding: 5 }}>

                    <TextField
                      size="small"
                      variant="outlined"
                      id="datetime-local"
                      label="予約日"
                      type="datetime-local"
                      name="reservation_at"
                      value={this.props.web_video_call_reservation_detail.reservation_at ? moment(this.props.web_video_call_reservation_detail.reservation_at).format("YYYY-MM-DDTHH:mm") : null}
                      // style={{ width: 200, marginBottom: 5 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChangeWebVideoCallReservationDetail}
                    />

                  </Grid>
                  <Grid xs={12} style={{ padding: 5 }}>

                    <TextField
                      size="small"
                      variant="outlined"
                      id="datetime-local"
                      label="有効期限"
                      type="datetime-local"
                      name="limited_at"
                      value={this.props.web_video_call_reservation_detail.limited_at ? moment(this.props.web_video_call_reservation_detail.limited_at).format("YYYY-MM-DDTHH:mm") : ""}
                      // style={{ width: 200, marginBottom: 5 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChangeWebVideoCallReservationDetail}
                    />

                  </Grid>
                  <Grid xs={12} style={{ padding: 5 }}>

                    <TextField
                      size="small"
                      id="datetime-local"
                      multiline
                      label="備考"
                      name="remarks"
                      value={this.props.web_video_call_reservation_detail.remarks}
                      // style={{ width: 200, marginBottom: 5 }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      onChange={this.onChangeWebVideoCallReservationDetail}
                      minRows={4}
                      maxRows={4}
                      variant="outlined"
                      fullWidth
                    />

                  </Grid>
                </Grid>
          </DialogContent>
          <DialogActions>
              {/* <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={update_rent_visits}>保存</Button>
              {visit_editor_forms.rent_visit_id ? <Button style={{ margin: 5 }} variant="contained" color="secondary" onClick={delete_rent_visits}>削除</Button> : ""} */}
              <Button variant="contained" style={{ margin: 5 }} color="primary" onClick={()=>this.props.insert_web_video_call_reservation_detail()}>保存</Button>
              <Button variant="contained" style={{ margin: 5 }} onClick={()=>this.props.change_web_video_call_reservation_detail_modal(false)}>閉じる</Button>
          </DialogActions>
        </Dialog>
      </div >
    );
  }
};

const time = [{ value: 900, label: '09:00' }
  , { value: 915, label: '09:15' }
  , { value: 930, label: '09:30' }
  , { value: 945, label: '09:45' }
  , { value: 1000, label: '10:00' }
  , { value: 1015, label: '10:15' }
  , { value: 1030, label: '10:30' }
  , { value: 1045, label: '10:45' }
  , { value: 1000, label: '10:00' }
  , { value: 1015, label: '10:15' }
  , { value: 1030, label: '10:30' }
  , { value: 1045, label: '10:45' }
  , { value: 1100, label: '11:00' }
  , { value: 1115, label: '11:15' }
  , { value: 1130, label: '11:30' }
  , { value: 1145, label: '11:45' }
  , { value: 1200, label: '12:00' }
  , { value: 1215, label: '12:15' }
  , { value: 1230, label: '12:30' }
  , { value: 1245, label: '12:45' }
  , { value: 1300, label: '13:00' }
  , { value: 1315, label: '13:15' }
  , { value: 1330, label: '13:30' }
  , { value: 1345, label: '13:45' }
  , { value: 1400, label: '14:00' }
  , { value: 1415, label: '14:15' }
  , { value: 1430, label: '14:30' }
  , { value: 1445, label: '14:45' }
  , { value: 1500, label: '15:00' }
  , { value: 1515, label: '15:15' }
  , { value: 1530, label: '15:30' }
  , { value: 1545, label: '15:45' }
  , { value: 1600, label: '16:00' }
  , { value: 1615, label: '16:15' }
  , { value: 1630, label: '16:30' }
  , { value: 1645, label: '16:45' }
  , { value: 1700, label: '17:00' }
  , { value: 1715, label: '17:15' }
  , { value: 1730, label: '17:30' }
  , { value: 1745, label: '17:45' }
  , { value: 1800, label: '18:00' }
  , { value: 1815, label: '18:15' }
  , { value: 1830, label: '18:30' }
  , { value: 1845, label: '18:45' }
  , { value: 1900, label: '19:00' }]

// Top 100 films as rated by IMDb users. http://www.imdb.com/chart/top

//export  default CustomerList;
const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    // users_select: state.masterReducer.users_select,
    users: state.masterReducer.users,
    contract_follow_search_list: state.contractfollow.contract_follow_search_list,
    rent_m_contract_follows_disp: state.contractfollow.rent_m_contract_follows_disp,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    modalResponseDetail: state.responsesReducer.modalResponseDetail,
    responseid: state.responsesReducer.responseid,
    contract_follow_editer_loading: state.contractfollow.contract_follow_editer_loading,
    users_list: state.contractfollow.users_list,
    web_video_call_reservation_detail: state.contractfollow.web_video_call_reservation_detail,
    web_video_call_reservation_detail_modal: state.contractfollow.web_video_call_reservation_detail_modal,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_contract_follow_list(state) {
      dispatch(ContractFollow.change_contract_follow_list(state))
    },

    get_contract_follow_list(state) {
      dispatch(ContractFollow.get_contract_follow_list(state))
    },
    change_responseid(state) {
      dispatch(Responses.change_responseid(state))
    },
    change_modalResponseDetail(state) {
      dispatch(Responses.change_modalResponseDetail(state))
    },

    change_rent_contract_id(state) {
      dispatch(Contracts.change_rent_contract_id(state))
    },

    update_rent_contract_follow_details(state) {
      dispatch(ContractFollow.update_rent_contract_follow_details(state))
    },
    search_contract_follow_list(state) {
      dispatch(ContractFollow.search_contract_follow_list(state))
    },
    delete_rent_contract_follow_details(state) {
      dispatch(ContractFollow.delete_rent_contract_follow_details(state))
    },

    get_users_list(state) {
      dispatch(ContractFollow.get_users_list(state))
    },
    get_web_video_call_reservation_detail(state) {
      dispatch(ContractFollow.get_web_video_call_reservation_detail(state))
    },
    insert_web_video_call_reservation_detail(state) {
      dispatch(ContractFollow.insert_web_video_call_reservation_detail(state))
    },
    change_web_video_call_reservation_detail(state) {
      dispatch(ContractFollow.change_web_video_call_reservation_detail(state))
    },
    change_web_video_call_reservation_detail_modal(state) {
      dispatch(ContractFollow.change_web_video_call_reservation_detail_modal(state))
    },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Editor);