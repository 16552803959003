import React, { Component } from 'react';

import { connect } from 'react-redux';

import '../../Css/Table.css';
import * as Calendarsaga from './Saga/Calendar';
import moment from 'moment';
import makeStyles from '@mui/styles/makeStyles';
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Scrollbars } from 'react-custom-scrollbars';

import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import Editor from '../ContractFollow/Editor';
import ContactEditComponent from '../CustomerContacts/ContactEditComponent';
import TaskEditor from './TaskEditor';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import DescriptionIcon from '@mui/icons-material/Description';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContactsIcon from '@mui/icons-material/Contacts';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';


var top = null
var left = null

var useStyles = makeStyles({
  avatar: {
    // backgroundColor: blue[100],
    // color: blue[600],
  },
  dialog: {
    position: 'absolute',
    left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
    top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
    width: 600,
    height: 580
  }
});


function TaskDialog(props) {


  const classes = useStyles();
  const { onClose, selectedValue, open, event_type, change_task_edit_modal, task_edit_modal, change_contract_follow_edit_modal, contract_follow_edit_modal, change_customer_contacts_modal, customer_contacts_modal } = props;

  // useStyles = makeStyles({
  //   avatar: {
  //     // backgroundColor: blue[100],
  //     // color: blue[600],
  //   },
  //   dialog: {
  //     position: 'absolute',
  //     left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
  //     top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),

  //     width: event_type === 1 ? 600 : 520,
  //     height: 538
  //   }
  // });

  const handleClose = () => {

    onClose(event_type);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  const close_task_edit_modal = (value) => {
    onClose(event_type);
  };

  const close_contract_follow_edit_modal = (value) => {
    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  const close_customer_contacts_modal = (value) => {

    onClose(event_type);
    // change_contract_follow_edit_modal(false);
  };

  return (
    <Dialog
      classes={{
        paper: classes.dialog
      }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={event_type === 1 ? customer_contacts_modal : (event_type === 2 ? contract_follow_edit_modal : task_edit_modal)}>



      {event_type === 1 ? <ContactEditComponent onClose={close_customer_contacts_modal} />
        : event_type === 2 ? <Editor onClose={close_contract_follow_edit_modal} /> :
          <TaskEditor onClose={close_task_edit_modal} />}


      {/* {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>}
      {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")}*/}
    </Dialog>
  );
}










function eventStyleGetter(event) {
  // console.log(event);
  //type:1, // 0:出勤 1:? 2:来店とか 3:タスク
  // var backgroundColor = event.type === 0 ? '#fffacd' : event.type === 4 ? '#e0e0e0' : event.type == 3 ? event.color : event.type == 2 && event.progress != 2 ? "#F7A46B" : event.type == 2 && event.progress == 2 ? "#a9a9a9" : event.intend ? '#98fb98' : '#87cefa';
  // var backgroundColor = event.id === 0 ? '#fffacd' : event.intend ? '#98fb98' : '#87cefa';
  var backgroundColor = "white"
  if(event.type === 0){// 出勤
    backgroundColor = '#fffacd' // クリーム
  }else if(event.type === 4){// 予定
    backgroundColor = '#e0e0e0' // グレイ
  }else if(event.type == 3){// タスク
    // if(event.completed_at){
    //   backgroundColor = '#a9a9a9'
    // }else{
      backgroundColor = event.color
    // }
  }else if(event.type == 2){// 来店
    // if(event.progress != 2) {
      backgroundColor = "#F7A46B"// オレンジ
    // }else{
    //   backgroundColor = "#9B9B9B"// グレイ
    // }
  }else if(event.type == 1){// 来店
    // if(event.intend){// 予定
      backgroundColor = '#98fb98'// 黄緑
    // }else{
    //   // backgroundColor = '#87cefa' // 水色
    //   backgroundColor = '#9B9B9B'// グレイ
    // }
  }

  return backgroundColor

  // var style = {
  //   backgroundColor: backgroundColor,
  //   borderRadius: '2px',
  //   borderColor: '#FFF',
  //   // opacity: 0.8,
  //   color: '#4d4d4d',
  //   border: '0px',
  //   display: 'block'
  // };
  // return {
  //   style: style
  // };
}








class ResponseListSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reflesh: false,
      open: false,
      event_type: 0

    };

    this.set = this.set.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.onChangeType = this.onChangeType.bind(this);

  }



  componentDidMount() {
    //  alert(this.props.masterReducer.user_details.amSectionId)
    //  console.log(this.props.masterReducer)
    //  alert("ddd")


  }






  handleDevices = (event, newDevices) => {
    // alert(newDevices)
    // if (newDevices.length) {
      this.props.change_calendar_toggle(newDevices);
    // }
  };

  editEvent = (event) => {

    this.props.change_calendar_precalendar(this.props.calendar_list)

    if (event.type === 3) {
      let rent_task_detail = this.props.rent_task_detail
      rent_task_detail.id = event.id
      rent_task_detail.title = event.title
      rent_task_detail.is_free = 0
      rent_task_detail.section_id = event.is_together ? (event.back_section_id == 5 && event.back_section_group == 1 ? 501 : event.back_section_id == 5 && event.back_section_group == 2 ? 502 : event.back_section_id == 5 && event.back_section_group == 3 ? 503 : event.back_section_id) : (event.section_id == 5 && event.section_group == 1 ? 501 : event.section_id == 5 && event.section_group == 2 ? 502 : event.section_id == 5 && event.section_group == 3 ? 503 : event.section_id)
      rent_task_detail.user_id = event.is_together ? event.back_user_id : event.user_id
      let together_user_ids = []
      if(event.together_user_id && event.together_user_id != 0){
        event.together_user_id.split(',').map(function (item) {
          together_user_ids.push(this.props.users.filter((a)=>a.id == item)[0])
          },this)
      }
      rent_task_detail.together_user_id = together_user_ids
      rent_task_detail.start_at = moment(event.start_edit).format('YYYY/MM/DD HH:mm')
      // rent_task_detail.end_at = moment(end).format('YYYY/MM/DD HH:mm')
      rent_task_detail.end_at = moment(event.end_edit).format('YYYY/MM/DD HH:mm')
      rent_task_detail.remarks = event.remarks
      rent_task_detail.color = event.color
      rent_task_detail.event_type = event.event_type
      rent_task_detail.completed_at = event.completed_at
      // alert( this.props.user_details.id)
      this.props.change_rent_task_detail(rent_task_detail)



    } else if (event.type === 2) {


      let contract_follow_editer = this.props.contract_follow_editer

      contract_follow_editer.rent_contract_id = event.rent_contract_id;
      contract_follow_editer.rent_response_id = event.rent_response_id;
      contract_follow_editer.section_id = event.follow_section_id;
      contract_follow_editer.user_id = event.user_id;

      contract_follow_editer.date = moment(event.date).utc().format("YYYY/MM/DD");
      contract_follow_editer.remarks = event.remarks;
      contract_follow_editer.way = event.way;
      // contract_follow_editer.going = event.going;
      contract_follow_editer.rent_m_contract_follow_id = event.rent_m_contract_follow_id;
      contract_follow_editer.title = event.rent_m_contract_follow_name
      contract_follow_editer.is_time = event.is_time
      contract_follow_editer.key_name = "3"
      contract_follow_editer.progress = event.progress;
      contract_follow_editer.client_user_id = event.client_user_id;
      contract_follow_editer.completed_at = event.completed_at;

      contract_follow_editer.tatemono_name = event.tatemono_name;
      contract_follow_editer.room_name = event.room_name;
      contract_follow_editer.name = event.nyuukyosha ? event.name2 : event.name1;

      contract_follow_editer.name1 = event.name1;
      contract_follow_editer.name2 = event.name2;
      contract_follow_editer.nyuukyosha = event.nyuukyosha;
      // contract_follow_editer.section_id = event.section_id;
      contract_follow_editer.agent_name = event.agent_name;
      contract_follow_editer.agent_etc = event.agent_etc;
      // contract_follow_editer.name2 = event.name2;

      contract_follow_editer.datetime_start = event.start_edit;
      contract_follow_editer.datetime_end = event.end_edit;

      // user_id: 'crm.rent_contract_follow_details.
      this.props.change_contract_follow_editer(contract_follow_editer)
      // this.props.change_rent_customer_contacts_forms_modal(!this.props.rent_customer_contacts_forms_modal)

    } else if (event.type === 1) {
      // alert(event.type)
      console.log(event)
      // alert(event.date)
      // alert(event.type)
      let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms


      rent_customer_contacts_forms.rent_response_id = event.rent_response_id;
      rent_customer_contacts_forms.section_id = event.section_id;
      rent_customer_contacts_forms.user_id = event.user_id;

      rent_customer_contacts_forms.date = moment(event.date).format("YYYY/MM/DD");
      rent_customer_contacts_forms.remarks = event.remarks ? event.remarks : '';
      rent_customer_contacts_forms.way = event.way ? event.way : '';
      rent_customer_contacts_forms.going = event.going ? event.going : 0;
      rent_customer_contacts_forms.going_time = event.going_time ? event.going_time : 0;
      rent_customer_contacts_forms.going_user_id = event.going_user_id ? event.going_user_id : 0;
      rent_customer_contacts_forms.client_user_id = event.client_user_id ? event.client_user_id : 0;
      rent_customer_contacts_forms.coming_detail_type = event.coming_detail_type ? event.coming_detail_type : 0;
      rent_customer_contacts_forms.completed_at = event.completed_at ? event.completed_at : '';
      rent_customer_contacts_forms.is_application = event.is_application ? event.is_application : 0;
      rent_customer_contacts_forms.rent_m_going_reason_id = event.rent_m_going_reason_id ? event.rent_m_going_reason_id : 0;
      rent_customer_contacts_forms.going_section = event.going_section ? event.going_section : 0;
      rent_customer_contacts_forms.online = event.online ? event.online : 0;
      rent_customer_contacts_forms.no_coming = event.no_coming ? event.no_coming : 0;
      rent_customer_contacts_forms.is_prepare = event.is_prepare ? event.is_prepare : 0;
      rent_customer_contacts_forms.rent_m_response_status_id = event.rent_m_response_status_id ? event.rent_m_response_status_id : 0;
      rent_customer_contacts_forms.intend = event.intend ? event.intend : 0;
      rent_customer_contacts_forms.agent_name = event.agent_name;
      rent_customer_contacts_forms.agent_etc = event.agent_etc;
      rent_customer_contacts_forms.intend_time = event.intend_time ? event.intend_time : 0;
      rent_customer_contacts_forms.intend_time2 = event.intend_time2 ? event.intend_time2 : 0;
      rent_customer_contacts_forms.start = event.start_edit ? moment(event.start_edit).format("YYYY/MM/DD HH:mm") : null;
      rent_customer_contacts_forms.end = event.end_edit ? moment(event.end_edit).format("YYYY/MM/DD HH:mm") : null;
      rent_customer_contacts_forms.id = event.id ? event.id : 0;
      rent_customer_contacts_forms.title = event.title ? event.title : "";
      this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
    }



    if (event.type === 1) {
      this.props.change_customer_contacts_modal(true)
    } else if (event.type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event.type === 3) {
      this.props.change_task_edit_modal(true)
    // } else if (event.type !== 0) {
    //   this.setState({ open: true })
    }

    this.props.change_calendar_event_type(event.type)

    this.setState({ event_type: event.type })

  }

  set(event_type) {

    if (event_type === 1) {

      this.props.change_customer_contacts_modal(true)
    } else if (event_type === 2) {
      this.props.change_contract_follow_edit_modal(true)
    } else if (event_type === 3) {
      this.props.change_task_edit_modal(true)
    // } else if (event_type !== 0) {
    //   this.setState({ open: true })
    }
    this.setState({ event_type: event_type })
    this.setState({ reflesh: !this.state.reflesh })
  }

  handleClose = (value) => {

    if (value === 3) {

      this.props.change_task_edit_modal(false)

      this.props.change_calendar_list(this.props.calendar_precalendar)
      
    } else if (value === 2) {

      this.props.change_contract_follow_edit_modal(false)

      this.props.change_calendar_list(this.props.calendar_precalendar)


    } else if (value === 1) {

      this.props.change_customer_contacts_modal(false)

      // this.props.change_calendar_list(this.state.precalendar)
      this.props.change_calendar_list(this.props.calendar_precalendar)


    }
    this.setState({ reflesh: !this.state.reflesh })
    this.props.change_calendar_precalendar([])

  };

  handleOpen(id, section_id, user_id, start, end) {
    if (id) {
        this.props.set_calendar_free_detail(id)
    } else {
        // 新規
        let calendar_free_detail = { 
            id : "",
            is_free : 1,
            remarks : "",
            section_id : section_id,
            user_id : user_id,
            start_at : start,
            end_at : end,
            color : "",
            is_deleted : 0,
        }
        this.props.change_calendar_free_detail(calendar_free_detail)
    }
    this.props.change_calendar_free_dialog(true)
    // this.setState({ "modal": true })
}

onChangeType(type){
  let calendar_toggle = this.props.calendar_toggle

  // if(calendar_toggle.includes(type)){// 削除
  //   calendar_toggle = calendar_toggle.filter(item => item != type);

  // }else{// 追加
  //   calendar_toggle[calendar_toggle.length] = type;
  // }

  calendar_toggle = type

  this.props.change_calendar_toggle(calendar_toggle)

  this.setState({ reflesh: !this.state.reflesh })
}

 

  render() {



    return (
      <div>




          <Grid xs={12} style={{ padding: 0 }}>
            {/* <ToggleButtonGroup
              value={this.props.calendar_toggle}
              onChange={this.handleDevices}
              aria-label="text formatting"
              color="primary"
              // exclusive
              style={{width:"100%"}}
            > */}
              {/* <ToggleButton value="coming" style={{padding:7, color:this.props.calendar_toggle.includes('coming') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('coming') ? "#3f51b5": "initial"}}>
                <DirectionsWalkIcon />
              </ToggleButton>
              <ToggleButton value="key" style={{padding:7, color:this.props.calendar_toggle.includes('key') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('key') ? "#3f51b5": "initial"}}>
                <VpnKeyIcon />
              </ToggleButton>
              <ToggleButton value="explan" style={{padding:7, color:this.props.calendar_toggle.includes('explan') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('explan') ? "#3f51b5": "initial"}}>
                <ContactsIcon />
              </ToggleButton>
              <ToggleButton value="contract" style={{padding:7, color:this.props.calendar_toggle.includes('contract') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('contract') ? "#3f51b5": "initial"}}>
                <DescriptionIcon />
              </ToggleButton>
            </ToggleButtonGroup> */}

            {/* <Fab color="primary" size="small" style={{borderRadius:"100%"}} variant="extended" aria-label="add">
              <DirectionsWalkIcon vari />
            </Fab>
            <Fab color="secondary" size="small" variant="extended" aria-label="add">
              <DirectionsWalkIcon />
            </Fab> */}

            {/* <ToggleButtonGroup
              value={this.props.calendar_toggle}
              onChange={this.handleDevices}
              aria-label="text formatting"
              color="primary"
              // exclusive
            >
              <ToggleButton value="coming" style={{padding:7, color:this.props.calendar_toggle.includes('coming') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('coming') ? "#3f51b5": "initial"}}>
                来店
              </ToggleButton>
              <ToggleButton value="key" style={{padding:7, color:this.props.calendar_toggle.includes('key') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('key') ? "#3f51b5": "initial"}}>
                カギ
              </ToggleButton>
              <ToggleButton value="explan" style={{padding:7, color:this.props.calendar_toggle.includes('explan') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('explan') ? "#3f51b5": "initial"}}>
                重説
              </ToggleButton>
              <ToggleButton value="contract" style={{padding:7, color:this.props.calendar_toggle.includes('contract') ? "white": "#c0c0c0", backgroundColor:this.props.calendar_toggle.includes('contract') ? "#3f51b5": "initial"}}>
                契渡
              </ToggleButton>
            </ToggleButtonGroup> */}


            <div className="todaylist" style={{marginTop:2, marginBottom:2}}>
              <Tooltip title="来店" placement="top" arrow>
              <IconButton
                onClick={()=>this.onChangeType("coming")}
                style={{padding:0, boxSizing: "content-box", borderRadius: "2px", marginRight:3}}
                size="large">
                <DirectionsWalkIcon className={this.props.calendar_toggle.includes('coming') ? 'icon1' : "icon3"}/>
              </IconButton>
              </Tooltip>
              <Tooltip title="鍵渡し" placement="top" arrow>
              <IconButton
                onClick={()=>this.onChangeType("key")}
                style={{padding:0, boxSizing: "content-box", borderRadius: "2px", marginRight:3}}
                size="large">
                <VpnKeyIcon className={this.props.calendar_toggle.includes('key') ? 'icon2' : "icon3"}/>
              </IconButton>
              </Tooltip>
              <Tooltip title="重説" placement="top" arrow>
              <IconButton
                onClick={()=>this.onChangeType("explan")}
                style={{padding:0, boxSizing: "content-box", borderRadius: "2px", marginRight:3}}
                size="large">
                <ContactsIcon className={this.props.calendar_toggle.includes('explan') ? 'icon2' : "icon3"}/>
              </IconButton>
              </Tooltip>
              <Tooltip title="契渡し" placement="top" arrow>
              <IconButton
                onClick={()=>this.onChangeType("contract")}
                style={{padding:0, boxSizing: "content-box", borderRadius: "2px"}}
                size="large">
                <DescriptionIcon className={this.props.calendar_toggle.includes('contract') ? 'icon2' : "icon3"}/>
              </IconButton>
              </Tooltip>
            </div>




            <List component="nav" aria-label="mailbox folders" style={{backgroundColor:"#E2E2E2", color:"#4D4D4D", padding:3, marginTop:4}}>

                    <ListItem style={{ width: "100%", paddingBottom:0, paddingTop:0 }}>
                      <DirectionsWalkIcon />
                      <span style={{marginRight:3}}>前{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T00:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T13:00:00")).length}　</span>
                      <span style={{marginRight:3}}>後{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T13:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T16:00:00")).length}　</span>
                      <span style={{marginRight:3}}>夕{this.props.calendar_list.filter(a => (a.type == 1) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T16:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T23:59:59")).length}　</span>
                    </ ListItem >
            
                    <ListItem style={{ width: "100%", paddingBottom:0, paddingTop:0 }}>
                      <DescriptionIcon />
                      <span style={{marginRight:3}}>前{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T00:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T13:00:00")).length}　</span>
                      <span style={{marginRight:3}}>後{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T13:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T16:00:00")).length}　</span>
                      <span>夕{this.props.calendar_list.filter(a => (a.type == 2) && parseInt(a.section_id) == parseInt(this.props.calendar_list_form.section_id) &&
                        moment(a.start) >= moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T16:00:00") &&
                        moment(a.start) < moment(moment(this.props.calendar_list_form.start).format("YYYY-MM-DD") + "T23:59:59")).length}　</span>

                    </ListItem >
            </List>
            <Scrollbars className="store_calendar" style={{ width: "100%", height: this.props.calendar_type == 3 ? window.innerHeight - 460 : window.innerHeight - 352, marginTop:4, border :"1px solid #E2E2E2"}}>
            
            <List component="nav" aria-label="mailbox folders" style={{padding:1 }}>

              {this.props.calendar_list.filter(a=> moment(a.start).format("YYYYMMDD") == moment(this.props.calendar_list_form.start).format("YYYYMMDD") && a.section_id == this.props.calendar_list_form.section_id && (a.type == 1 || a.rent_m_contract_follow_id == 8 || a.rent_m_contract_follow_id == 27 || a.rent_m_contract_follow_id == 3 || a.rent_m_contract_follow_id == 2))
              .filter(a=>
              (this.props.calendar_toggle.includes('coming') && a.type == 1) ||
              (this.props.calendar_toggle.includes('key') && a.rent_m_contract_follow_id == 8) ||
              (this.props.calendar_toggle.includes('explan') && a.rent_m_contract_follow_id == 3) ||
              (this.props.calendar_toggle.includes('contract') && (a.type != 1 && a.rent_m_contract_follow_id != 8 && a.rent_m_contract_follow_id != 3))
              )
              .map(function (value) {
              return <div><ListItem button style={{padding:2, marginBottom:2, backgroundColor:eventStyleGetter(value)}}  onClick={() => this.editEvent(value)}>
                  {value.type == "1" ? value.returning == 0 ? <FiberNewIcon style={{color: value.intend ? "red" :"#666666" }} />: <DirectionsWalkIcon style={{ color: value.intend ? "red" :"#666666" }} /> : value.way == 2 ? <ContactsIcon style={{color:value.progress != 2 ? "red" : "#666666"}} /> : (value.rent_m_contract_follow_id == 7 || value.rent_m_contract_follow_id == 8) ? <VpnKeyIcon style={{color:value.progress != 2 ? "red" : "#666666"}} /> : <DescriptionIcon style={{color:value.progress != 2 ? "red" : "#666666"}} />}
                  <span>{moment(value.start).format("HH:mm") == "00:00" ? "" : <span className="badge badge-info">{moment(value.start).format("HH:mm")}</span>}
                {value.title}</span>
              </ListItem>
              {/* <Divider /> */}
              </div>

              },this)}
            </List>
            </Scrollbars>
          </Grid>




      {/* <TaskDialog

          open={this.state.open}
          onClose={this.handleClose}
          event_type={this.props.calendar_event_type}
          change_task_edit_modal={this.props.change_task_edit_modal}
          task_edit_modal={this.props.task_edit_modal}
          change_contract_follow_edit_modal={this.props.change_contract_follow_edit_modal}
          contract_follow_edit_modal={this.props.contract_follow_edit_modal}
          change_customer_contacts_modal={this.props.change_customer_contacts_modal}
          customer_contacts_modal={this.props.customer_contacts_modal} /> */}




      </div >
    );
  }
}



const mapStateToProps = (state) => {
  return {

    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users: state.masterReducer.users,
    users_select: state.masterReducer.users_select,
    section_select: state.masterReducer.section_select,

    calendar_list: state.calendar.calendar_list,
    // calendar_list_form: state.calendar.calendar_list_form,
    calendar_type: state.calendar.calendar_type,


    calendar_toggle: state.calendar.calendar_toggle,
    calendar_viewType: state.calendar.calendar_viewType,
    calendar_event_type: state.calendar.calendar_event_type,
    calendar_precalendar: state.calendar.calendar_precalendar,
    
    rent_task_detail: state.calendar.rent_task_detail,
    contract_follow_editer: state.contractfollow.contract_follow_editer,
    rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,

    contract_follow_edit_modal: state.contractfollow.contract_follow_edit_modal,
    customer_contacts_modal: state.customerContactsReducer.customer_contacts_modal,

  }
}
function mapDispatchToProps(dispatch) {
  return {

    change_calendar_toggle(state) {
      dispatch(Calendarsaga.change_calendar_toggle(state))
    },

    change_rent_task_detail(state) {
      dispatch(Calendarsaga.change_rent_task_detail(state))
    },
    change_contract_follow_editer(state) {
      dispatch(ContractFollow.change_contract_follow_editer(state))
    },
    change_rent_customer_contacts_forms(state) {
      dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
    },
    change_task_edit_modal(state) {
      dispatch(Calendarsaga.change_task_edit_modal(state))
    },
    change_contract_follow_edit_modal(state) {
      dispatch(ContractFollow.change_contract_follow_edit_modal(state))
    },
    change_customer_contacts_modal(state) {
      dispatch(CustomerContacts.change_customer_contacts_modal(state))
    },

    change_calendar_precalendar(state) {
      dispatch(Calendarsaga.change_calendar_precalendar(state))
    },
    change_calendar_event_type(state) {
      dispatch(Calendarsaga.change_calendar_event_type(state))
    },
    change_calendar_list(state) {
      dispatch(Calendarsaga.change_calendar_list(state))
    },
    
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseListSearch);

// ResponseListSearch = reduxForm({
//   form: 'formset', // a unique identifier for this form
//   onSubmit: submit
//   //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
// })(ResponseListSearch)
