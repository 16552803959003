import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import * as Task from './Saga/Task';
import * as ContractFollow from '../ContractFollow/Saga/ContractFollow';
import * as CustomerContacts from '../CustomerContacts/Saga/CustomerContacts';
import * as Calendar from '../Calendar/Saga/Calendar';

import moment from 'moment'
import FiberNewIcon from '@mui/icons-material/FiberNew';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import LaptopChromebookIcon from '@mui/icons-material/LaptopChromebook';

import DescriptionIcon from '@mui/icons-material/Description';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ContactsIcon from '@mui/icons-material/Contacts';
import CallIcon from '@mui/icons-material/Call';
import EmailIcon from '@mui/icons-material/Email';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import './Css/Grid.css'

import Editor from '../ContractFollow/Editor';
import ContactEditComponent from '../CustomerContacts/ContactEditComponent';
import TaskEditor from '../Calendar/TaskEditor';
import FreeDialog from "../Calendar/FreeDialog";

import Dialog from '@mui/material/Dialog';



var top = null
var left = null

// var useStyles = makeStyles({
//   avatar: {
//     // backgroundColor: blue[100],
//     // color: blue[600],
//   },
//   dialog: {
//     position: 'absolute',
//     left: 520 + left <= window.innerWidth ? left : (left - 520 > 0 ? left - 520 : 0),
//     top: 580 + top <= window.innerHeight ? top : (top - 580 > 0 ? top - 580 : 0),
//   },
//   tooltip: {
//     fontSize: "12px",
//     color:"black",
//     backgroundColor: "white",
//     border:"1px solid #6c757d",
//     padding:"1px 3px",

//   },
// });


function TaskDialog(props) {


//   const classes = useStyles();
  const { onClose, event_type, modal } = props;

 

  const handleClose = () => {

    onClose();
  };





  return (
    <Dialog
    //   classes={{
    //     paper: classes.dialog
    //   }}
      onClose={handleClose} aria-labelledby="simple-dialog-title" open={modal}>



      {event_type === 1 ? <ContactEditComponent onClose={onClose} />
        : event_type === 2 ? <Editor onClose={onClose} /> 
        : event_type === 5 ? <FreeDialog onClose={onClose} /> :
          <TaskEditor onClose={onClose} />}


      {/* {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : <button className="btn btn-primary float-right" type="button" onClick={this.props.add_rent_customer_contacts}>保存</button>}
      {event_type === 1 && (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 1 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 2 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 5 || (Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 3 && Number(this.props.rent_customer_contacts_forms.intend) !== 1) || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 12 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 13 || Number(this.props.rent_customer_contacts_forms.rent_m_response_status_id) === 14) ? "" : (this.state.contactstate && this.state.contactstate.id ? <button className="btn btn-danger float-right" type="button" onClick={this.toggledelete}>削除</button> : "")}*/}
    </Dialog>
  );
}


class TaskComponent extends Component  {
    constructor(props) {
        super(props);
        this.state = {
            refresh: false,
            event_type: 0,
            modal: false, 
            columnWidths: {
                type: 120, 
                time: 180,
                tanto: 90,
                primary_tanto: 90,
                hankyo_tanto: 90,
                follow_name: 130,
                okyaku: 200,
                biko: 450,
                memo: 170,
            },
        }

        this._rowClassNameGetter = this._rowClassNameGetter.bind(this);
        this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);
        this.RowClick = this.RowClick.bind(this);

    }

    RowClick(event,index){

        let detail = this.props.task_rent_responses_list_search[index]
        
        if (detail.type === 3) {
          let rent_task_detail = this.props.rent_task_detail
          rent_task_detail.id = detail.id
          rent_task_detail.title = detail.title
          rent_task_detail.is_free = 0
        //   rent_task_detail.section_id = detail.section_id == 5 && detail.section_group == 1 ? 501 : detail.section_id == 5 && detail.section_group == 2 ? 502 : detail.section_id
          rent_task_detail.section_id = detail.is_together ? (detail.back_section_id == 5 && detail.back_section_group == 1 ? 501 : detail.back_section_id == 5 && detail.back_section_group == 2 ? 502 : detail.back_section_id == 5 && detail.back_section_group == 3 ? 503 : detail.back_section_id) : (detail.section_id == 5 && detail.section_group == 1 ? 501 : detail.section_id == 5 && detail.section_group == 2 ? 502 : detail.section_id == 5 && detail.section_group == 3 ? 503 : detail.section_id)

          rent_task_detail.user_id = detail.is_together ? detail.back_user_id : detail.user_id
          let together_user_ids = []
            if(detail.together_user_id && detail.together_user_id != 0){
                detail.together_user_id.split(',').map(function (item) {
                together_user_ids.push(this.props.calendar_users.filter((a)=>a.id == item)[0])
                },this)
            }
          rent_task_detail.together_user_id = together_user_ids
          rent_task_detail.start_at = moment(detail.start).utc().format('YYYY/MM/DD HH:mm')
          rent_task_detail.end_at = moment(detail.end).utc().format('YYYY/MM/DD HH:mm')
          rent_task_detail.remarks = detail.remarks
          rent_task_detail.color = detail.color
          rent_task_detail.event_type = detail.event_type
          rent_task_detail.completed_at = detail.completed_at
    
          rent_task_detail.diff = moment(detail.end).diff(moment(detail.start), 'minutes')
          this.props.change_rent_task_detail(rent_task_detail)
    
        } else if (detail.type === 5) {

            let calendar_free_detail = this.props.calendar_free_detail

            calendar_free_detail.id = detail.id
            calendar_free_detail.is_free = 1
            calendar_free_detail.remarks = detail.remarks
            calendar_free_detail.section_id = detail.section_id == 5 && detail.section_group == 1 ? 501 : detail.section_id == 5 && detail.section_group == 2 ? 502 : detail.section_id
            calendar_free_detail.user_id = detail.user_id ? detail.user_id : 0
            calendar_free_detail.start_at = moment(detail.start).format('YYYY/MM/DD HH:mm:ss')
            calendar_free_detail.end_at = moment(detail.end).format('YYYY/MM/DD HH:mm:ss')
            calendar_free_detail.color = detail.color
            calendar_free_detail.completed_at = detail.completed_at
            calendar_free_detail.is_deleted = detail.is_deleted

            // console.log("bbbbbbb", calendar_free_detail)
            this.props.change_calendar_free_detail(calendar_free_detail)
    
        } else if (detail.type === 2) {
    
    
          let contract_follow_editer = this.props.contract_follow_editer
    
          contract_follow_editer.rent_contract_id = detail.rent_contract_id;
          contract_follow_editer.rent_response_id = detail.rent_response_id;
          contract_follow_editer.section_id = detail.follow_section_id;
          contract_follow_editer.user_id = detail.user_id;
    
          contract_follow_editer.date = moment(detail.date).utc().format("YYYY/MM/DD");
          contract_follow_editer.remarks = detail.remarks;
          contract_follow_editer.way = detail.way;
          contract_follow_editer.rent_m_contract_follow_id = detail.rent_m_contract_follow_id;
          contract_follow_editer.title = detail.rent_m_contract_follow_name
          contract_follow_editer.is_time = detail.is_time
          contract_follow_editer.key_name = "3"
          contract_follow_editer.progress = detail.progress;
          contract_follow_editer.client_user_id = detail.client_user_id;
          contract_follow_editer.completed_at = detail.completed_at;
    
          contract_follow_editer.tatemono_name = detail.tatemono_name;
          contract_follow_editer.room_name = detail.room_name;
          contract_follow_editer.name = detail.nyuukyosha ? detail.name2 : detail.name1;
    
          contract_follow_editer.name1 = detail.name1;
          contract_follow_editer.name2 = detail.name2;
          contract_follow_editer.nyuukyosha = detail.nyuukyosha;
          contract_follow_editer.agent_name = detail.agent_name;
          contract_follow_editer.agent_etc = detail.agent_etc;
    
          contract_follow_editer.datetime_start = detail.start && detail.start != "Invalid date" ? moment(detail.start).utc().format("YYYY/MM/DD HH:mm") : null;
          contract_follow_editer.datetime_end = detail.end && detail.end != "Invalid date" ? moment(detail.end).utc().format("YYYY/MM/DD HH:mm") : null;
    
          this.props.change_contract_follow_editer(contract_follow_editer)
    
        } else if (detail.type === 1) {
          console.log(event)
          let rent_customer_contacts_forms = this.props.rent_customer_contacts_forms
    
          rent_customer_contacts_forms.rent_response_id = detail.rent_response_id;
          rent_customer_contacts_forms.section_id = detail.section_id;
          rent_customer_contacts_forms.user_id = detail.user_id;
    
          rent_customer_contacts_forms.date = moment(detail.date).utc().format("YYYY/MM/DD HH:mm");
          rent_customer_contacts_forms.remarks = detail.remarks ? detail.remarks : '';
          rent_customer_contacts_forms.way = detail.way ? detail.way : '';
          rent_customer_contacts_forms.going = detail.going ? detail.going : 0;
          rent_customer_contacts_forms.going_time = detail.going_time ? detail.going_time : 0;
          rent_customer_contacts_forms.going_user_id = detail.going_user_id ? detail.going_user_id : 0;
          rent_customer_contacts_forms.client_user_id = detail.client_user_id ? detail.client_user_id : 0;
          rent_customer_contacts_forms.coming_detail_type = detail.coming_detail_type ? detail.coming_detail_type : 0;
          rent_customer_contacts_forms.completed_at = detail.completed_at ? detail.completed_at : '';
          rent_customer_contacts_forms.is_application = detail.is_application ? detail.is_application : 0;
          rent_customer_contacts_forms.rent_m_going_reason_id = detail.rent_m_going_reason_id ? detail.rent_m_going_reason_id : 0;
          rent_customer_contacts_forms.going_section = detail.going_section ? detail.going_section : 0;
          rent_customer_contacts_forms.online = detail.online ? detail.online : 0;
          rent_customer_contacts_forms.no_coming = detail.no_coming ? detail.no_coming : 0;
          rent_customer_contacts_forms.is_prepare = detail.is_prepare ? detail.is_prepare : 0;
          rent_customer_contacts_forms.rent_m_response_status_id = detail.rent_m_response_status_id ? detail.rent_m_response_status_id : 0;
          rent_customer_contacts_forms.intend = detail.intend ? detail.intend : 0;
          rent_customer_contacts_forms.agent_name = detail.agent_name;
          rent_customer_contacts_forms.agent_etc = detail.agent_etc;
          rent_customer_contacts_forms.intend_time = detail.intend_time ? detail.intend_time : 0;
          rent_customer_contacts_forms.intend_time2 = detail.intend_time2 ? detail.intend_time2 : 0;
    
          rent_customer_contacts_forms.start = detail.start ? moment(detail.start).utc().format("YYYY/MM/DD HH:mm") : null;
          rent_customer_contacts_forms.end = detail.end ? moment(detail.end).utc().format("YYYY/MM/DD HH:mm") : null;
    
          rent_customer_contacts_forms.id = detail.id ? detail.id : 0;
          rent_customer_contacts_forms.title = detail.title ? detail.title : "";
          this.props.change_rent_customer_contacts_forms(rent_customer_contacts_forms)
        }
    
    
        this.props.change_rent_tasks_modal(true)
    
        this.setState({ 
                event_type: detail.type, 
                modal: true
        })

    }

    handleClose = (value) => {

        this.props.change_rent_tasks_modal(false)
        
        this.setState({ 
            event_type: 0, 
            modal: false,
        })
    
    
    };

    _rowClassNameGetter(index){
        
        if(this.props.task_rent_responses_list_search && this.props.task_rent_responses_list_search[index]) {
        //   return this.props.task_rent_responses_list_search[index].type == 1 ? "completed" : "normal"

          return (this.props.task_rent_responses_list_search[index].type == 1 && this.props.task_rent_responses_list_search[index].intend) || 
          (this.props.task_rent_responses_list_search[index].type == 2 && this.props.task_rent_responses_list_search[index].progress != 2) || 
          ((this.props.task_rent_responses_list_search[index].type == 3 || this.props.task_rent_responses_list_search[index].type == 5) && this.props.task_rent_responses_list_search[index].completed_at == null) ? "normal" : "completed"
            // return "normal"
        }else{
          return ""
        }
    }

    _onColumnResizeEndCallback(newColumnWidth, columnKey) {
        this.setState(({ columnWidths }) => ({
            columnWidths: {
                ...columnWidths,
                [columnKey]: newColumnWidth,
            },
        }));
    }


    render() {

        // const classes = useStylesTooltip();
        return (
            <div>
                {this.props.task_rent_responses_list_search.length > 0 ? <Table
                    className="gridTaskNormalList"
                    rowHeight={34}
                    rowsCount={this.props.task_rent_responses_list_search.length}
                    width={window.innerWidth - 90}
                    height={window.innerHeight - 250}
                    headerHeight={40}
                    onColumnResizeEndCallback={this._onColumnResizeEndCallback}
                    isColumnResizing={true}
                    onRowClick={this.RowClick}
                    rowClassNameGetter={this._rowClassNameGetter}
                >
                    <Column width={this.state.columnWidths.type} isResizable={true} columnKey="type" header={"種別"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type == 5 ? "その他" : ""}
                            {this.props.task_rent_responses_list_search[rowIndex].type == 3 ? "その他" : ""}
                            {this.props.task_rent_responses_list_search[rowIndex].type == 2 ? "契約フォロー" : ""}
                            {this.props.task_rent_responses_list_search[rowIndex].type == 1 ? this.props.task_rent_responses_list_search[rowIndex].way == 3 ?  "来店" : "追客" : ""}
                            {/* {moment(this.props.task_rent_responses_list_search[rowIndex].date).utc().format("YYYY/MM/DD HH:mm")}　 */}
                            {/* {moment(this.props.task_rent_responses_list_search[rowIndex].syokai_taiou_date).format("YYYY/MM/DD HH:mm")} */}
                            </Cell>)}
                    />
                    <Column width={this.state.columnWidths.time} isResizable={true} columnKey="time" header={"時間"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type == 5 ? moment(this.props.task_rent_responses_list_search[rowIndex].start).utc().format('YYYY/MM/DD HH:mm') : //フリー入力
                            this.props.task_rent_responses_list_search[rowIndex].type == 1 && this.props.task_rent_responses_list_search[rowIndex].way != 3 ? moment(this.props.task_rent_responses_list_search[rowIndex].date).utc().format('YYYY/MM/DD HH:mm') : // 追客
                            this.props.task_rent_responses_list_search[rowIndex].start && this.props.task_rent_responses_list_search[rowIndex].start != "Invalid date" ? moment(this.props.task_rent_responses_list_search[rowIndex].start).utc().format('YYYY/MM/DD HH:mm') + "～" + moment(this.props.task_rent_responses_list_search[rowIndex].end).utc().format('HH:mm') : ""}

                            </Cell>)}
                    />
                    <Column width={this.state.columnWidths.tanto} isResizable={true} columnKey="tanto" header={"担当"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type == 2 && this.props.task_rent_responses_list_search[rowIndex].contract_user_id && this.props.users && this.props.users.length > 0 ? this.props.users.filter(a=>a.id == this.props.task_rent_responses_list_search[rowIndex].contract_user_id)[0].nickname : ""}
                            {this.props.task_rent_responses_list_search[rowIndex].type != 2 ? this.props.task_rent_responses_list_search[rowIndex].user_nickname : ""}
                            </Cell>)}
                    />
                    <Column width={this.state.columnWidths.hankyo_tanto} isResizable={true} columnKey="hankyo_tanto" header={"反響担当"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type != 2 && this.props.task_rent_responses_list_search[rowIndex].hankyo_user_id && this.props.users && this.props.users.length > 0 ? this.props.users.filter(a=>a.id == this.props.task_rent_responses_list_search[rowIndex].hankyo_user_id)[0].nickname : ""}
                            </Cell>)}
                    />
                    {/* <Column width={this.state.columnWidths.primary_tanto} isResizable={true} columnKey="primary_tanto" header={"追客担当"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type != 2 && this.props.task_rent_responses_list_search[rowIndex].primary_user_id && this.props.users && this.props.users.length > 0 ? this.props.users.filter(a=>a.id == this.props.task_rent_responses_list_search[rowIndex].primary_user_id)[0].nickname : ""}
                            </Cell>)}
                    /> */}
                    
                    
                    <Column width={this.state.columnWidths.okyaku} isResizable={true} columnKey="okyaku" header={"お客様名"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {/* {this.props.task_rent_responses_list_search[rowIndex].type} */}
                            {(() => {// 0:出勤 1:来店とか 2:契約フォロー 3:タスク 4:予定件数 5:フリー

                                if (this.props.task_rent_responses_list_search[rowIndex].type != 3 && this.props.task_rent_responses_list_search[rowIndex].type != 5){
                                    return(<div>
                                        {this.props.task_rent_responses_list_search[rowIndex].rank === 1 ? <span className="badge badge-danger">S</span> : 
                                        this.props.task_rent_responses_list_search[rowIndex].rank === 2 ? <span className="badge badge-warning">A</span> : 
                                        this.props.task_rent_responses_list_search[rowIndex].rank === 3 ? <span className="badge badge-primary">B</span> : 
                                        this.props.task_rent_responses_list_search[rowIndex].rank === 4 ? <span className="badge badge-light">C</span> : ""}
                                        {this.props.task_rent_responses_list_search[rowIndex].title}
                                        </div>)
                                }

                            })()}




                        </Cell>)}
                    />

                    <Column width={this.state.columnWidths.memo} isResizable={true} columnKey="memo" header={"メモ"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].memo}
                            </Cell>)}
                    />


                    <Column width={this.state.columnWidths.follow_name} isResizable={true} columnKey="follow_name" header={"内容"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type == 2 ? <span>
                                {this.props.task_rent_responses_list_search[rowIndex].rent_m_contract_follow_id == 8 ? <VpnKeyIcon color="primary" /> :
                                this.props.task_rent_responses_list_search[rowIndex].rent_m_contract_follow_id == 3 ? this.props.task_rent_responses_list_search[rowIndex].way == 2 ? <ContactsIcon color="primary" /> : <DescriptionIcon color="primary" /> :<DescriptionIcon color="primary" />}

                                {this.props.task_rent_responses_list_search[rowIndex].rent_m_contract_follow_name}</span> : ""}
                            
                            {this.props.task_rent_responses_list_search[rowIndex].type === 1 && this.props.task_rent_responses_list_search[rowIndex].way === 3 ? 
                                this.props.task_rent_responses_list_search[rowIndex].returning == 0 ?
                                        this.props.task_rent_responses_list_search[rowIndex].rent_hearing_sheet_id > 0 ? 
                                            <span><DirectionsWalkIcon /> <FiberNewIcon style={{ color: "red" }} /><span className="badge badge-primary" style={{ fontWeight: "normal", marginRight: 1 }}>web</span></span>
                                            : <span><DirectionsWalkIcon  /> <FiberNewIcon style={{ color: "red" }} /></span>
                                        : <DirectionsWalkIcon /> 
                                : ""}

                            {this.props.task_rent_responses_list_search[rowIndex].type === 1 && this.props.task_rent_responses_list_search[rowIndex].way === 3 ?
                                this.props.task_rent_responses_list_search[rowIndex].going == 2 ? <span className="badge badge-primary" style={{ fontWeight: "normal", backgroundColor: "gray", marginRight: 1 }}>現</span> : 
                                this.props.task_rent_responses_list_search[rowIndex].going == 3 ? <span ><LaptopChromebookIcon /></span> : ""
                            :""}

                            {
                            this.props.task_rent_responses_list_search[rowIndex].type == 1 && this.props.task_rent_responses_list_search[rowIndex].way == 1 ? <span><CallIcon color="success" />TEL</span> : 
                            this.props.task_rent_responses_list_search[rowIndex].type == 1 && this.props.task_rent_responses_list_search[rowIndex].way == 2 ? (this.props.task_rent_responses_list_search[rowIndex].mail_template_id ? <span><ScheduleSendIcon />メール自動送信</span> : <span><EmailIcon color="primary" />メール</span>) :
                            this.props.task_rent_responses_list_search[rowIndex].type == 1 && this.props.task_rent_responses_list_search[rowIndex].way == 3 ? "":
                            this.props.task_rent_responses_list_search[rowIndex].type == 1 && this.props.task_rent_responses_list_search[rowIndex].way == 4 ? (this.props.task_rent_responses_list_search[rowIndex].rent_line_schedule_id ? <span><ScheduleSendIcon />LINE自動送信</span> : <span className="badge badge-success">LINE</span>) : ""
                            }
                            </Cell>)}
                    />
                    <Column width={this.state.columnWidths.biko} isResizable={true} columnKey="biko" header={"備考"} cell={({ rowIndex, ...props }) => (
                        <Cell {...props}>
                            {this.props.task_rent_responses_list_search[rowIndex].type == 3 ? this.props.task_rent_responses_list_search[rowIndex].title : this.props.task_rent_responses_list_search[rowIndex].remarks}
                            </Cell>)}
                    />
                </Table>:""}

                <TaskDialog
                    onClose={this.handleClose}
                    // event_type={this.state.event_type}
                    event_type={this.state.event_type}
                    modal={this.props.rent_tasks_modal}
                />
            </div>
        );
    }


}



const mapStateToProps = (state) => {
    return {
        rent_tasks_disp_form: state.task.rent_tasks_disp_form,
        task_rent_responses_list_search: state.task.task_rent_responses_list_search,
        rent_task_detail: state.calendar.rent_task_detail,
        calendar_free_detail: state.calendar.calendar_free_detail,
        contract_follow_editer: state.contractfollow.contract_follow_editer,
        rent_customer_contacts_forms: state.customerContactsReducer.rent_customer_contacts_forms,
        rent_tasks_modal: state.task.rent_tasks_modal,
        users: state.masterReducer.users,
        calendar_users: state.calendar.users,
    }
}
function mapDispatchToProps(dispatch) {
    return {
        change_rent_tasks_modal(state) {
            dispatch(Task.change_rent_tasks_modal(state))
        },
        change_rent_task_detail(state) {
            dispatch(Calendar.change_rent_task_detail(state))
        },
        change_calendar_free_detail(state) {
            dispatch(Calendar.change_calendar_free_detail(state))
        },
        change_contract_follow_editer(state) {
            dispatch(ContractFollow.change_contract_follow_editer(state))
        },
        change_rent_customer_contacts_forms(state) {
            dispatch(CustomerContacts.change_rent_customer_contacts_forms(state))
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskComponent);