import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Column, Cell } from 'fixed-data-table-2';
import 'fixed-data-table-2/dist/fixed-data-table.css';


import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';


import * as CustomerContacts from './Saga/CustomerContacts';

import Tooltip from '@mui/material/Tooltip';

import MenuItem from '@mui/material/MenuItem';import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import Select from '@mui/material/Select';import EditIcon from '@mui/icons-material/Edit';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';


import MaskedInput from 'react-text-mask';
var moment = require('moment-timezone');


const phoneNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];


const mobileNumberMask = [
  "(",
  /[0-9]/,
  /\d/,
  /\d/,
  ")",
  " ",
  /\d/,
  /\d/,
  /\d/,
  /\d/,
  "-",
  /\d/,
  /\d/,
  /\d/,
  /\d/
];
function TelMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (

    <MaskedInput
      {...other}
      // ref={inputRef}
      mask={String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "090" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "080" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "070" || String(props.value).replace("(", "").replace(")", "").replace("-", "").slice(0, 3) === "050" ? mobileNumberMask : phoneNumberMask}
      placeholderChar={'\u2000'}
    // showMask
    />
  );
}

const Base = ({ column, props, rowIndex }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_visits[rowIndex][column.key]}
  </Cell>;


const Review = ({ column, props, rowIndex, onClickReview }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    {props.rent_visits[rowIndex][column.key] == 0 ? "-":props.rent_visits[rowIndex][column.key]}
      {/* <IconButton style={{padding:0}} size="small" aria-label="edit" onClick={() => onClickReview(0, rowIndex)}>
        {props.rent_visits[rowIndex][column.key] >= 1 ? <StarBorderIcon /> :<StarIcon />}
      </IconButton>
      <IconButton style={{padding:0}} size="small" aria-label="edit" onClick={() => onClickReview(1, rowIndex)}>
        {props.rent_visits[rowIndex][column.key] >= 2 ? <StarBorderIcon /> :<StarIcon />}
      </IconButton>
      <IconButton style={{padding:0}} size="small" aria-label="edit" onClick={() => onClickReview(2, rowIndex)}>
        {props.rent_visits[rowIndex][column.key] >= 3 ? <StarBorderIcon /> :<StarIcon />}
      </IconButton>
      <IconButton style={{padding:0}} size="small" aria-label="edit" onClick={() => onClickReview(3, rowIndex)}>
        {props.rent_visits[rowIndex][column.key] >= 4 ? <StarBorderIcon /> :<StarIcon />}
      </IconButton>
      <IconButton style={{padding:0}} size="small" aria-label="edit" onClick={() => onClickReview(4, rowIndex)}>
        {props.rent_visits[rowIndex][column.key] >= 5 ? <StarBorderIcon /> :<StarIcon />}
      </IconButton> */}
  </Cell>;




const Edit = ({ column, props, rowIndex, monies }) =>
  <Cell style={{ whiteSpace: "nowrap" }} >
    <Tooltip title="編集" >
      <IconButton style={{marginLeft:-5, marginTop:-5}} size="small" aria-label="edit" onClick={() => props.set_visit_edit({ id: props.rent_visits[rowIndex].rent_customer_contact_id, disp: true, rent_visit_id: props.rent_visits[rowIndex].id, tatemono_name: props.rent_visits[rowIndex].tatemono_name, tatemono_id: props.rent_visits[rowIndex].tatemono_id, remarks: props.rent_visits[rowIndex].remarks, date: props.rent_visits[rowIndex].date, user_id: props.rent_visits[rowIndex].user_id, room_id: props.rent_visits[rowIndex].room_id, room_name: props.rent_visits[rowIndex].room_name, no: props.rent_visits[rowIndex].no, review: props.rent_visits[rowIndex].review })}>
        <EditIcon />
      </IconButton>
    </Tooltip>
  </Cell>;




var SortTypes = {
  ASC: 'ASC',
  DESC: 'DESC',
};


function reverseSortDirection(sortDir) {
  return sortDir === SortTypes.DESC ? SortTypes.ASC : SortTypes.DESC;
}
class SortHeaderCell extends React.Component {
  constructor(props) {
    super(props);

    this._onSortChange = this._onSortChange.bind(this);
  }

  render() {
    var { onSortChange, sortDir, children, ...props } = this.props;
    return (
      <Cell {...props} onClick={this._onSortChange}>

        {children} {sortDir ? (sortDir === SortTypes.DESC ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />) : ''}

      </Cell>
    );
  }

  _onSortChange(e) {

    e.preventDefault();

    if (this.props.onSortChange) {
      this.props.onSortChange(
        this.props.columnKey,
        this.props.sortDir ?
          reverseSortDirection(this.props.sortDir) :
          SortTypes.DESC
      );
    }
  }
}


const columns = [
  { key: 'edit', name: '編集', width: 40 },
  { key: 'tatemono_name', name: '物件名', width: 140 },
  { key: 'room_name', name: '部屋名', width: 60 },
  {
    key: 'remarks', name: '備考', width: 120,

  },
  { key: 'review', name: '評価', width: 50 },


]







class VisitList extends Component {
  constructor(props) {
    super(props);


    // this.props.rent_m_contract_follows_disp.map(async function (value) {
    //   if (value.disp) {
    //     columns.push({ key: value.key_name, name: value.name, company: value.company, width: 80 })
    //   }
    // })
    console.log(columns)

    this.state = {
      columns: columns,
      reflesh: false,
      modalreserve: false,
      cardx: window.innerWidth - 600,
      colSortDirs: {},

      // dataList: new FakeObjectDataListStore(1000000),
      // columnWidths: {
      //   firstName: 240,
      //   lastName: 150,
      //   sentence: 140,
      //   companyName: 60,
      // },
    };
    // this.props.get_contract_follow_list()
    this._onColumnResizeEndCallback = this._onColumnResizeEndCallback.bind(this);


    this.onClickReview  = this.onClickReview .bind(this);
    this.add_new = this.add_new.bind(this);


  }





  _onColumnResizeEndCallback(newColumnWidth, columnKey) {

    let columnsset = this.state.columns.map(function (value) {
      if (value.key === columnKey) {
        value.width = newColumnWidth
      }
      return value
    })

    this.setState({ columns: columnsset })

  }



  add_new() {
    let rent_contract_editor_forms = this.props.rent_contract_editor_forms
    // let family = rent_contract_editor_forms.family.map(function (value) {
    //   return value
    // })

    rent_contract_editor_forms.family.push({
      name: "",
      relations: "",
      sex: 0,
      birthday: null,
      workplace: '',
      tel: '',
      nationality: '',
      remarks: '',
    })
    // rent_contract_editor_forms.family = family
    this.props.change_rent_contract_editor_forms(rent_contract_editor_forms)

    this.setState({ reflash: !this.state.reflash })
  }


  onClickReview(th, index){

    var detai = {...this.props.rent_visits[index]}
    detai.review = th + 1

    this.props.rent_visits[index] = detai
    // if(review < th){

    // }

  }





  render() {
    let hiwari = []

    return (
      <div id="contract_car" >



        {<div style={{ marginTop: 10, marginBottom: 10 }}><Table
          rowHeight={40}
          rowsCount={this.props.rent_visits.length}
          onColumnResizeEndCallback={this._onColumnResizeEndCallback}
          isColumnResizing={false}
          touchScrollEnabled={true}
          width={380}
          height={this.props.rent_visits.length * 40 + 60}
          // width={window.innerWidth - 110}
          // height={window.innerHeight - 250}
          headerHeight={40}>
          {this.state.columns.map(function (value, i) {
            return <Column
              columnKey={value.key}
              header={<SortHeaderCell
                onSortChange={this._onSortChange}
                sortDir={this.state.colSortDirs[value.key]}
                style={{ whiteSpace: "nowrap" }}>{value.name}</SortHeaderCell>}

              // cell={({ rowIndex, ...props }) => (<Cell style={{ whiteSpace: "nowrap" }} >{this.props.seisan_follow_list[rowIndex][value.key]}</Cell>)}
              cell={({ rowIndex, ...props }) => (

                value.key === "edit" ? <Edit column={value} props={this.props} rowIndex={rowIndex} ></Edit>

                : value.key === "review" ? <Review column={value} props={this.props} rowIndex={rowIndex} onClickReview={this.onClickReview}></Review>
                  // :
                  : <Base column={value} props={this.props} rowIndex={rowIndex} ></Base>

              )}

              width={value.width}
              isResizable={true}


            />
          }, this)}

        </Table > </div>
        }




      </div >
    );
  }
};


//export  default CustomerList;
const mapStateToProps = (state) => {
  return {
    section_select: state.masterReducer.section_select,
    // search_modal: state.Adjustment.search_modal,
    user_details: state.masterReducer.user_details,
    users_select: state.masterReducer.users_select,
    rent_contract_details: state.contracts.rent_contract_details,
    rent_contract_family_editor_disp: state.contracts.rent_contract_family_editor_disp,
    rent_contract_editor_forms: state.contracts.rent_contract_editor_forms,
    // rent_visits: state.customerContactsReducer.rent_visits,
  }
}
function mapDispatchToProps(dispatch) {
  return {


    get_rent_visits(state) {
      dispatch(CustomerContacts.get_rent_visits(state))
    },

    set_visit_edit(state) {
      dispatch(CustomerContacts.set_visit_edit(state))
    },
    // change_rent_contract_editor_forms(state) {
    //   dispatch(Contracts.change_rent_contract_editor_forms(state))
    // },
    // check_rent_contract_editor_disp(state) {
    //   dispatch(Contracts.check_rent_contract_editor_disp(state))
    // },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(VisitList);