import React, { Component } from 'react';
import { connect } from 'react-redux';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import * as Contracts from './Saga/Contracts';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import ContractSendMail from './ContractSendMail';
import { compose } from 'redux'
import withStyles from '@mui/styles/withStyles';

const styles = (theme) => ({
backdrop: {
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: 20000,
    color: '#fff',
},
})
// const shougakkouList = [
//     { id: 43, label: "浅野小学校" },
//     { id: 84, label: "庵治小学校" },
//     { id: 33, label: "一宮小学校" },
//     { id: 41, label: "植田小学校" },
//     { id: 31, label: "円座小学校" },
//     { id: 15, label: "太田小学校" },
//     { id: 16, label: "太田南小学校" },
//     { id: 44, label: "大野小学校" },
//     { id: 86, label: "男木小学校" },

// //か行
//     { id: 3, label: "亀阜小学校" },
//     { id: 30, label: "川岡小学校" },
//     { id: 37, label: "川島小学校" },
//     { id: 28, label: "川添小学校" },
//     { id: 50, label: "川東小学校" },
//     { id: 11, label: "木太小学校" },
//     { id: 10, label: "木太北部小学校" },
//     { id: 12, label: "木太南小学校" },
//     { id: 24, label: "鬼無小学校" },
//     { id: 25, label: "香西小学校" },
//     { id: 54, label: "香南小学校" },
//     { id: 53, label: "国分寺南部小学校" },
//     { id: 52, label: "国分寺北部小学校" },

// //さ行
//     { id: 35, label: "三渓小学校" },
//     { id: 85, label: "塩江小学校" },
//     { id: 22, label: "下笠居小学校" },
//     { id: 60, label: "新番丁小学校" },
//     { id: 38, label: "十河小学校" },

// //た行
//     { id: 61, label: "高松第一小学校" },
//     { id: 49, label: "田中小学校" },
//     { id: 34, label: "多肥小学校" },
//     { id: 32, label: "檀紙小学校" },
//     { id: 13, label: "中央小学校" },
//     { id: 26, label: "鶴尾小学校" },
//     { id: 23, label: "弦打小学校" },

// //な行

// //は行
//     { id: 47, label: "白山小学校" },
//     { id: 9, label: "花園小学校" },
//     { id: 29, label: "林小学校" },
//     { id: 40, label: "東植田小学校" },
//     { id: 48, label: "氷上小学校" },
//     { id: 45, label: "平井小学校" },
//     { id: 36, label: "仏生山小学校" },
//     { id: 20, label: "古高松小学校" },
//     { id: 21, label: "古高松南小学校" },

// //ま行
//     { id: 27, label: "前田小学校" },
//     { id: 55, label: "牟礼小学校" },
//     { id: 59, label: "牟礼北小学校" },
//     { id: 51, label: "牟礼南小学校" },

// //や行
//     { id: 17, label: "屋島小学校" },
//     { id: 19, label: "屋島西小学校" },
//     { id: 18, label: "屋島東小学校" },

// //ら行
//     { id: 14, label: "栗林小学校" }
// ]
class ContractSheetDataCheck extends Component {
  constructor(props) {
    super(props);
    this.state = { title:'', Mail:'', name:'', company:'', sendmail:false, }
    this.change_new = this.change_new.bind(this);
    this.change_old = this.change_old.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.hundleMailClick = this.hundleMailClick.bind(this);
    this.hundleModalOpen = this.hundleModalOpen.bind(this);
  }
  componentDidMount() {
    let check_contract_sheet_text = this.props.check_contract_sheet_text
    if(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ){
        check_contract_sheet_text['Mail'] = 'denshikeiyaku1@relo.jp'
        this.props.change_check_contract_sheet_text(check_contract_sheet_text);
    }
  }
  hundleModalOpen(){
    this.props.change_check_contract_send_modal(true)
    this.props.get_sendmail_files()
  }
  change_new(e) {
    this.props.change_rent_contract_logs_new(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  change_old(e) {
    this.props.change_rent_contract_logs_old(e.target.value);
    this.props.change_rent_contract_logs_ver()
  }
  handleButtonClick(page) {
    this.setState({ page })
  }
  handleRotateClick(rotate) {
    this.setState({ rotate })
  }
  handleResend(payment){
    this.props.contracts_resend_parking({responses_id:this.props.rent_contract_details.rent_response_id,payment:payment})
  }
  hundleMailClick(checked){
    this.props.check_send_files(checked);
    this.setState({sendmail:checked})
    this.props.change_check_contract_send(checked)
  }
//   handleChange(set){
//     console.log(set)
//   }
  handleChange = (event) => {
    let check_contract_sheet_text = this.props.check_contract_sheet_text
    check_contract_sheet_text[event.target.name] = event.target.value;
    // this.props.change_rent_contract_editor_forms({});
    if(event.target.name == 'procedureNum' && event.target.value == ''){
        if(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ){
            check_contract_sheet_text['Mail'] = 'denshikeiyaku1@relo.jp'
        }
    }
    this.props.change_check_contract_sheet_text(check_contract_sheet_text);
    // if (event.target.name === "condition") {
    // }
    if(this.props.check_contract_sheet_text.name != '' && this.props.check_contract_sheet_text.title != '' && this.props.check_contract_sheet_text.Mail != ''){
        this.props.checkState(true);
    }else{
        this.props.checkState(false);
    }
    if(event.target.name == "shougakkou"){
        this.props.change_hazardmap(event.target.value);
    }
    this.setState({ reflash: !this.state.reflash });
  }
  handleSelectChange(event){
    this.props.change_hazardmap(event.target.value);
    
  }
  render() {
    let ver = []
    return (
        <div className="component" style={{padding: 10}}>
            <Grid container spacing={2}>
                {
                    this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ? 
                        <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                            <TextField
                                
                                id="title"
                                error={this.props.check_contract_sheet_text.procedureNum == '' ? true : false}
                                helperText={this.props.check_contract_sheet_text.procedureNum == '' ? '入力してください。' : ''}
                                //   className={classes.textField}
                                label="手続き番号"
                                margin="normal"
                                variant="outlined"
                                onChange={this.handleChange}
                                name="procedureNum"
                                defaultValue={this.props.check_contract_sheet_text.procedureNum}
                                style={{ float: "left", width: "100%" }}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        : ''
                }
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <TextField
                        
                        id="title"
                        error={this.props.check_contract_sheet_text.title == '' ? true : false}
                        helperText={this.props.check_contract_sheet_text.title == '' ? '入力してください。' : ''}
                        //   className={classes.textField}
                        label="タイトル"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        name="title"
                        defaultValue={this.props.check_contract_sheet_text.title}
                        style={{ float: "left", width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <TextField
                        id="Mail"
                        error={ !(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814) && !this.props.check_contract_sheet_text['Mail'].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/)}
                        //   className={classes.textField}
                        helperText={!(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814) && !this.props.check_contract_sheet_text['Mail'].match(/^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/) ? "※メールアドレスが有効ではありません。" : ''}
                        label="メールアドレス"
                        margin="normal"
                        disabled={this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ? true : false}
                        variant="outlined"
                        onChange={this.handleChange}
                        name="Mail"
                        defaultValue={this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814 ? 'denshikeiyaku1@relo.jp' : this.props.check_contract_sheet_text['Mail'] ? this.props.check_contract_sheet_text['Mail']:''}
                        style={{ float: "left", width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <TextField
                        // disabled
                        error={this.props.check_contract_sheet_text.name == '' ? true : false}
                        helperText={this.props.check_contract_sheet_text.name == '' ? '入力してください。' : ''}
                        id="name"
                        //   className={classes.textField}
                        label="名前"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        name="name"
                        defaultValue={this.props.check_contract_sheet_text.name}
                        style={{ float: "left", width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <TextField
                        id="company"
                        //   className={classes.textField}
                        label="会社名"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        name="company"
                        defaultValue={''}
                        style={{ float: "left", width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <TextField
                        id="message"
                        //   className={classes.textField}
                        label="メッセージ"
                        margin="normal"
                        variant="outlined"
                        onChange={this.handleChange}
                        name="message"
                        defaultValue={this.props.check_contract_sheet_text.message}
                        style={{ float: "left", width: "100%" }}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                { !(this.props.rent_contract_details.rent_m_contract_type_id == 1 && this.props.rent_contract_details.rent_m_corporate_id == 4814) ?     
                    <Grid item xs={12} md={12}>
                        <FormGroup>
                            <FormControlLabel control={<Checkbox value={false} onChange={(e)=>this.hundleMailClick(e.target.checked)}/>} 
                            label={(this.state.sendmail && (this.props.check_contract_send_files.length > 0 || (this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.month_payment_type != 1 && this.props.rent_contract_details.apply_payment_type != 1 )) && this.props.check_contract_send_mail_template) || !this.state.sendmail  ? "案内メールも併せて送信する" : "メールテンプレートを読み込み中…"} />
                        </FormGroup>
                    </Grid>
                    :
                    ''
                }    
                {
                    this.state.sendmail && (this.props.check_contract_send_files.length > 0 || (this.props.rent_contract_details.parking_application_id != 0 && this.props.rent_contract_details.month_payment_type != 1 && this.props.rent_contract_details.apply_payment_type != 1 )) && this.props.check_contract_send_mail_template ? 
                    <>
                        <Grid item xs={3} md={3}></Grid>
                        <Grid item xs={6} md={6}>
                            <FormGroup>
                                <Button variant="contained" onClick={() => this.hundleModalOpen()}>メール確認</Button>
                            </FormGroup>
                        </Grid>
                        <Grid item xs={3} md={3}></Grid>
                    </>
                    : ""
                }
                <ContractSendMail/>
                {/* {!this.props.check_contract_sheet_text.shougakkou ? 
                <Grid item xs={12} md={12} style={{paddingBottom: 10}}>
                    <FormControl sx={{ m: 1, width: 300 }}>
                        <Select
                            displayEmpty
                            value={this.props.check_contract_sheet_text.shougakkou}
                            name="shougakkou"
                            onChange={this.handleChange}
                            input={<OutlinedInput />}
                            inputProps={{ 'aria-label': 'Without label' }}
                        >
                        <MenuItem disabled value="">
                            <em>選択無し</em>
                        </MenuItem>
                        {shougakkouList.map((name) => (
                            <MenuItem
                            value={name.id}
                            >
                            {name.label}
                            </MenuItem>
                        ))}
                        </Select>
                    </FormControl>
                </Grid> : ""
                } */}
            </Grid>
            
            <Backdrop className={this.props.classes.backdrop} open={this.props.back_drop_contract_mail}>
              <CircularProgress color="inherit" />
            </Backdrop>
        </div >
    );
  }
}
//コンテナ
const mapStateToProps = state => {
  return {
    check_contract_sheet_file:state.contracts.check_contract_sheet_file,
    check_contract_sheet_text:state.contracts.check_contract_sheet_text,
    rent_contract_details:state.contracts.rent_contract_details,
    check_contract_send_modal:state.contracts.check_contract_send_modal,
    check_contract_send_mail_template:state.contracts.check_contract_send_mail_template,
    check_contract_send_files:state.contracts.check_contract_send_files,
    back_drop_contract_mail:state.contracts.back_drop_contract_mail
  }
}
function mapDispatchToProps(dispatch) {
  return {
    contracts_resend_parking(state){
        dispatch(Contracts.contracts_resend_parking(state))
    },
    change_check_contract_sheet_text(state){
      dispatch(Contracts.change_check_contract_sheet_text (state))
    },
    change_hazardmap(state){
      dispatch(Contracts.change_hazardmap(state))
    },
    check_send_files(state){
        dispatch(Contracts.check_send_files(state))
    },
    get_sendmail_files(state){
        dispatch(Contracts.get_sendmail_files(state))
    },
    change_check_contract_send_modal(state){
        dispatch(Contracts.change_check_contract_send_modal(state))
    },
    change_check_contract_send(state){
        dispatch(Contracts.change_check_contract_send(state))
    }
  };
}

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps))(ContractSheetDataCheck);
