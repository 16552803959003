import React, { Component } from 'react';

import { connect } from 'react-redux';
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Select from 'react-select';
import { reduxForm } from 'redux-form';
import MaterialUiSelect from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import MaterialSelect from '@mui/material/Select';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import * as ResponseList from './Saga/ResponseList';

import * as ResponseDetail from './../ResponseDetail/Saga/Responses';
import ResponseFollowSheet from '../ResponseFollowSheet/Index';
import * as ResponseFollowSheetSaga from './../ResponseFollowSheet/Saga/ResponseFollowSheet';
import ResponseLineSendComponent from './ResponseLineSendComponent';
import ResponseLineSendListComponent from './ResponseLineSendListComponent';

import Button from '@mui/material/Button';
import '../../Css/Table.css';
import '../../Css/Form.css';
import './Css/Table.css';
import Chip from '@mui/material/Chip';
import moment from 'moment';
import FormLabel from '@mui/material/FormLabel';
import { Scrollbars } from 'react-custom-scrollbars';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { ModalHeader, ModalBody, Modal } from 'reactstrap';
import ContactsComponent from '../CustomerContacts/ContactsComponent';
import { Col } from 'reactstrap';
import { CSVLink } from "react-csv";
import ListItemText from '@mui/material/ListItemText';

import HistoryIcon from '@mui/icons-material/History';

let autokana1;
let Bosyuflg;
let timer


// redux-form 用バリデーション関数
const validate = (values) => {

    // 検証に失敗したら values と同じキーで
    // エラーメッセージをセットする。
    const errors = {};



    return errors;
};

const RenderSelectInput = ({ input, options, name, id }) => (

    <Select
        {...input}
        id={id}
        name={name}
        options={options}
        value={input.value}
        onChange={(a) => input.onChange(a ? a.value : 0)}
        onBlur={(a) => input.onBlur(a ? a.value : 0)}
    // placeholder="店舗を選択"
    />


)

export class RenderField extends React.Component {

    render() {
        const { className, id, name, type, style, input, disabled, onBlur, meta: { touched, error } } = this.props;
        return (
            <div>
                {type === 'textarea' ?
                    <textarea id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style} {...input} type={type} disabled={disabled} />
                    : type === 'select' ?
                        <select id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled}>{this.props.children}</select>
                        :
                        <input id={id} name={name} onBlur={onBlur} className={touched && error ? 'alert-danger ' + className : className} style={style}  {...input} type={type} disabled={disabled} />
                }
                {touched && error && <div className="text-danger">{error}</div>}
            </div>
        );
    }
};

const ReduxFormDateRange = (props) => {


    console.log(props);
    return (

        <DatePicker

            selected={props.input.value !== "" ? new Date(props.input.value) : ""}
            //selected={moment(props.input.value, "YYYY-MM-DD").format("YYYY-MM-DD") || null}
            onChange={props.input.onChange}
            //showTimeSelect
            dateFormat="yyyy/MM/dd"
            className={props.className}
        />
    )
}

function secToTime(seconds) {
    const hour = Math.floor(seconds / 3600);
    const min = Math.floor(seconds % 3600 / 60);
    const sec = seconds % 60;
    let time = '';
    // if(hour !==0 ) {
    //   time = `${hour}時間${min}分${sec}秒`;
    // }else if(min !==0) {
    //   time = `${min}分${sec}秒`;
    // }else {
    //   time = `${sec}秒`;
    // }
    if(hour !==0 ) {
        time = `${hour}時間${min}分`;
      }else if(min !==0) {
        time = `${min}分`;
      }else {
        time = ``;
      }
    return time;
  }

function SimpleDialog(props) {

    const { section_id, open, change_response_follow_sheet_open } = props;
  
    const handleClose = () => {
      change_response_follow_sheet_open(false)
      // onClose(selectedValue)
    };
  
    const handleListItemClick = (value) => {
      // onClose(value);
    };
  
    return (
        <Dialog
          maxWidth="xl"
          // classes={{
          //   paper: classes.dialog
          // }}
          onClose={handleClose} aria-labelledby="simple-dialog-title" open={open}>
          <DialogTitle id="simple-dialog-title">追客状況
            <IconButton
                aria-label="close"
                style={{ float: "right" }}
                onClick={handleClose}
                size="large">
              <CloseIcon />
            </IconButton>
          </DialogTitle>
    
          <ResponseFollowSheet onClose={handleClose} section_id={section_id} />
    
        </Dialog>
    );
  }

  const headers = [
    // { label: "request", key: "request" },
    // { label: "媒体", key: "request_name" },
    // { label: "media", key: "media" },
    // { label: "メディア", key: "media_name" },

    // { label: "status", key: "status" },

    // { label: "反響日", key: "date" },
    { label: "反響日", key: "date_format" },
    // { label: "受信日時", key: "date" },
    { label: "最終対応", key: "last_response_date_format" },

    // { label: "rank", key: "rank" },
    { label: "ランク", key: "rank_name" },
    // { label: "店舗", key: "section_id" },
    { label: "店舗", key: "section_name" },
    { label: "反響担当", key: "user_id" },
    { label: "来店数", key: "coming_response_count" },
    { label: "メール反響初回対応まで", key: "first_response_time" },
    { label: "コンタクト数", key: "contact_response_count" },

    // { label: "intended_rent_m_response_status_id", key: "intended_rent_m_response_status_id" },
    { label: "予定内容", key: "intended_rent_m_response_status_name" },
    { label: "予定時間", key: "intended_at" },

    // { label: "shops", key: "shops" },
    // { label: "反響店舗", key: "shops_name" },
    { label: "顧客名", key: "name1" },
    // { label: "mypage_student_independent", key: "mypage_student_independent" },
    { label: "TEL", key: "tel1" },
    { label: "メール", key: "email1" },

    // { label: "信用情報マッチングあり", key: "attentions" },
    
    // { label: "在学/受験", key: "mypage_student_examinee_name" },
    { label: "引越理由", key: "rent_m_reason_name" },


    { label: "社会人/学生", key: "student_independent_name" },
    { label: "学校名", key: "rent_m_school_name" },
    { label: "受験予定", key: "jyukenyotei" },
    { label: "学生予約", key: "gakusei_yoyaku" },
    { label: "学年", key: "gakunen" },
    { label: "合否", key: "gouhi" },

    // { label: "連絡方法", key: "main_media_name" },
    { label: "引越予定", key: "request_moving_time_name" },
    { label: "問合物件", key: "building_name" },
    
    { label: "反響媒体", key: "main_media_name" },
    { label: "経路", key: "path_name" },
    { label: "TEL", key: "mail_is_tel" },
    { label: "契約", key: "rent_contract_id" },
    { label: "追客終了日", key: "end_response_date" },
    { label: "追客終了理由", key: "end_response_reason_name" },
    { label: "追客終了備考", key: "end_response_remarks" },
    
    
  ];

  const headers_raiten = [
    // { label: "request", key: "request" },
    // { label: "媒体", key: "request_name" },
    // { label: "media", key: "media" },
    // { label: "メディア", key: "media_name" },

    // { label: "status", key: "status" },

    // { label: "反響日", key: "date" },
    { label: "反響日", key: "date_format" },
    // { label: "受信日時", key: "date" },
    { label: "最終対応", key: "last_response_date_format" },

    // { label: "rank", key: "rank" },
    { label: "ランク", key: "rank_name" },
    // { label: "店舗", key: "section_id" },
    { label: "店舗", key: "section_name" },
    { label: "反響担当", key: "user_name" },



    { label: "来店日", key: "raiten_date" },
    { label: "来店担当", key: "raiten_user_id" },

    { label: "見ず決め", key: "no_coming" },
    { label: "オンライン物説", key: "online" },
    { label: "案内", key: "going" },
    { label: "案内部署", key: "going_section" },
    { label: "案内担当", key: "going_user_id" },
    { label: "案内時間", key: "going_time" },
    { label: "成約", key: "is_application" },
    { label: "未成約理由", key: "rent_m_going_reason_id" },
    

    { label: "来店数", key: "coming_response_count" },
    { label: "メール反響初回対応まで", key: "first_response_time" },
    { label: "コンタクト数", key: "contact_response_count" },

    // { label: "intended_rent_m_response_status_id", key: "intended_rent_m_response_status_id" },
    { label: "予定内容", key: "intended_rent_m_response_status_name" },
    { label: "予定時間", key: "intended_at" },
    { label: "来店", key: "intend_coming" },

    // { label: "shops", key: "shops" },
    // { label: "反響店舗", key: "shops_name" },
    { label: "顧客名", key: "name1" },
    // { label: "mypage_student_independent", key: "mypage_student_independent" },
    { label: "社会人/学生", key: "student_independent_name" },
    { label: "学校名", key: "rent_m_school_name" },
    { label: "受験予定", key: "jyukenyotei" },
    { label: "学生予約", key: "gakusei_yoyaku" },
    { label: "学年", key: "gakunen" },
    { label: "合否", key: "gouhi" },


    { label: "TEL", key: "tel1" },
    { label: "メール", key: "email1" },

    // { label: "信用情報マッチングあり", key: "attentions" },
    
    // { label: "在学/受験", key: "mypage_student_examinee_name" },
    { label: "引越理由", key: "rent_m_reason_name" },


    // { label: "連絡方法", key: "main_media_name" },
    { label: "引越予定", key: "request_moving_time_name" },
    { label: "問合物件", key: "building_name" },
    
    { label: "反響媒体", key: "main_media_name" },
    { label: "経路", key: "path_name" },
    { label: "TEL", key: "mail_is_tel" },
    { label: "契約", key: "rent_contract_id" },
    { label: "追客終了日", key: "end_response_date" },
    { label: "追客終了理由", key: "end_response_reason_name" },
    { label: "追客終了備考", key: "end_response_remarks" },
    
    
  ];

  const rank_list =[
      {id:0, value:"未設定"},
      {id:1, value:"S"},
      {id:2, value:"A"},
      {id:3, value:"B"},
      {id:4, value:"C"}]

  const moving_list =[
      {id:1, value:"今月中"},
      {id:2, value:"来月中"},
      {id:3, value:"2ヶ月後"},
      {id:4, value:"3ヶ月後"},
      {id:5, value:"4ヶ月後"},
      {id:6, value:"5ヶ月後"},
      {id:7, value:"半年後"},
      {id:8, value:"1年以内"},
      {id:9, value:"未定いいのがあれば"}]

const way_list =[
    {id:1, value:"TEL"},
    {id:2, value:"メール"},
    {id:6, value:"LINE"},
    {id:7, value:"SNS"},
    {id:3, value:"社宅斡旋"},
    {id:5, value:"飛込"}]
class ResponseSearchComponent extends Component {

    constructor(props) {
        super(props);

        this.state = {
            reflash: false,

        };

        Bosyuflg = 1;

        this.handleFormSubmit = this.handleFormSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.change_primary_user_id = this.change_primary_user_id.bind(this);
        this.change_coming_user_id = this.change_coming_user_id.bind(this);
        this.change_going_user_id = this.change_going_user_id.bind(this);
        this.change_web_user_id = this.change_web_user_id.bind(this);
        this.change_contract_user_id = this.change_contract_user_id.bind(this);
        this.change_response_user_id = this.change_response_user_id.bind(this);
        this.open_response_add_set = this.open_response_add_set.bind(this);
        this.handle_agent_change = this.handle_agent_change.bind(this);
        this.onClear = this.onClear.bind(this);

        this.sort = this.sort.bind(this);


        this.onChange = this.onChange.bind(this);
        this.props.initialize(this.props.roomdetail);//フォームに初期値を反映させる

        this.togglecalender = this.togglecalender.bind(this);

        this.props.get_rent_m_agents()
    }

    togglecalender() {
        this.setState(prevState => ({
          modalcalender: !prevState.modalcalender
        }));
      }

    handleFormSubmit(event) {
        event.preventDefault();
        console.log(event)
        // form値取得
        console.log(this.props.forecasts);
        // form値取得
        //const params = {
        //    name1: event.target.name1.value,
        //    kana1: event.target.kana1.value,
        //};

        //alert(JSON.stringify(params, null, '  '));
    }

    onChange(e) {
        console.log(e.target.value);
        this.setState({ name1: e.target.value });
    }

    componentDidMount() {
        // This method is called when the component is first added to the document
        this.ensureDataFetched();
        //autokana1 = AutoKana.bind('#tatemonoName', '#tatemonoKana', { katakana: true });
    }

    componentDidUpdate() {
        // This method is called when the route parameters change
        this.ensureDataFetched();
    }

    ensureDataFetched() {
        //const startDateIndex = parseInt(this.props.match.params.startDateIndex, 10) || 0;
        //console.log(startDateIndex)

        //this.props.requestWeatherForecasts(0);

    }

    handleChange = (event) => {
        // alert(event.target.name)
        console.log(event)
        // alert(event.target.name)
        // alert(event.target.value)
        let formData = this.props.formData
        // formData[event.target.name] = event.target.value;
        // if (event.target.name === "condition") {

        //     formData[event.target.name] = event.target.value;

        //     formData.contractionDateStart = new Date();
        //     formData.contractionDateEnd = null;

        if (event.target.name === "contract_form" || 
        event.target.name === "other_contract_form" || 
        event.target.name === "student" || 
        event.target.name === "other_student" || 
        event.target.name === "gakusei_yoyaku" || 
        event.target.name === "check_end_response" || 
        event.target.name === "check_progress_response" || 
        event.target.name === "check_application" || 
        event.target.name === "line_img_url" || 
        event.target.name === "unfollowed" || 
        event.target.name === "line_send") {
            formData[event.target.name] = event.target.checked
            if(event.target.name === "line_send"){
                this.props.change_line_template_open(event.target.checked)
            }
            // alert(event.target.checked)
            // if (event.target.name === "check_end_response") {
            //     if (event.target.checked) {
            //         formData.response_date_start = !formData.response_date_start ? moment().add(-3, 'months').format("YYYY-MM-DD") : formData.response_date_start
            //     } else {
            //         formData.response_date_start = ''
            //     }

            // }
        } else if (event.target.name === "condition_radio") {
            formData[event.target.name] = event.target.value;
            if (event.target.value == 0) {
                formData["condition"] = ""
                formData.sort = 4
            }else if (event.target.value == 1) {
                formData["condition"] = ""
                formData.sort = 1
            }else if (event.target.value == 2) {
                formData["condition"] = "2,3"
                formData.sort = 4
            }
            if (event.target.value == 0 || event.target.value == 2) {
                formData["response_date_start"] = ""
            }else if (event.target.value == 1) {
                formData["response_date_start"] = moment().add(-3, "months").format("YYYY-MM-DD")
            }

        } else if (event.target.name === "jyukenyotei" || event.target.name === "condition") {

            if (event.target.checked) {
                let ary = formData[event.target.name] ? formData[event.target.name].split(",") : []
                ary.push(parseInt(event.target.value))
                ary = ary.filter(Boolean)
                formData[event.target.name] = ary.join(',')
            } else {
                formData[event.target.name] = formData[event.target.name].split(",").filter(item => parseInt(item) != parseInt(event.target.value)).join(',')

            }

        } else {
            formData[event.target.name] = event.target.value;
        }


        this.props.change_formData(formData);

        if (event.target.name === "section_id") {
            this.props.responseList()
        } else if (event.target.name === "check_end_response" || event.target.name === "check_progress_response" || event.target.name === "check_application" || event.target.name === "last_response_date_start") {
            // alert(formData[event.target.name])
            this.props.responseList()

        } else if (event.target.name === "condition_radio") {
            this.props.responseList()
            
        // } else if (event.target.name === "condition" && Number(event.target.value) === 5) {
        // } else if (event.target.name === "condition") {

        //     this.props.responseList()
        } else if (event.target.name === "name") {
            clearTimeout(timer);
            timer = setTimeout(function () {

                this.props.search_response_list2()
            }.bind(this), 800);

        } else {
            this.props.search_response_list2()

        }
        this.setState({ reflash: !this.state.reflash });
    }

    handle_agent_change(e, value) {
        
        let formData = this.props.formData

        if(value){
            formData.agent = value.id
        }else{
            formData.agent = 0
        }

        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });

    }

    change_response_user_id = (event, value) => {

        let formData = this.props.formData
        formData.response_user_id = value ? value.value : 0;

        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    change_primary_user_id = (event, value) => {

        let formData = this.props.formData
        formData.primary_user_id = value ? value.value : 0;

        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    change_coming_user_id = (event, value) => {

        let formData = this.props.formData
        formData.coming_user_id = value ? value.value : 0;

        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    change_going_user_id = (event, value) => {

        let formData = this.props.formData
        formData.going_user_id = value ? value.value : 0;

        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    change_web_user_id = (event, value) => {

        let formData = this.props.formData
        formData.web_user_id = value ? value.value : 0;
    
        this.props.change_formData(formData);
    
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    change_contract_user_id = (event, value) => {

        let formData = this.props.formData
        formData.contract_user_id = value ? value.value : 0;
    
        this.props.change_formData(formData);
    
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }

    open_response_add_set() {
        this.props.change_line_user_id({ line_user_id: 0, line_account_section_id: 0 })
        this.props.open_response_add({})

    }

    sort(sort) {
        let formData = this.props.formData
        formData.sort = Number(sort.target.value)
        this.props.change_formData(formData);
        this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }
    onClear() {

        let formData = this.props.formData

        // if (!(formData.section_id === this.props.user_details.amSectionId)) {
        //     formData.section_id = this.props.user_details.amSectionId
        //     this.props.change_formData(formData);
        //     this.props.responseList()
        // }

        formData = {
            sort: 1,
            intend: 0,
            // email: '',
            tatemonoName: '',
            applicationDate: '',
            contractionDateStart: '',
            contractionDateEnd: '',
            condition: "1",
            response_user_id: 0,
            primary_user_id: 0,
            coming_user_id: 0,
            web_user_id: 0,
            name: '',

            // sectionId: 10,
            section_id: this.props.user_details.amSectionId,
            building_name: '',
            response_date_start: '',
            response_date_end: '',
            application_date_start: '',
            application_date_end: '',
            coming_date_start: '',
            coming_date_end: '',
            contract_date_start: '',
            contract_date_end: '',
            response_condition: 0,
            tel1: '',
            rank: [],
            moving_time: [],
            way: [],
            media: [],
            agent: [],
            reason: [],
            email: '',

            from_cost: 0,
            to_cost: 0,
            from_madori: 0,
            to_madori: 0,
            area: 0,
            requests: 0,
            contract_form: false,
            other_contract_form: false,
            student: false,
            other_student: false,
            end_response: 0,
            gakusei_yoyaku: false,
            jyukenyotei: "",
            gouhi: 0,

            modalcalender: false,

            check_end_response:false,
            check_progress_response:true,
            check_application:false,

            condition_radio:0,
            condition:"",

            last_response_date_start: moment("2021/01/01").format("YYYY-MM-DD"),

        }

        this.props.change_formData(formData);
        this.props.responseList()

        // this.props.change_formData(formData);
        // this.props.search_response_list2()
        this.setState({ reflash: !this.state.reflash });
    }
    render() {


        //moment.locale('ja')
        return (
            <Scrollbars style={{float: "left", border:"1px solid rgb(221, 221, 221)", width: 235, height: (window.innerHeight - 60) }}>
            <div className="searchdiv" style={{ backgroundColor: "White", width: 230, textAlign: "left" }}>


                {/* <Button
                    color="primary"
                    type="button"
                    variant="contained"
                >検索</Button> */}





<SimpleDialog section_id={this.props.formData.section_id} open={this.props.response_follow_sheet_open} change_response_follow_sheet_open={this.props.change_response_follow_sheet_open} />

{/* <LineSendDialog open={this.props.line_send_template_open}　change_line_template_edit_open={this.props.change_line_send_open} /> */}
<Modal isOpen={this.props.line_template_edit_open} size="xl" toggle={()=>this.props.change_line_template_edit_open(false)} fade={false} className="modal-dialog-centered">
    <ModalHeader>
        LINE一斉送信 テンプレート
        <IconButton
            aria-label="close"
            style={{position:'absolute', right:1, top:1}}
            onClick={()=>this.props.change_line_template_edit_open(false)}
            size="large">
            <CloseIcon />
        </IconButton>
    </ModalHeader>  
    <ModalBody>
        <ResponseLineSendComponent />
    </ModalBody>
    {/* <ModalFooter>
    </ModalFooter> */}
</Modal>
<Modal isOpen={this.props.line_send_list_open} size="xxl" toggle={()=>this.props.change_line_send_list_open(false)} fade={false} className="modal-dialog-centered">
    <ModalHeader>
        LINE一斉送信 履歴
        <IconButton
            aria-label="close"
            style={{position:'absolute', right:1, top:1}}
            onClick={()=>this.props.change_line_send_list_open(false)}
            size="large">
            <CloseIcon />
        </IconButton>
    </ModalHeader>  
    <ModalBody>
        <ResponseLineSendListComponent />
    </ModalBody>
    {/* <ModalFooter>
    </ModalFooter> */}
</Modal>


<Modal isOpen={this.state.modalcalender} toggle={this.togglecalender} size="lg" fade={false}>

<ModalHeader toggle={this.togglemail}>カレンダー</ModalHeader>
<ModalBody>
  <ContactsComponent rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} customer_contacts={this.props.intend_list} customer_contacts_calendar={this.props.intend_list_calendar} />

</ModalBody>
</Modal>






                <Card style={{ border: 0, boxShadow: "none" }}>


                <CardContent style={{ padding: 5, borderBottom:"1px solid #dcdcdc" }}>
                
                    

                    <Button

                    style={{ marginLeft: 5, paddingLeft:5, paddingRight:5 }}
                    onClick={() => this.props.change_response_follow_sheet_open(true)}
                    //   this.props.handletoggleaddresponse}
                    variant="contained"
                    size="small"
                    color="primary"
                    >追客状況</Button>

                    {this.props.formData.condition_radio == 2 ? <Button
                        style={{ marginLeft: 5, color: "white", backgroundColor: "#5cb85c", paddingLeft:5, paddingRight:5, width:30 }}
                        //   this.props.handletoggleaddresponse}
                        variant="contained"
                        size="small"
                        >
                        <CSVLink style={{textDecoration:"none", color:"white" }} headers={headers_raiten} filename={"反響リスト.csv"} data={this.props.response_search_list.filter(a=> a.request == 0).map(row => ({...row, 

end_response_date: row.end_response_date ? moment(row.end_response_date).format("YYYY-MM-DD") : "",

no_coming: row.no_coming == 1 ? "あり" : "",
mail_is_tel: row.rent_m_way_id == 2 ? row.mail_is_tel ? "あり"  : "なし"  : "",
raiten_date: row.raiten_date ? moment(row.raiten_date).utc().format("YYYY-MM-DD") : "",
raiten_user_id: row.raiten_user_id ? this.props.users_select.filter(a=>a.value == row.raiten_user_id).length > 0 ? this.props.users_select.filter(a=>a.value == row.raiten_user_id)[0].label : "" : "",
going: row.going == 0 ? "なし" : row.going == 1 ? "あり" : row.going == 2 ? "現地" : row.going == 3 ? "オンライン" : "",
going_section: row.going == 0 ? "" : row.going_section == 0 ? "自店" : row.going_section == 1 ? "他店" : row.going_section == 2 ? "他部署" : row.going_section == 3 ? "タクシー" : row.going_section == 4 ? "セルフ" : "",
going_user_id: row.going == 0 ? "" : row.going_user_id ? this.props.users_select.filter(a=>a.value == row.going_user_id).length > 0 ? this.props.users_select.filter(a=>a.value == row.going_user_id)[0].label : "" : "",
is_application: row.is_application == 1 ? "申込" : row.is_application == 2 ? "未成約" : "",
rent_m_going_reason_id: 
    row.rent_m_going_reason_id == 1 ? "返事待ち" : 
    row.rent_m_going_reason_id == 2 ? "再来予定" : 
    row.rent_m_going_reason_id == 3 ? "時期" : 
    row.rent_m_going_reason_id == 4 ? "他も見たい" : 
    row.rent_m_going_reason_id == 5 ? "初期費用" : 
    row.rent_m_going_reason_id == 6 ? "家賃" : 
    row.rent_m_going_reason_id == 7 ? "相談する" : 
    row.rent_m_going_reason_id == 8 ? "他社に行く" : 
    row.rent_m_going_reason_id == 9 ? "物件が不満" : 
    row.rent_m_going_reason_id == 10 ? "その他" : 
    "",
rent_contract_id: row.rent_contract_id > 0 ? "あり"  : "なし",
online: row.online > 0 ? "あり"  : "なし",
intend_coming: row.intend_coming > 0 ? "予定"  : "完了",
first_response_time: row.first_response_time > 0 ? secToTime(row.first_response_time*60) : 0,


rent_m_school_name: row.rent_m_school_id > 0 ? this.props.rent_m_schools.filter(a=>a.id == row.rent_m_school_id)[0].name : "",
jyukenyotei: row.jyukenyotei ? row.jyukenyotei.split(",").map(value => value == "1" ? "推薦" : value == "2" ? "前期" : value == "3" ? "後期" : value == "4" ? "一般" : "").join(", ") : "",
gakusei_yoyaku: row.gakusei_yoyaku == 1 ? "学生予約" : "",
gakunen: row.gakunen == "1" ? "新入学" : row.gakunen == "2" ? "1回生" : row.gakunen == "3" ? "2回生" : row.gakunen == "4" ? "3回生" : row.gakunen == "5" ? "4回生" : row.gakunen == "6" ? "院生" : "",
gouhi: row.gouhi == 1 ? "推薦合格" : row.gouhi == 2 ? "前期合格" : row.gouhi == 3 ? "後期合格" : row.gouhi == 4 ? "不合格" : row.gouhi == 5 ? "一般合格" : "",




                        }))}>CSV</CSVLink>

                    </Button>
                    :
                    <Button
                        style={{ marginLeft: 5, color: "white", backgroundColor: "#5cb85c", paddingLeft:5, paddingRight:5, width:30 }}
                        //   this.props.handletoggleaddresponse}
                        variant="contained"
                        size="small"
                        >
                        <CSVLink style={{textDecoration:"none", color:"white" }} headers={headers} filename={"反響リスト.csv"} data={this.props.response_search_list.filter(a=> a.request == 0).map(row => ({...row, 

end_response_date: row.end_response_date ? moment(row.end_response_date).format("YYYY-MM-DD") : "",
mail_is_tel: row.rent_m_way_id == 2 ? row.mail_is_tel ? "あり"  : "なし"  : "",
user_id: row.user_id ? this.props.users_select.filter(a=>a.value == row.user_id).length > 0 ? this.props.users_select.filter(a=>a.value == row.user_id)[0].label : "" : "",
rent_contract_id: row.rent_contract_id > 0 ? "あり"  : "なし",
first_response_time: row.first_response_time > 0 ? secToTime(row.first_response_time*60) : 0,


rent_m_school_name: row.rent_m_school_id > 0 ? this.props.rent_m_schools.filter(a=>a.id == row.rent_m_school_id)[0].name : "",
jyukenyotei: row.jyukenyotei ? row.jyukenyotei.split(",").map(value => value == "1" ? "推薦" : value == "2" ? "前期" : value == "3" ? "後期" : value == "4" ? "一般" : "").join(", ") : "",
gakusei_yoyaku: row.gakusei_yoyaku == 1 ? "学生予約" : "",
gakunen: row.gakunen == "1" ? "新入学" : row.gakunen == "2" ? "1回生" : row.gakunen == "3" ? "2回生" : row.gakunen == "4" ? "3回生" : row.gakunen == "5" ? "4回生" : row.gakunen == "6" ? "院生" : "",
gouhi: row.gouhi == 1 ? "推薦合格" : row.gouhi == 2 ? "前期合格" : row.gouhi == 3 ? "後期合格" : row.gouhi == 4 ? "不合格" : row.gouhi == 5 ? "一般合格" : "",

                        }))}>CSV</CSVLink>

                    </Button>
                    }

                    {/* <Button

                    style={{ marginRight: 5, float:'right', paddingLeft:5, paddingRight:5 }}
                    onClick={this.togglecalender.bind(this)}
                    variant="contained"
                    
                    size="small"
                    >追客カレンダー</Button> */}


                    {/* <button className="btn btn-sm btn-primary float-right"  size="small" style={{marginRight:5}} type="button" onClick={this.togglecalender.bind(this)}>追客カレンダー</button> */}
                        
                    <div className="tooltip3" style={{float:"right", marginRight:5}} >
                        <p><Chip label="色" variant="outlined" /></p>
                        <div className="description3 responselist">
                            <div className='irosetumei pink'>新規反響</div>
                            <div className='irosetumei green'>新規LINE</div>
                            <div className='irosetumei lightblue'>新規Web来店カード</div>
                            <div className='irosetumei yellow'>メール受信</div>
                            <div className='irosetumei yellowgreen'>LINE受信</div>
                            <div className='irosetumei purple'>来店予約</div>
                            <div className='irosetumei orange'>お気に入り登録</div>
                            <div className='irosetumei blue'>条件設定</div>
                            <div className='irosetumei red'>問合せ(マイページ)</div>
                        </div>
                    </div>
                </CardContent>
                {/* <CardContent style={{ padding: 5,  }}>
                <Button
                    style={{ float:'right', marginRight:5, marginLeft: 5, color: "white", backgroundColor: "#5cb85c", paddingLeft:5, paddingRight:5, width:30 }}
                    //   this.props.handletoggleaddresponse}
                    variant="contained"
                    size="small"
                    >
                        <CSVLink style={{textDecoration:"none", color:"white" }} headers={headers} filename={"反響リスト.csv"} data={this.props.response_search_list}>CSV</CSVLink>

                    </Button>
                    <div style={{clear: "both"}}></div>
                </CardContent> */}
                <CardContent style={{ padding: 5 }}>
                    <Chip label={this.props.response_count + "件"} style={{ margin: 5, fontSize: 14, }} variant="outlined" />
                    
                <button
                        type="button"
                        onClick={this.props.responseList}
                        className="btn btn-primary"
                    >更新</button>

                <Button
                    color="secondary"
                    onClick={this.open_response_add_set}
                    //   this.props.handletoggleaddresponse}
                    variant="contained"
                    style={{marginRight:5, marginTop:5, float:"right"}}
                >追加</Button>


                </CardContent>

                <CardContent style={{ padding: 5 }}>

                
                {this.props.form_count > 0 ?<Chip label={this.props.form_count + "件指定中"} style={{ marginLeft: 10, fontSize: 14 }} variant="outlined" color="secondary" /> : ""}

                    <Button

style={{ float: "right", marginLeft: 10, marginRight: 5 }}
onClick={this.onClear}
//   this.props.handletoggleaddresponse}
variant="contained"
>条件クリア</Button>
                <div style={{clear: "both"}}></div>
                </CardContent>

                
                
                

               

                <CardContent style={{ padding: 5,  }}>
                    <FormControl size="small" variant="standard" style={{ marginLeft: "10px", width: "100px" }}>
                        <InputLabel id="demo-simple-select-label">並び順</InputLabel>
                        <MaterialSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            defaultValue={1}
                            name="sort"
                            // value={age}
                            value={this.props.formData.sort}
                            onChange={this.handleChange}
                        >
                            <MenuItem value={4}>最終閲覧</MenuItem>
                            <MenuItem value={1}>最新対応順</MenuItem>
                            <MenuItem value={2}>反響日順</MenuItem>
                            <MenuItem value={3}>ランク順</MenuItem>
                        </MaterialSelect>
                    </FormControl>

                    <FormControl size="small" variant="standard" style={{ marginLeft: "10px", width: "100px" }}>
                        <InputLabel id="demo-simple-select-label">予定</InputLabel>
                        <MaterialSelect
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={this.props.formData.intend}
                            name="intend"
                            onChange={this.handleChange}
                        >
                            <MenuItem value={0}>-</MenuItem>
                            <MenuItem value={1}>来店予定</MenuItem>
                            <MenuItem value={2}>追客予定</MenuItem>
                        </MaterialSelect>
                    </FormControl>
                </CardContent>



                    <CardContent style={{ padding: 5 }}>
                        <RadioGroup aria-label="状況" name="condition_radio" value={Number(this.props.formData.condition_radio)} onChange={this.handleChange} row>
                            <Col xs="6" style={{paddingRight:0, paddingLeft:0}}><FormControlLabel value={0} style={{marginRight:0}} control={<Radio style={{paddingTop:0, paddingBottom:0}} />} label="反響" /></Col>
                            <Col xs="6" style={{paddingRight:0, paddingLeft:0}}><FormControlLabel value={2} style={{marginRight:0}} control={<Radio style={{paddingTop:0, paddingBottom:0}} />} label="来店" /></Col>
                            {/* <Col xs="4" style={{paddingRight:0, paddingLeft:0}}><FormControlLabel value={1} style={{marginRight:0}} control={<Radio style={{paddingTop:0, paddingBottom:0}} />} label="契約" /></Col> */}
                        </RadioGroup>
                    </CardContent>

                    {Number(this.props.formData.condition_radio) == 0 ? <CardContent style={{ paddingTop: 0,paddingBottom: 5,paddingLeft: 5,paddingRight: 5 }}>
                        <Col lg="12" style={{paddingRight:0}}><FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.formData.condition ? this.props.formData.condition.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="condition" />} label="来店なし" /></Col>
                        <Col lg="12" style={{paddingRight:0}}><FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.formData.condition ? this.props.formData.condition.split(',').includes("6") : false} value={6} onChange={this.handleChange} name="condition" />} label="自動追客" /></Col>
                    </CardContent>:""}

                    {Number(this.props.formData.condition_radio) == 2 ? <CardContent style={{ paddingTop: 0,paddingBottom: 5,paddingLeft: 5,paddingRight: 5 }}>
                        <Col lg="12" style={{paddingRight:0}}><FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.formData.condition ? this.props.formData.condition.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="condition" />} label="初回来店" /></Col>
                        <Col lg="12" style={{paddingRight:0}}><FormControlLabel control={<Checkbox style={{paddingTop:0,paddingBottom:0,}} checked={this.props.formData.condition ? this.props.formData.condition.split(',').includes("3") : false} value={3} onChange={this.handleChange} name="condition" />} label="再来" /></Col>
                        {/* <Row>
                            <RadioGroup aria-label="反響状況" name="condition" value={Number(this.props.formData.condition)} onChange={this.handleChange} row>
                                <Col lg="6" style={{paddingRight:0}}><FormControlLabel value={2} style={{marginRight:0}} control={<Radio style={{paddingTop:0, paddingBottom:0}} />} label="初回来店" /></Col>
                                <Col lg="6" style={{paddingRight:0}}><FormControlLabel value={3} style={{marginRight:0}} control={<Radio style={{paddingTop:0, paddingBottom:0}} />} label="再来" /></Col>
                            </RadioGroup>
                        </Row> */}
                        
                    </CardContent>:""}

                    {Number(this.props.formData.condition_radio) == 0 || Number(this.props.formData.condition_radio) == 2 ? <CardContent style={{ padding: 5}}>
                    
                        <FormControlLabel style={{marginBottom:0}} control={<Checkbox checked={this.props.formData.check_progress_response} name="check_progress_response" onChange={this.handleChange} />} label="追客中" />
                        <FormControlLabel style={{marginBottom:0}} control={<Checkbox checked={this.props.formData.check_end_response} name="check_end_response" onChange={this.handleChange} />} label="追客終了" />
                        <FormControlLabel style={{marginBottom:0, marginTop:-12}} control={<Checkbox checked={this.props.formData.check_application} name="check_application" onChange={this.handleChange} />} label="申込" />
                    </CardContent>:""}

                    <CardContent style={{ paddingLeft: 5, paddingTop:0, paddingBottom:0 }}>
                        <FormControlLabel control={<Checkbox style={{paddingTop:0, paddingBottom:0}} checked={this.props.formData.line_img_url == 1 ? true : false} name="line_img_url" onChange={this.handleChange} />} label="LINE登録あり" />
                        {this.props.formData.line_img_url == 1 ? <FormControlLabel control={<Checkbox style={{paddingTop:0, paddingBottom:0}} checked={this.props.formData.unfollowed == 1 ? true : false} name="unfollowed" onChange={this.handleChange} />} label="LINEブロック" /> : ""}
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>ランク</InputLabel>
                            <MaterialUiSelect
                                id="rank"
                                name="rank"
                                value={this.props.formData.rank}
                                multiple
                                onChange={this.handleChange}
                                renderValue={()=>this.props.formData.rank.length > 0 ? this.props.formData.rank.map(function (item) {
                                    return parseInt(item) >= 0 ? rank_list.filter(a => a.id === parseInt(item))[0].value : ""
                                }, this).join(',') : ""}
                                // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={0}>-</MenuItem> */}
                                <MenuItem value={0}><Checkbox checked={this.props.formData.rank.indexOf(0) > -1} /><ListItemText primary={"未設定"} /></MenuItem>
                                <MenuItem value={1}><Checkbox checked={this.props.formData.rank.indexOf(1) > -1} /><ListItemText primary={"S"} /></MenuItem>
                                <MenuItem value={2}><Checkbox checked={this.props.formData.rank.indexOf(2) > -1} /><ListItemText primary={"A"} /></MenuItem>
                                <MenuItem value={3}><Checkbox checked={this.props.formData.rank.indexOf(3) > -1} /><ListItemText primary={"B"} /></MenuItem>
                                <MenuItem value={4}><Checkbox checked={this.props.formData.rank.indexOf(4) > -1} /><ListItemText primary={"C"} /></MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <TextField fullWidth
                            id="name"
                            label="お客様名"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="name"
                            value={this.props.formData.name}
                            size="small"
                        />
                    </CardContent>


                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel id="sectionId-label">店舗</InputLabel>
                            <MaterialUiSelect
                                labelId="sectionId-label"
                                id="section_id"
                                name="section_id"
                                value={this.props.formData.section_id}
                                onChange={this.handleChange}
                                // disabled={this.props.formData.id}
                                label="section_id"
                            >
                                <MenuItem key={-1} value={0}>全店</MenuItem>
                                {/* {this.props.section_select.filter(a => a.value > 0 && a.value <=6 || a.value == 10 || a.value == 16 || a.value == 17 || a.value == 26 || a.value == 37 || a.value == 47).map(function (item, i) { */}
                                {this.props.shops.sort((a, b) => {return a.dispOrder < b.dispOrder ? -1 : 1;}).filter(a => a.tenpoNo > 0 && a.tenpoNo <=6 || a.tenpoNo == 10 || a.tenpoNo == 16 || a.tenpoNo == 17 || a.tenpoNo == 26 || a.tenpoNo == 37 || a.tenpoNo == 47 || a.tenpoNo == 55 || a.tenpoNo == 56 || a.tenpoNo == 36).map(function (item, i) {
                                return (
                                    <MenuItem key={i} value={item.tenpoNo}>{item.tenpoName}</MenuItem>
                                );

                                })}

                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>



                    <CardContent style={{ padding: 5 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            options={this.props.users_select.length === 0 && this.props.formData.response_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.response_user_id)[0]]
                                : this.props.users_select}
                            getOptionLabel={(option) => option.label ? option.label : ''}

                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}

                            value={this.props.formData.response_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.response_user_id)[0] : {}}

                            onChange={this.change_response_user_id}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="response_user_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                // onChange={this.change_response_user_id}
                                label="反響担当" />}

                        />
                    </CardContent>
                    <CardContent style={{ padding: 5, display:"none" }}>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            options={this.props.users_select.length === 0 && this.props.formData.primary_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.primary_user_id)[0]]
                                : this.props.users_select}
                            getOptionLabel={(option) => option.label ? option.label : ''}

                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}

                            value={this.props.formData.primary_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.primary_user_id)[0] : {}}

                            onChange={this.change_primary_user_id}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="primary_user_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                // onChange={this.change_primary_user_id}
                                label="追客担当" />}

                        />
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            options={this.props.users_select.length === 0 && this.props.formData.coming_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.coming_user_id)[0]]
                                : this.props.users_select}
                            getOptionLabel={(option) => option.label ? option.label : ''}

                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}

                            value={this.props.formData.coming_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.coming_user_id)[0] : {}}

                            onChange={this.change_coming_user_id}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="coming_user_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                // onChange={this.change_coming_user_id}
                                label="来店担当" />}

                        />
                    </CardContent>

                    <CardContent style={{ padding: 5, display:"none" }}>
                        <Autocomplete
                        id="combo-box-demo"
                        size="small"
                        options={this.props.users_select.length === 0 && this.props.formData.web_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.web_user_id)[0]]
                            : this.props.users_select}
                        getOptionLabel={(option) => option.label ? option.label : ''}

                        style={{ width: "100%" }}
                        name="userId"
                        loading={this.props.users_loading}

                        value={this.props.formData.web_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.web_user_id)[0] : {}}

                        onChange={this.change_web_user_id}
                        renderInput={(params) => <TextField
                            {...params}
                            variant="outlined"
                            name="web_user_id"

                            InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>

                                {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                            }}
                            // onChange={this.change_web_user_id}
                            label="Web課追客担当" />}

                        />
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            options={this.props.users_select.length === 0 && this.props.formData.going_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.going_user_id)[0]]
                                : this.props.users_select}
                            getOptionLabel={(option) => option.label ? option.label : ''}

                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}

                            value={this.props.formData.going_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.going_user_id)[0] : {}}

                            onChange={this.change_going_user_id}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="going_user_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                label="案内担当" />}

                        />
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            size="small"
                            options={this.props.users_select.length === 0 && this.props.formData.contract_user_id ? [this.props.users_select.filter(a => a.value === this.props.formData.contract_user_id)[0]]
                                : this.props.users_select}
                            getOptionLabel={(option) => option.label ? option.label : ''}

                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}

                            value={this.props.formData.contract_user_id ? this.props.users_select.filter(a => a.value === this.props.formData.contract_user_id)[0] : {}}

                            onChange={this.change_contract_user_id}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="contract_user_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>

                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                // onChange={this.change_coming_user_id}
                                label="契約担当" />}

                        />
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <TextField fullWidth
                            id="building_name"
                            label="建物名"
                            variant="outlined"
                            value={this.props.formData.building_name}
                            onChange={this.handleChange}
                            name="building_name"
                            size="small"
                        />
                    </CardContent>
                    {/* <CardContent style={{ padding: 5 }}>
                        <TextField fullWidth
                            id="parking_name"
                            label="駐車場名"
                            variant="outlined"
                            onChange={this.handleChange}
                            name="parking_name"
                        />
                    </CardContent> */}
                    {/* <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel id="contractUserId-label">売上担当</InputLabel>
                            <MaterialUiSelect
                                labelId="contractUserId-label"
                                id="contractUserId"
                                name="contractUserId"
                                // value={this.props.rent_responses_editor.section_id}
                                onChange={this.handleChange}
                                label="contractUserId"
                            >
                                {this.props.users_select.map(function (item, i) {
                                    return (
                                        <MenuItem key={i} value={item.value}>{item.label}</MenuItem>
                                    );

                                })}
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent> */}

                    <CardContent style={{ padding: 5 }}>
                        <TextField
                            id="response_date_start"
                            label="反響日"
                            type="date"
                            name="response_date_start"
                            onChange={this.handleChange}
                            value={this.props.formData.response_date_start}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />～
                        <TextField
                            id="response_date_end"
                            label="反響日"
                            type="date"
                            name="response_date_end"
                            value={this.props.formData.response_date_end}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>
                    {this.props.formData.condition_radio == 2 ? <CardContent style={{ padding: 5 }}>
                        <TextField
                            id="coming_date_start"
                            label="来店日"
                            type="date"
                            name="coming_date_start"
                            onChange={this.handleChange}
                            value={this.props.formData.coming_date_start}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />～
                        <TextField
                            id="coming_date_end"
                            label="来店日"
                            type="date"
                            name="coming_date_end"
                            value={this.props.formData.coming_date_end}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>:""}
                    {this.props.formData.check_application ? <CardContent style={{ padding: 5 }}>
                        <TextField
                            id="application_date_start"
                            label="申込日"
                            type="date"
                            name="application_date_start"
                            onChange={this.handleChange}
                            value={this.props.formData.application_date_start}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />～
                        <TextField
                            id="application_date_end"
                            label="申込日"
                            type="date"
                            name="application_date_end"
                            value={this.props.formData.application_date_end}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>:""}
                    {this.props.formData.condition_radio == 1 ? <CardContent style={{ padding: 5 }}>
                        <TextField
                            id="contract_date_start"
                            label="契約日"
                            type="date"
                            name="contract_date_start"
                            onChange={this.handleChange}
                            value={this.props.formData.contract_date_start}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />～
                        <TextField
                            id="contract_date_end"
                            label="契約日"
                            type="date"
                            name="contract_date_end"
                            value={this.props.formData.contract_date_end}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            variant="outlined"
                            size="small"
                        />
                    </CardContent>:""}
                    {/* <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth>
                            <InputLabel>対応状況</InputLabel>
                            <MaterialUiSelect
                                id="response_condition"
                                name="response_condition"
                                onChange={this.handleChange}
                            >
                                <MenuItem value={0}>すべて</MenuItem>
                                <MenuItem value={1}>反響のみ</MenuItem>
                                <MenuItem value={2}>来店あり</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent> */}
                    <CardContent style={{ padding: 5 }}>
                        <TextField fullWidth
                            id="tel1"
                            label="電話番号"
                            variant="outlined"
                            value={this.props.formData.tel1}
                            onChange={this.handleChange}
                            name="tel1"
                            size="small"
                        />
                    </CardContent>

                    {/* <CardContent style={{ padding: 5 }}>
                        <TextField
                            id="responseDate1"
                            label="引越期限"
                            type="date"
                            name="responseDate1"
                            // onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                        />～
                        <TextField
                            id="responseDate2"
                            label="引越期限"
                            type="date"
                            name="responseDate2"
                            // onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                        />
                    </CardContent> */}
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" size="small" fullWidth style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>引越時期</InputLabel>
                            <MaterialUiSelect
                                id="moving_time"
                                name="moving_time"
                                value={this.props.formData.moving_time}
                                multiple
                                onChange={this.handleChange}
                                renderValue={()=>this.props.formData.moving_time.length > 0 ? this.props.formData.moving_time.map(function (item) {
                                    return parseInt(item) > 0 ? moving_list.filter(a => a.id === parseInt(item))[0].value : ""
                                }, this).join(',') : ""}
                                // MenuProps={MenuProps}
                            >
                                {/* <MenuItem value={0}>-</MenuItem> */}
                                <MenuItem value={1}><Checkbox checked={this.props.formData.moving_time.indexOf(1) > -1} /><ListItemText primary={"今月中"} /></MenuItem>
                                <MenuItem value={2}><Checkbox checked={this.props.formData.moving_time.indexOf(2) > -1} /><ListItemText primary={"来月中"} /></MenuItem>
                                <MenuItem value={3}><Checkbox checked={this.props.formData.moving_time.indexOf(3) > -1} /><ListItemText primary={"2ヶ月後"} /></MenuItem>
                                <MenuItem value={4}><Checkbox checked={this.props.formData.moving_time.indexOf(4) > -1} /><ListItemText primary={"3ヶ月後"} /></MenuItem>
                                <MenuItem value={5}><Checkbox checked={this.props.formData.moving_time.indexOf(5) > -1} /><ListItemText primary={"4ヶ月後"} /></MenuItem>
                                <MenuItem value={6}><Checkbox checked={this.props.formData.moving_time.indexOf(6) > -1} /><ListItemText primary={"5ヶ月後"} /></MenuItem>
                                <MenuItem value={7}><Checkbox checked={this.props.formData.moving_time.indexOf(7) > -1} /><ListItemText primary={"半年後"} /></MenuItem>
                                <MenuItem value={8}><Checkbox checked={this.props.formData.moving_time.indexOf(8) > -1} /><ListItemText primary={"1年以内"} /></MenuItem>
                                <MenuItem value={9}><Checkbox checked={this.props.formData.moving_time.indexOf(9) > -1} /><ListItemText primary={"未定いいのがあれば"} /></MenuItem>
                                {/* <MenuItem value={1}>今月中</MenuItem>
                                <MenuItem value={2}>来月中</MenuItem>
                                <MenuItem value={3}>2ヶ月後</MenuItem>
                                <MenuItem value={4}>3ヶ月後</MenuItem>
                                <MenuItem value={5}>4ヶ月後</MenuItem>
                                <MenuItem value={6}>5ヶ月後</MenuItem>
                                <MenuItem value={7}>半年後</MenuItem>
                                <MenuItem value={8}>1年以内 </MenuItem>
                                <MenuItem value={9}>未定いいのがあれば </MenuItem> */}
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" size="small" fullWidth style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>反響経路</InputLabel>
                            <MaterialUiSelect
                                id="way"
                                name="way"
                                value={this.props.formData.way}
                                onChange={this.handleChange}
                                multiple
                                renderValue={()=>this.props.formData.way.length > 0 ? this.props.formData.way.map(function (item) {
                                    return parseInt(item) > 0 ? way_list.filter(a => a.id === parseInt(item))[0].value : ""
                                }, this).join(',') : ""}
                            >
                                {/* <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={1}>TEL</MenuItem>
                                <MenuItem value={2}>メール</MenuItem>
                                <MenuItem value={6}>LINE</MenuItem>
                                <MenuItem value={7}>SNS</MenuItem>
                                <MenuItem value={3}>社宅斡旋</MenuItem>
                                <MenuItem value={5}>飛込</MenuItem> */}
                                <MenuItem value={1}><Checkbox checked={this.props.formData.way.indexOf(1) > -1} /><ListItemText primary={"TEL"} /></MenuItem>
                                <MenuItem value={2}><Checkbox checked={this.props.formData.way.indexOf(2) > -1} /><ListItemText primary={"メール"} /></MenuItem>
                                <MenuItem value={6}><Checkbox checked={this.props.formData.way.indexOf(6) > -1} /><ListItemText primary={"LINE"} /></MenuItem>
                                <MenuItem value={7}><Checkbox checked={this.props.formData.way.indexOf(7) > -1} /><ListItemText primary={"SNS"} /></MenuItem>
                                <MenuItem value={3}><Checkbox checked={this.props.formData.way.indexOf(3) > -1} /><ListItemText primary={"社宅斡旋"} /></MenuItem>
                                <MenuItem value={5}><Checkbox checked={this.props.formData.way.indexOf(5) > -1} /><ListItemText primary={"飛込"} /></MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" size="small" fullWidth style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>反響媒体</InputLabel>
                            <MaterialUiSelect
                                id="media"
                                name="media"
                                value={this.props.formData.media}
                                onChange={this.handleChange}
                                multiple
                                renderValue={()=>this.props.formData.media.length > 0 ? this.props.formData.media.map(function (item) {
                                    return parseInt(item) > 0 ? this.props.rentMMedeas.filter(a => a.id === parseInt(item))[0].name : ""
                                }, this).join(',') : ""}
                            >
                                {/* <MenuItem value={0}>-</MenuItem> */}
                                {this.props.rentMMedeas.map(function (item, i) {
                                    return (
                                        // <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        <MenuItem value={item.id}><Checkbox checked={this.props.formData.media.indexOf(item.id) > -1} /><ListItemText primary={item.name} /></MenuItem>
                                    );

                                },this)}
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>




                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>代行会社</InputLabel>
                            <MaterialUiSelect
                                id="agent"
                                name="agent"
                                value={this.props.formData.agent}
                                onChange={this.handleChange}
                                multiple
                                renderValue={()=>this.props.formData.agent.length > 0 ? this.props.formData.agent.map(function (item) {
                                    return parseInt(item) > 0 ? this.props.rent_m_agents.filter(a => a.id === parseInt(item))[0].name : ""
                                }, this).join(',') : ""}
                            >
                                {/* <MenuItem value={0}>-</MenuItem> */}
                                {this.props.rent_m_agents.map(function (item, i) {
                                    return (
                                        // <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        <MenuItem value={item.id}><Checkbox checked={this.props.formData.agent.indexOf(item.id) > -1} /><ListItemText primary={item.name} /></MenuItem>
                                    );

                                },this)}
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    {/* <CardContent style={{ padding: 5 }}>
                        <Autocomplete
                            id="combo-box-demo"
                            options={this.props.rent_m_agents.length === 0 && this.props.formData.agent ? [{ value: this.props.rent_m_agents.id, label: this.props.rent_m_agents.name }]
                                : this.props.rent_m_agents.map((value) => ({
                                    id: value.id,
                                    name: value.name ? value.name : ""
                                }))}
                            getOptionLabel={(option) => option.name ? option.name : ''}
                            style={{ width: "100%" }}
                            name="userId"
                            loading={this.props.users_loading}
                            // value={this.props.formData.agent > 0 ? this.props.rent_m_agents.filter(a => a.id === 1)[0] : {id:0, name:''}}
                            // value={this.props.rent_m_agents.filter(a => a.id === this.props.formData.agent)[0]}
                            value={this.props.formData.agent > 0 ? this.props.rent_m_agents.filter(a => a.id == this.props.formData.agent)[0] : {}}

                            onChange={this.handle_agent_change}
                            renderInput={(params) => <TextField
                                {...params}
                                variant="outlined"
                                name="rent_m_agent_id"

                                InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                        <React.Fragment>
                                            {this.props.rent_m_corporates_loading ? <CircularProgress color="inherit" size={20} /> : null}
                                            {params.InputProps.endAdornment}
                                        </React.Fragment>
                                    ),
                                }}
                                label="代行会社" />}
                        />
                    </CardContent> */}


                    <CardContent style={{ padding: 5 }}>
                        <TextField fullWidth
                            id="email"
                            label="メール"
                            variant="outlined"
                            value={this.props.formData.email}
                            onChange={this.handleChange}
                            name="email"
                            size="small"
                        />
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" margin="dense" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>希望金額</InputLabel>
                            <MaterialUiSelect
                                id="from_cost"
                                name="from_cost"
                                value={this.props.formData.from_cost}
                                onChange={this.handleChange}
                                style={{ width: 100 }}
                                size="small"
                                label="希望金額"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={20000}>20,000円</MenuItem>
                                <MenuItem value={25000}>25,000円</MenuItem>
                                <MenuItem value={30000}>30,000円</MenuItem>
                                <MenuItem value={35000}>35,000円</MenuItem>
                                <MenuItem value={40000}>40,000円</MenuItem>
                                <MenuItem value={45000}>45,000円</MenuItem>
                                <MenuItem value={50000}>50,000円</MenuItem>
                                <MenuItem value={55000}>55,000円</MenuItem>
                                <MenuItem value={60000}>60,000円</MenuItem>
                                <MenuItem value={65000}>65,000円</MenuItem>
                                <MenuItem value={70000}>70,000円</MenuItem>
                                <MenuItem value={75000}>75,000円</MenuItem>
                                <MenuItem value={80000}>80,000円</MenuItem>
                                <MenuItem value={85000}>85,000円</MenuItem>
                                <MenuItem value={90000}>90,000円</MenuItem>
                                <MenuItem value={95000}>95,000円</MenuItem>
                                <MenuItem value={100000}>100,000円</MenuItem>
                                <MenuItem value={105000}>105,000円</MenuItem>
                                <MenuItem value={110000}>110,000円</MenuItem>
                                <MenuItem value={120000}>120,000円</MenuItem>
                                <MenuItem value={130000}>130,000円</MenuItem>
                                <MenuItem value={140000}>140,000円</MenuItem>
                                <MenuItem value={150000}>150,000円以上</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>～
                        <FormControl variant="outlined" margin="dense" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>希望金額</InputLabel>
                            <MaterialUiSelect
                                id="to_cost"
                                name="to_cost"
                                value={this.props.formData.to_cost}
                                onChange={this.handleChange}
                                style={{ width: 100 }}
                                size="small"
                                label="希望金額"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={20000}>20,000円</MenuItem>
                                <MenuItem value={25000}>25,000円</MenuItem>
                                <MenuItem value={30000}>30,000円</MenuItem>
                                <MenuItem value={35000}>35,000円</MenuItem>
                                <MenuItem value={40000}>40,000円</MenuItem>
                                <MenuItem value={45000}>45,000円</MenuItem>
                                <MenuItem value={50000}>50,000円</MenuItem>
                                <MenuItem value={55000}>55,000円</MenuItem>
                                <MenuItem value={60000}>60,000円</MenuItem>
                                <MenuItem value={65000}>65,000円</MenuItem>
                                <MenuItem value={70000}>70,000円</MenuItem>
                                <MenuItem value={75000}>75,000円</MenuItem>
                                <MenuItem value={80000}>80,000円</MenuItem>
                                <MenuItem value={85000}>85,000円</MenuItem>
                                <MenuItem value={90000}>90,000円</MenuItem>
                                <MenuItem value={95000}>95,000円</MenuItem>
                                <MenuItem value={100000}>100,000円</MenuItem>
                                <MenuItem value={105000}>105,000円</MenuItem>
                                <MenuItem value={110000}>110,000円</MenuItem>
                                <MenuItem value={120000}>120,000円</MenuItem>
                                <MenuItem value={130000}>130,000円</MenuItem>
                                <MenuItem value={140000}>140,000円</MenuItem>
                                <MenuItem value={150000}>150,000円以上</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>間取り</InputLabel>
                            <MaterialUiSelect
                                id="from_madori"
                                name="from_madori"
                                value={this.props.formData.from_madori}
                                onChange={this.handleChange}
                                style={{ width: 100 }}
                                label="間取り"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={2}>1K</MenuItem>
                                <MenuItem value={3}>1DK</MenuItem>
                                <MenuItem value={5}>1LDK</MenuItem>
                                <MenuItem value={8}>2R</MenuItem>
                                <MenuItem value={9}>2DK</MenuItem>
                                <MenuItem value={11}>2LDK</MenuItem>
                                <MenuItem value={15}>3DK</MenuItem>
                                <MenuItem value={17}>3LDK</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>～
                        <FormControl variant="outlined" size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>間取り</InputLabel>
                            <MaterialUiSelect
                                id="to_madori"
                                name="to_madori"
                                value={this.props.formData.to_madori}
                                onChange={this.handleChange}
                                style={{ width: 100 }}
                                label="間取り"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={2}>1K</MenuItem>
                                <MenuItem value={3}>1DK</MenuItem>
                                <MenuItem value={5}>1LDK</MenuItem>
                                <MenuItem value={8}>2R</MenuItem>
                                <MenuItem value={9}>2DK</MenuItem>
                                <MenuItem value={11}>2LDK</MenuItem>
                                <MenuItem value={15}>3DK</MenuItem>
                                <MenuItem value={17}>3LDK</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth margin="dense" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>地域</InputLabel>
                            <MaterialUiSelect
                                id="area"
                                name="area"
                                value={this.props.formData.area}
                                onChange={this.handleChange}
                                size="small"
                                label="地域"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={1}>高松市中心部</MenuItem>
                                <MenuItem value={2}>レインボー・サンフラワー周辺</MenuItem>
                                <MenuItem value={3}>高松市南部</MenuItem>
                                <MenuItem value={4}>高松市東部</MenuItem>
                                <MenuItem value={5}>高松市西部</MenuItem>
                                <MenuItem value={6}>香川町　三木町</MenuItem>
                                <MenuItem value={7}>庵治町・牟礼町</MenuItem>
                                <MenuItem value={8}>高松市全域</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth margin="dense" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>こだわり条件</InputLabel>
                            <MaterialUiSelect
                                id="requests"
                                name="requests"
                                value={this.props.formData.requests}
                                onChange={this.handleChange}
                                size="small"
                                label="こだわり条件"
                            >
                                <MenuItem value={0}>-</MenuItem>

                                <MenuItem value={24}>ガスコンロ1口付</MenuItem>
                                <MenuItem value={23}>ガスコンロ2口付</MenuItem>
                                <MenuItem value={22}>ガスコンロ3口付</MenuItem>
                                <MenuItem value={95}>ガスコンロ口数不明</MenuItem>

                                <MenuItem value={29}>IHヒーター1口付</MenuItem>
                                <MenuItem value={30}>IHヒーター2口付</MenuItem>
                                <MenuItem value={59}>IHヒーター3口付</MenuItem>
                                <MenuItem value={92}>IHヒーター口数不明</MenuItem>

                                <MenuItem value={91}>対面キッチン</MenuItem>
                                <MenuItem value={21}>システムキッチン</MenuItem>
                                <MenuItem value={58}>エアコン1台付</MenuItem>
                                <MenuItem value={7}>エアコン2台付</MenuItem>
                                <MenuItem value={8}>エアコン3台付</MenuItem>
                                <MenuItem value={119}>エアコン4台付</MenuItem>
                                <MenuItem value={151}>エアコン5台付</MenuItem>
                                <MenuItem value={121}>エアコン6台付</MenuItem>
                                <MenuItem value={115}>エアコン台数不明</MenuItem>
                                <MenuItem value={3}>追い炊き</MenuItem>
                                <MenuItem value={4}>シャンプードレッサー</MenuItem>
                                <MenuItem value={196}>脱衣所</MenuItem>
                                <MenuItem value={20}>バストイレ別</MenuItem>
                                <MenuItem value={1}>温水洗浄便座</MenuItem>
                                <MenuItem value={16}>ネット使い放題</MenuItem>
                                <MenuItem value={9}>CATV</MenuItem>
                                <MenuItem value={12}>BS</MenuItem>
                                <MenuItem value={69}>室内洗濯機置き場</MenuItem>
                                <MenuItem value={41}>TVモニターホン</MenuItem>
                                <MenuItem value={37}>オートロック</MenuItem>
                                <MenuItem value={153}>2F以上</MenuItem>
                                <MenuItem value={99}>ウォークインクローゼット</MenuItem>
                                <MenuItem value={448}>家具・家電付き</MenuItem>
                                <MenuItem value={17}>P2台</MenuItem>
                                <MenuItem value={104}>バイク置き場</MenuItem>
                                <MenuItem value={45}>ペット相談可</MenuItem>
                                <MenuItem value={147}>猫相談可</MenuItem>

                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControlLabel control={<Checkbox margin="dense" checked={this.props.formData.gakusei_yoyaku == 1 ? true : false} name="gakusei_yoyaku" onChange={this.handleChange} />} label="学生予約" />
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControlLabel control={<Checkbox margin="dense" checked={this.props.formData.student == 1 ? true : false} name="student" onChange={this.handleChange} />} label="学生のみ" />
                        <FormControlLabel control={<Checkbox margin="dense" checked={this.props.formData.other_student == 1 ? true : false} name="other_student" onChange={this.handleChange} />} label="学生以外" />
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControlLabel control={<Checkbox margin="dense" checked={this.props.formData.contract_form == 1 ? true : false} name="contract_form" onChange={this.handleChange} />} label="法人のみ" />
                        <FormControlLabel control={<Checkbox margin="dense" checked={this.props.formData.other_contract_form == 1 ? true : false} name="other_contract_form" onChange={this.handleChange} />} label="法人以外" />
                    </CardContent>


                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel id="rent_m_school_id-label">学校名</InputLabel>
                            <MaterialUiSelect
                                id="rent_m_school_id"
                                name="rent_m_school_id"
                                value={this.props.formData.rent_m_school_id}
                                onChange={this.handleChange}
                                multiple
                                renderValue={()=>this.props.formData.rent_m_school_id.length > 0 ? this.props.formData.rent_m_school_id.map(function (item) {
                                    return parseInt(item) > 0 ? this.props.rent_m_schools.filter(a => a.id === parseInt(item))[0].name : ""
                                }, this).join(',') : ""}
                            >
                                {this.props.rent_m_schools.map(function (item, i) {
                                    return (
                                        <MenuItem value={item.id}><Checkbox checked={this.props.formData.rent_m_school_id.indexOf(item.id) > -1} /><ListItemText primary={item.name} /></MenuItem>
                                    );

                                },this)}
                            </MaterialUiSelect>

                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel id="gakunen-label">学年</InputLabel>
                            <MaterialUiSelect
                                labelId="gakunen-label"
                                id="gakunen"
                                name="gakunen"
                                value={this.props.formData.gakunen}
                                onChange={this.handleChange}
                                label="gakunen"
                            >
                                    <MenuItem value={0}>-</MenuItem>
                                    <MenuItem value={1}>新入学</MenuItem>
                                    <MenuItem value={2}>1回生</MenuItem>
                                    <MenuItem value={3}>2回生</MenuItem>
                                    <MenuItem value={4}>3回生</MenuItem>
                                    <MenuItem value={5}>4回生</MenuItem>
                                    <MenuItem value={6}>院生</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>

                    <CardContent style={{ padding: 5 }}>
                        <FormLabel component="legend" style={{ marginBottom: 0, fontSize: 10 }}>受験予定</FormLabel>
                        <FormControlLabel control={<Checkbox checked={this.props.formData.jyukenyotei ? this.props.formData.jyukenyotei.split(',').includes("1") : false} value={1} onChange={this.handleChange} name="jyukenyotei" />} label="推薦" />
                        <FormControlLabel control={<Checkbox checked={this.props.formData.jyukenyotei ? this.props.formData.jyukenyotei.split(',').includes("2") : false} value={2} onChange={this.handleChange} name="jyukenyotei" />} label="前期" />
                        <FormControlLabel control={<Checkbox checked={this.props.formData.jyukenyotei ? this.props.formData.jyukenyotei.split(',').includes("3") : false} value={3} onChange={this.handleChange} name="jyukenyotei" />} label="後期" />
                        <FormControlLabel control={<Checkbox checked={this.props.formData.jyukenyotei ? this.props.formData.jyukenyotei.split(',').includes("4") : false} value={4} onChange={this.handleChange} name="jyukenyotei" />} label="一般" />
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel id="gakunen-label">合否</InputLabel>
                            <MaterialUiSelect
                                labelId="gakunen-label"
                                id="gouhi"
                                name="gouhi"
                                value={this.props.formData.gouhi}
                                onChange={this.handleChange}
                                label="gakunen"
                            >
                                <MenuItem value={0}>-</MenuItem>
                                <MenuItem value={1}>推薦合格</MenuItem>
                                <MenuItem value={2}>前期合格</MenuItem>
                                <MenuItem value={3}>後期合格</MenuItem>
                                <MenuItem value={5}>一般合格</MenuItem>
                                <MenuItem value={4}>不合格</MenuItem>
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        <FormControl variant="outlined" fullWidth size="small" style={{marginTop:0, marginBottom:0}}>
                            <InputLabel>引越理由</InputLabel>
                            <MaterialUiSelect
                                id="reason"
                                name="reason"
                                value={this.props.formData.reason}
                                onChange={this.handleChange}
                                multiple
                                renderValue={()=>this.props.formData.reason.length > 0 ? this.props.formData.reason.map(function (item) {
                                    return parseInt(item) > 0 ? this.props.rentMReasons.filter(a => a.id === parseInt(item))[0].name : ""
                                }, this).join(',') : ""}
                            >
                                {/* <MenuItem value={0}>-</MenuItem> */}
                                {this.props.rentMReasons.filter(a=>a.orderNo != null).sort((a, b) => a['orderNo'] - b['orderNo']).map(function (item, i) {
                                    return (
                                        // <MenuItem key={i} value={item.id}>{item.name}</MenuItem>
                                        <MenuItem value={item.id}><Checkbox checked={this.props.formData.reason.indexOf(item.id) > -1} /><ListItemText primary={item.name} /></MenuItem>
                                    );

                                },this)}
                            </MaterialUiSelect>
                        </FormControl>
                    </CardContent>
                    <CardContent style={{ padding: 5 }}>
                        {/* <TextField
                            id="response_date_start"
                            label="反響日"
                            type="date"
                            name="response_date_start"
                            onChange={this.handleChange}
                            value={this.props.formData.response_date_start}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                        /> */}
                        ～<TextField
                            id="last_response_date_start"
                            label="最終反響日"
                            type="date"
                            name="last_response_date_start"
                            value={this.props.formData.last_response_date_start}
                            onChange={this.handleChange}
                            InputLabelProps={{ shrink: true, }}
                            style={{ width: 130 }}
                            size="small"
                        />
                    </CardContent>
                    <CardContent style={{ paddingLeft: 5}}>
                        <FormControlLabel control={<Checkbox style={{paddingTop:0, paddingBottom:0}} checked={this.props.formData.line_send} name="line_send" onChange={this.handleChange} />} label="LINE選択" />
                        {/* <Button
                            style={{ marginLeft: 5, paddingLeft:5, paddingRight:5 }}
                            onClick={() => this.props.change_line_template_open(true)}
                            //   this.props.handletoggleaddresponse}
                            variant="contained"
                            size="small"
                            color="primary"
                        >一斉送信テンプレート</Button> */}
                        
                        <IconButton size="normal" onClick={() => this.props.change_line_send_list_open(true)}><HistoryIcon color="primary" /></IconButton>
                        {/* <IconButton size="normal" onClick={() => this.props.change_line_template_edit_open(true)}><DynamicFeedIcon color="primary" /></IconButton> */}
                        <IconButton size="normal" onClick={() => this.props.change_line_template_edit_open(true)}><span style={{ color: "green" }} className="fab fa-line" /></IconButton>
                    </CardContent>
                </Card>



                {/* <div className="searchtitle">お客様名</div>
                <div className="searchform">
                    <Field
                        name="responseName"
                        id="responseName"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    />
                </div> */}

                {/* <div className="searchtitle">店舗</div>
                <div className="searchform">
                    <Field
                        name="sectionContactresponse"
                        id="sectionContactresponse"
                        component={RenderSelectInput} options={this.props.shops} />
                </div> */}


                {/* <div className="searchtitle">建物名</div>
                <div className="searchform">
                    <Field
                        name="buildingName"
                        id="buildingName"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    />
                </div> */}

                {/* <div className="searchtitle">駐車場名</div>
                <div className="searchform">
                    <Field
                        name="parkingParkingname"
                        id="parkingParkingname"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    />
                </div> */}

                {/* <div className="searchtitle">売上担当</div>
                <div className="searchform">
                    <Field
                        name="contractUserId"
                        id="staffContactresponse"
                        component={RenderSelectInput} options={this.props.users} />
                </div> */}
                {/* 
                <div className="searchtitle">反響担当</div>
                <div className="searchform">
                    <Field
                        name="responseUserId"
                        id="staffContactresponse"
                        component={RenderSelectInput} options={this.props.users} />
                </div>

                <div className="searchtitle">追客担当</div>
                <div className="searchform">
                    <Field
                        name="staffResponse"
                        id="staffResponse"
                        component={RenderSelectInput} options={this.props.users} />
                </div> */}

                {/* <div className="searchtitle">反響日</div>
                <div className="searchform">
                    <Field
                        name="responseDate1"
                        id="responseDate1"
                        type="text"
                        placeholder=""
                        className="form-control dateform"
                        component={ReduxFormDateRange}
                    />
                    ～
                    <Field
                        name="responseDate2"
                        id="responseDate2"
                        type="text"
                        placeholder=""
                        className="form-control dateform"
                        component={ReduxFormDateRange}
                    />
                </div> */}

                {/* <div className="searchtitle">対応状況</div>
                <div className="searchform">
                    <Field
                        name="responseCondition"
                        id="responseCondition"
                        placeholder=""
                        className="form-control"
                        component="select">
                        <option value="0">すべて</option>
                        <option value="1">反響のみ</option>
                        <option value="2">来店あり</option>
                    </Field>
                </div> */}

                {/* <div className="searchtitle">電話番号</div>
                <div className="searchform">
                    <Field
                        name="responseTel"
                        id="responseTel"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    />
                </div>

                <div className="searchtitle">引越期限</div>
                <div className="searchform">
                    <Field
                        name="responseMovedate1"
                        id="responseMovedate1"
                        type="text"
                        placeholder=""
                        className="form-control dateform"
                        component={ReduxFormDateRange}
                    />
                    ～
                    <Field
                        name="responseMovedate2"
                        id="responseDate2"
                        type="text"
                        placeholder=""
                        className="form-control dateform"
                        component={ReduxFormDateRange}
                    />
                </div> */}

                {/* <div className="searchtitle">反響経路</div>
                <div className="searchform">
                    <Field
                        name="responseWay"
                        id="responseWay"
                        placeholder=""
                        className="form-control"
                        component="select">
                        <option value="0">-</option>
                        <option value="1">TEL</option>
                        <option value="2">メール</option>
                        <option value="3">社宅斡旋</option>
                        <option value="5">飛込</option>
                    </Field>
                </div> */}

                {/* <div className="searchtitle">反響媒体</div>
                <div className="searchform">
                    <Field name="responseMedia"
                        id="responseMedia"
                        className="form-control"
                        component="select"
                    >
                        <option value="0">-</option>
                        {
                            this.props.rentMMedeas.map(function (item, i) {
                                return (
                                    <option key={i} value={item.id}>{item.name}</option>
                                );
                            })
                        }
                    </Field>
                </div> */}

                {/* <div className="searchtitle">代行会社</div>
                <div className="searchform">
                    <Field name="responseAgent"
                        id="responseAgent"
                        className="form-control"
                        component="select"
                    >
                        <option value="0">-</option>
                        {
                            this.props.rentAgents.map(function (item, i) {
                                return (
                                    <option key={i} value={item.id}>{item.name1}</option>
                                );
                            })
                        }
                    </Field>
                </div> */}

                {/* <div className="searchtitle">メール</div>
                <div className="searchform">
                    <Field
                        name="responseMail"
                        id="responseMail"
                        type="text"
                        placeholder=""
                        className="form-control"
                        component="input"
                    />
                </div> */}

                {/* <div className="searchtitle">予算</div>
                <div className="searchform">
                    <div className="form-inline">
                        <Field
                            name="responseMoney1"
                            id="responseMoney1"
                            placeholder=""
                            className="form-control"
                            style={{ width: 100 }}
                            component="select">
                            <option value="0">-</option>
                            <option value="1">2万円</option>
                            <option value="2">2.5万円</option>
                            <option value="3">3万円</option>
                            <option value="4">3.5万円</option>
                            <option value="5">40万円</option>
                            <option value="6">4.5万円</option>
                            <option value="7">5万円</option>
                            <option value="8">5.5万円</option>
                            <option value="9">6万円</option>
                            <option value="10">6.5万円</option>
                            <option value="11">7万円</option>
                            <option value="12">7.5万円</option>
                            <option value="13">8万円</option>
                            <option value="14">8.5万円</option>
                            <option value="15">9万円</option>
                            <option value="16">9.5万円</option>
                            <option value="17">10万円</option>
                            <option value="18">10.5万円</option>
                            <option value="19">11万円</option>
                            <option value="33">12万円</option>
                            <option value="34">13万円</option>
                            <option value="35">14万円</option>
                            <option value="36">15万円以上</option>
                        </Field>
                    ～

                    <Field
                            name="responseMoney2"
                            id="responseMoney2"
                            placeholder=""
                            className="form-control"
                            style={{ width: 100 }}
                            component="select">
                            <option value="0">-</option>
                            <option value="1">2万円</option>
                            <option value="2">2.5万円</option>
                            <option value="3">3万円</option>
                            <option value="4">3.5万円</option>
                            <option value="5">40万円</option>
                            <option value="6">4.5万円</option>
                            <option value="7">5万円</option>
                            <option value="8">5.5万円</option>
                            <option value="9">6万円</option>
                            <option value="10">6.5万円</option>
                            <option value="11">7万円</option>
                            <option value="12">7.5万円</option>
                            <option value="13">8万円</option>
                            <option value="14">8.5万円</option>
                            <option value="15">9万円</option>
                            <option value="16">9.5万円</option>
                            <option value="17">10万円</option>
                            <option value="18">10.5万円</option>
                            <option value="19">11万円</option>
                            <option value="33">12万円</option>
                            <option value="34">13万円</option>
                            <option value="35">14万円</option>
                            <option value="36">15万円以上</option>
                        </Field>
                    </div>
                </div> */}

                {/* <div className="searchtitle">間取り</div>
                <div className="searchform">
                    <div className="form-inline">
                        <Field
                            name="responseMadori1"
                            id="responseMadori1"
                            placeholder=""
                            className="form-control"
                            style={{ width: 100 }}
                            component="select">
                            <option value="0">-</option>
                            <option value="1">1R</option>
                            <option value="2">1K</option>
                        </Field>
                    ～
                        <Field
                            name="responseMadori2"
                            id="responseMadori2"
                            placeholder=""
                            className="form-control"
                            style={{ width: 100 }}
                            component="select">
                            <option value="0">-</option>
                            <option value="1">1R</option>
                            <option value="2">1K</option>
                        </Field>
                    </div>
                </div> */}

                {/* <div className="searchtitle">地域</div>
                <div className="searchform">
                    <Field
                        name="responseLocation"
                        id="responseLocation"
                        placeholder=""
                        className="form-control"
                        component="select">
                        <option value="0">-</option>
                        <option value="1">高松市中心部</option>
                        <option value="2">レインボー・サンフラワー周辺</option>
                        <option value="3">高松市南部</option>
                        <option value="4">高松市東部</option>
                        <option value="5">高松市西部</option>
                        <option value="6">香川町　三木町</option>
                        <option value="7">庵治町・牟礼町</option>
                        <option value="8">高松市全域</option>
                    </Field>
                </div> */}

                {/* <div className="searchtitle">条件</div>
                <div className="searchform">
                    <Field
                        name="responseParticular"
                        id="responseParticular"
                        placeholder=""
                        className="form-control"
                        component="select">
                        <option value="0">-</option>
                        <option value="1">ペット可</option>
                        <option value="2">オートロック</option>
                    </Field>
                </div> */}

                {/* <div className="searchtitle">法人</div>
                <div className="searchform">
                    <label><Field name="responseCorporate" id="responseCorporate" component="input" type="checkbox" />法人</label>
                </div>

                <div className="searchtitle">学生</div>
                <div className="searchform">
                    <label><Field name="responseStudent" id="responseStudent" component="input" type="checkbox" />学生</label>
                </div> */}

                {/* <div className="searchtitle">追客状況</div>
                <div className="searchform">
                    <Field
                        name="endResponse"
                        id="endResponse"
                        placeholder=""
                        className="form-control"
                        component="select">
                        <option value="0">追客中のみ</option>
                        <option value="1">追客終了のみ</option>
                        <option value="2">すべて</option>
                    </Field>
                </div>

                <div className="searchtitle">学生予約</div>
                <div className="searchform">
                    <label><Field name="responseStudentBook" id="responseStudentBook" component="input" type="checkbox" />学生予約</label>
                </div>
                <div className="searchtitle">　　</div> */}
            </div>
            </Scrollbars>
        );
    }
}




//export default connect(
//  state => state.weatherForecasts,
//  dispatch => bindActionCreators(actionCreators, dispatch)
//)(RoomInfoEdit);

const mapStateToProps = state => {
    return {
        rentMMedeas: state.masterReducer.rentMMedeas,
        rentAgents: state.masterReducer.rentAgents,
        users_select: state.masterReducer.users_select,
        section_select: state.masterReducer.section_select,
        shops: state.masterReducer.shops,
        user_details: state.masterReducer.user_details,
        formData: state.responselist.formData,
        response_count: state.responselist.response_count,
        rent_m_agents: state.responselist.rent_m_agents,
        //customerdetail: state.customerReducer.detail,
        //firebasetoken: state.firebaseReducer.firebasetoken,
        //firebasedata: state.firebaseReducer.firebasedata,
        form_count: state.responselist.form_count,
        response_follow_sheet_open: state.ResponseFollowSheet.response_follow_sheet_open,
        response_search_list: state.responselist.response_search_list,
        rentMReasons: state.masterReducer.rentMReasons,
        line_template_open: state.responselist.line_template_open,
        line_send_list_open: state.responselist.line_send_list_open,
        line_template_edit_open: state.responselist.line_template_edit_open,
        rent_m_schools: state.responsesReducer.rent_m_schools,
        
    }
}
function mapDispatchToProps(dispatch) {
    return {

        change_formData(state) {
            dispatch(ResponseList.change_formData(state))
        },

        change_line_user_id(state) {
            dispatch(ResponseDetail.change_line_user_id(state))
        },
        open_response_add(state) {
            dispatch(ResponseDetail.open_response_add(state))
        },
        search_response_list2(state) {
            dispatch(ResponseList.search_response_list2(state))
        },
        responseList(state) {
            dispatch(ResponseList.responseList(state))
        },

        get_rent_m_agents(state) {
            dispatch(ResponseList.get_rent_m_agents(state))
        },

        change_form_count(state) {
            dispatch(ResponseList.change_form_count(state))
        },
        change_response_follow_sheet_open(state) {
            dispatch(ResponseFollowSheetSaga.change_response_follow_sheet_open(state))
        },
        change_line_send_list_open(state) {
            dispatch(ResponseList.change_line_send_list_open(state))
        },
        change_rent_line_schedule_list_open(state) {
            dispatch(ResponseList.change_rent_line_schedule_list_open(state))
        },
        change_line_template_edit_open(state) {
            dispatch(ResponseList.change_line_template_edit_open(state))
        },
        change_line_template_open(state) {
            dispatch(ResponseList.change_line_template_open(state))
        },
        
        //loadTodos(state) {
        //    dispatch(loadTodos(state))
        //}
    };
}

ResponseSearchComponent = reduxForm({
    form: 'roomeditform', // a unique identifier for this form
    validate//バリデーション
    //enableReinitialize: true, // initialValuesプロップが更新されると、フォームも更新されます。
})(ResponseSearchComponent)

export default connect(mapStateToProps, mapDispatchToProps)(ResponseSearchComponent);