import React, { Component } from 'react';

import { connect } from 'react-redux';
import * as Contracts from './Saga/Contracts';
import Button from '@mui/material/Button';
import "react-input-range/lib/css/index.css"
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { ModalHeader, ModalBody, ModalFooter, Modal } from 'reactstrap';
import ContractsZenhorenFile from './ContractsZenhorenFile';
import IconButton from '@mui/material/IconButton';
import ErrorIcon from '@mui/icons-material/ErrorOutline';
var moment = require('moment-timezone');

const a = () => <Typography variant="caption" color="error">
  店長確認
</Typography>
const steps = [
  '契約書作成依頼',
  a,
  'Create an ad',
];

const HorizontalLabelPositionBelowStepper = () =>
  <Box sx={{ width: '100%' }}>
    <Stepper activeStep={1} alternativeLabel>

      <Step key={0}>
        <StepLabel>契約書作成依頼</StepLabel>
      </Step>
      <Step key={1} variant="caption" color="error">
        <StepLabel>契約書作成依頼</StepLabel>
      </Step>
    </Stepper>
  </Box >;
// const HorizontalLabelPositionBelowStepper = () =>
//   <Box sx={{ width: '100%' }}>
//     <Stepper activeStep={1} alternativeLabel>
//       {steps.map((label,index) => (
//         <Step key={label}>
//           <StepLabel>{label}</StepLabel>
//         </Step>
//       ))}
//     </Stepper>
//   </Box>;

// function HorizontalLabelPositionBelowStepper() {
//   return (
//     <Box sx={{ width: '100%' }}>
//       <Stepper activeStep={1} alternativeLabel>
//         {steps.map((label) => (
//           <Step key={label}>
//             <StepLabel>{label}</StepLabel>
//           </Step>
//         ))}
//       </Stepper>
//     </Box>
//   );
// }
const active_step = [
  {id: -1, value: 0},
  {id: 0, value: 1},//店長審査待ち
  {id: 1, value: 2},//店長承認
  {id: 2, value: 3},//管理承認
  {id: 3, value: 1},//店長却下
  {id: 4, value: 3},//管理却下
]

function Kanri_check(rent_contract_details){
  if(rent_contract_details.special_contract_decision || 
             rent_contract_details.electronics || 
             rent_contract_details.special_contract || 
             rent_contract_details.contract_remarks || 
             //this.props.rent_contract_details.checkalert || //
             rent_contract_details.teikishakka_year > 0 ||
             rent_contract_details.teikishakka_period_date ||
             rent_contract_details.tatemono_id == 2108 ||  //ＡＳ　ＰＲＥＭＩＵＭ栗林公園
             rent_contract_details.tatemono_id == 2846709 ||  // Ｋ’ｓＢ上之町
             rent_contract_details.tatemono_id == 1613 //リアライズ屋島
  ){
    return 1
  }else{
    return 0
  }
}

const KanriApproval = ({ status, confirm_manager_user_id, confirm_pm_user_id, division, 
  user_details, update_rent_contract_workflow_pm, rent_contract_send_status, rent_contract_workflows, users, handleRemarksChange}) => {
  let re;
  if ((status == 0 && confirm_manager_user_id > 0) || status == 8) {
    re = <Alert icon={false} severity="info" style={{textAlign:"left"}} >管理部 承認待ち
      {
      user_details.amSectionId == 10 ||
      user_details.amRollId == 6 || 
      user_details.id == 1060 || //増田祐
      // user_details.id == 1098 ||  //脇
      user_details.id == 1058 ||  //中村憲生
      user_details.id == 1052 ||  //吉田正人
      user_details.id == 1090 ||  //福井幹
      //user_details.id == 1272 ||  //得居
      user_details.id == 1130 ||  //大高
      // user_details.id == 1345 ||  //竹本
      user_details.id == 1383 //中井晋
      
      ? <div>
        <Button size="small" variant="contained" color="primary" onClick={(e) => { update_rent_contract_workflow_pm(1) }}>承認</Button>
        <Button size="small" variant="contained" color="error" onClick={(e) => {update_rent_contract_workflow_pm(4) }} style={{marginLeft:5}}>却下</Button>
        <div><TextField 
        label="備考"
        name="remarks"
        onChange={handleRemarksChange}
        value={rent_contract_send_status.pm_remarks}
        // style={{ width: "300px", marginTop: "10px" }}
        variant="outlined"
        fullWidth style={{marginTop:5}}
        size='small'
        ></TextField></div>
      </div>:""}
    </Alert>
  } else {
    if(status == 4){
      re = <Alert icon={false} severity="error" style={{textAlign:"left"}}>管理部 却下 {rent_contract_workflows.confirm_pm_user_id > 0 && users ? users.filter(a=>a.id == rent_contract_workflows.confirm_pm_user_id)[0].nickname: ""}<br />
      {moment(rent_contract_workflows.confirm_pm_at).format('YYYY年MM月DD日')}</Alert>
    }else if(confirm_pm_user_id > 0){
      re = <Alert icon={false} severity="success" style={{textAlign:"left"}}>管理部 承認済 {rent_contract_workflows.confirm_pm_user_id > 0 && users ? users.filter(a=>a.id == rent_contract_workflows.confirm_pm_user_id)[0].nickname: ""}<br />
      {moment(rent_contract_workflows.confirm_pm_at).format('YYYY年MM月DD日')}</Alert>
    }else{
      re = <Alert icon={false} style={{backgroundColor:"#f5f5f5", textAlign:"center"}}>管理部　承認待ち</Alert>
    }
    
  }
  return re
};

class ReSend extends Component {

  constructor(props) {
    super(props);
    this.state = {
      refresh: false,
      tenpo: -1,

      // zenhoren_status: 0,  //0:依頼まだ　1:審査中  2:承認　3:審査落ち
      workflow_status: -1,
      kanri_approval: 1,  // 1:管理部の承認必要
      confirm_pm_remarks: "", // 管理承認備考
      division: 3,  // 管理：1,2　一般：3,6　業物：4,5

    };
    this.handleChangeStore = this.handleChangeStore.bind(this);
    this.handleRemarksChange = this.handleRemarksChange.bind(this);
  }
  handleChangeStore = (event) => {

    let rent_contract_send_status = this.props.rent_contract_send_status
    rent_contract_send_status.send_section_id = event.target.value
    this.props.change_rent_contract_send_status(rent_contract_send_status)

    console.log(event.target.value);
    this.setState({ refresh: !this.state.refresh })
  };
  handleRemarksChange = (event) => {
    let rent_contract_send_status = this.props.rent_contract_send_status
    rent_contract_send_status.pm_remarks = event.target.value
    this.props.change_rent_contract_send_status(rent_contract_send_status)

    console.log(event.target.value);
    this.setState({ refresh: !this.state.refresh })
  };

  // 管理　(全保連)　依頼　店長承認　(管理部承認)
  // 一般　店舗依頼　店長承認 特約確認
  // 業物　フォローシートのみ


  
  render() {

    const RequestContract = ({ counting, workflows, division }) => {
      let re = '';
      // alert(status)
      // alert(division)

      // countingが1か2でworkflowがないときは作成依頼

      if (division === 1 || division === 2) {//管理
        
          if (!workflows.id) {
            //division 管理：1,2　一般：3,6　業物：4,5
            re = <Alert icon={false} severity="info" style={{textAlign:"left"}} action={<Button size="small" onClick={(e) => { this.props.insert_rent_contract_workflow() }} variant="contained">依頼</Button>} >契約書作成依頼</Alert>
          } else {
            re = <Alert icon={false} severity="success">契約書作成依頼済<br/>{moment(this.props.rent_contract_workflows.created_at).format('YYYY年MM月DD日')}</Alert>
          }


      } else if (division === 3 || division === 6) {//一般
        if (!workflows.id) {
          re = <div>
            <Alert icon={false} severity="info">
            <div style={{ maxWidth:150}}>
            <FormControl size="small" sx={{ minWidth: 150 }}>
              <InputLabel size="small" id="demo-simple-select-label">依頼店舗</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="--店舗名--"
                width="150"
                style={{ borderRadius: '5px 5px 0 0', backgroundColor:"white" }}
                value={this.props.rent_contract_send_status.send_section_id}
                onChange={this.handleChangeStore}
                size="small"
                margin='dense'
              >
                <MenuItem value={3}>高松駅前店</MenuItem>
                <MenuItem value={2}>レインボー店</MenuItem>
                <MenuItem value={17}>サンフラワー店</MenuItem>
                <MenuItem value={1}>番町店</MenuItem>
                <MenuItem value={4}>東店</MenuItem>
                <MenuItem value={6}>中央公園店</MenuItem>
                <MenuItem value={47}>中央通り店</MenuItem>
                {/* <MenuItem value={0}>経理に送信</MenuItem> */}
              </Select>

            </FormControl>
            </div>

            <Button variant="outlined" size="small" onClick={(e) => { this.props.insert_rent_contract_workflow(0) }} disabled={this.props.rent_contract_send_status.send_section_id > 0 ? false : true} style={{ fontSize: 12, color: '#fff', backgroundColor: this.props.rent_contract_send_status.send_section_id > 0 ? '#5bc0de' : "#e2e2e2", borderColor: this.props.rent_contract_send_status.send_section_id > 0 ? '#5bc0de' : '#e2e2e2', borderRadius: '0 0 5px 5px', padding: 5, minWidth: 150 }}>
              契約書作成依頼 </Button>
            </Alert>

          </div>
        }else{
          //division 管理：1,2　一般：3,6　業物：4,5
          re = <Alert icon={false} severity="success" style={{textAlign:"left"}}><div>契約書作成依頼済</div>
          {this.props.shops.length > 0 && this.state.tenpo > 0 ? <div>{this.props.shops.filter(a=>a.tenpoNo == this.state.tenpo)[0].tenpoName}</div> : ""}
          </Alert>
          
        }
      }
      // re = <Alert icon={false} severity="info" action={<Button size="small" variant="contained">依頼</Button>}>契約書作成依頼</Alert>/* 後ほど消す */
      return re
    };

    const ManagerApproval = ({ status, division, confirm_manager_user_id, special_contract_decision, rent_contract_details }) => {
      let re = "";
      if ((status === 0 && confirm_manager_user_id == 0) || status == 7) {
        
        re = <Alert icon={false} severity="info" style={{textAlign:"left"}} >店長 承認待ち
          {
          // this.props.rent_contract_workflows.manager && this.props.rent_contract_workflows.manager.split(",").filter(a => a == this.props.user_details.id).length >= 0
          // this.props.user_details.mPositionId == 2 || 
          // this.props.user_details.mPositionId == 4 || 
          // this.props.user_details.mPositionId == 5 ||
          // this.props.user_details.mPositionId == 10 || 
          // this.props.user_details.id == 1191 || 
          // this.props.user_details.id == 1377
          this.props.user_details.amSectionId == 10 || 
          this.props.user_details.mPositionId == 2 || 
          this.props.user_details.mPositionId == 4 || 
          this.props.user_details.id == 1190 || //竹内
          this.props.user_details.id == 1252 || //三宅
          this.props.user_details.id == 1005  //若さ

          ? <div>
            <Button size="small" variant="contained" color="primary" onClick={(e) => this.props.update_rent_contract_workflow_manager(status == 7 ? (Kanri_check(rent_contract_details) == 1 ? 8 : 1) : (Kanri_check(rent_contract_details) == 1 ? 0 : 1))}>承認</Button> {/*変更の承認の時　管理部が承認済みのとき通らない可能性も*/}
            <Button size="small" variant="contained" color="error" onClick={(e) => this.props.update_rent_contract_workflow_manager(3)} style={{marginLeft:5}}>却下</Button>
          </div>:""}
        </Alert>
      }else if(status == 3){
        re = <Alert icon={false} severity="error" style={{textAlign:"left"}}>店長 却下 {this.props.rent_contract_workflows.confirm_manager_user_id > 0 && this.props.users ? this.props.users.filter(a=>a.id == this.props.rent_contract_workflows.confirm_manager_user_id)[0]?.nickname: ""}<br />
        {moment(this.props.rent_contract_workflows.confirm_manager_at).format('YYYY年MM月DD日')}</Alert>
      }else if(confirm_manager_user_id > 0){ //  status == 1 承認済
        re = <Alert icon={false} severity="success" style={{textAlign:"left"}}>店長 承認済 {this.props.rent_contract_workflows.confirm_manager_user_id > 0 && this.props.users ? this.props.users.filter(a=>a.id == this.props.rent_contract_workflows.confirm_manager_user_id)[0]?.nickname: ""}<br />
        {moment(this.props.rent_contract_workflows.confirm_manager_at).format('YYYY年MM月DD日')}</Alert>
      }else{
        re = <Alert icon={false} style={{backgroundColor:"#f5f5f5", textAlign:"center"}}>店長 承認待ち</Alert>
      }

      return re
    };



    const SpecialContractDecision = ({ status, confirm_manager_user_id }) => {
      let re = "";
      if(status == 1){
        re = <Alert icon={false} severity="success" style={{textAlign:"left"}} >特約確認済</Alert>
      } else if (status == 0 && confirm_manager_user_id > 0) {
        re = <Alert icon={false} severity="info" style={{textAlign:"left"}} >特約確認
        {this.props.rent_contract_workflows.manager && this.props.rent_contract_workflows.manager.split(",").filter(a => a == this.props.user_details.id).length >= 0 ? <div>
            <Button size="small" variant="contained" color="primary" onClick={(e) => this.props.update_rent_contract_workflow_manager(1)}>承認</Button>
            <Button size="small" variant="contained" color="error" onClick={(e) => this.props.update_rent_contract_workflow_manager(3)} style={{marginLeft:5}}>却下</Button>
          </div>:""}
        </Alert>

      } else {
        re = <Alert icon={false} style={{backgroundColor:"#f5f5f5", textAlign:"center"}}>特約確認待ち</Alert>
      }
      return re
    };

    return (
      // Object.keys(this.props.rent_contract_workflows).length ?
      // : ''
      <div style={{ margin: 10 }}>
        {/* status:{this.props.rent_contract_workflows.status}　
        w_division:{this.props.rent_contract_workflows.division}　
        d_division:{this.props.rent_contract_details.division}  */}

      <div>
        {(this.props.rent_contract_details.division == 1 || this.props.rent_contract_details.division == 2) && // 管理かサブで
        (this.props.rent_contract_details.counting == 1 || this.props.rent_contract_details.counting == 2) && // 1か2で
        this.props.rent_contract_details.rent_m_guarantee_id == 2 && // 全保連
        this.props.rent_contract_details.zenhoren_status != 2 ? // 全保連まだ承認済みでない
        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={this.props.rent_contract_details.zenhoren_status == 3 ? 3 : this.props.rent_contract_details.zenhoren_status} alternativeLabel>
              <Step key={0} variant="caption" color="error">
                <StepLabel>
                {this.props.rent_contract_details.zenhoren_status == 0 ?  <Alert icon={false} severity="info" action={<span>
                  <Button size="small" color="primary" onClick={(e) => { this.setState({ "modal_file" : true, }) }} variant="contained">依頼</Button>
                  {this.props.rent_contract_details.zenhoren_result ? <IconButton size="small" onClick={(e) => { this.props.change_zenhoren_result_modal(true)}} variant="contained"><ErrorIcon style={{color:"red"}} /></IconButton>:""}</span>}>  全保連 審査依頼</Alert>
                : <Alert icon={false} severity="success">全保連 審査承認済</Alert>}
                </StepLabel>
              </Step>
              <Step key={1}>
                <StepLabel>
                  {this.props.rent_contract_details.zenhoren_status > 1 ? 
                  <Alert icon={false} severity="success">全保連 審査済</Alert> : 
                  this.props.rent_contract_details.zenhoren_status > 0 ? 
                    <Alert icon={false} severity="info">全保連 審査中</Alert> : 
                    <Alert icon={false} style={{backgroundColor:"#f5f5f5", textAlign:"center"}}>全保連 審査待ち</Alert>}
                </StepLabel>
              </Step>
              <Step key={2}>
                <StepLabel>{this.props.rent_contract_details.zenhoren_status == 3 ? <Alert severity="error">全保連 審査落ち</Alert>: <Alert icon={false} style={{backgroundColor:"#f5f5f5", textAlign:"center"}}><div>全保連 結果</div></Alert>}</StepLabel>
              </Step>
            </Stepper>
        </Box >
        :
        ""}

        
{
        ((this.props.rent_contract_details.division == 1 || this.props.rent_contract_details.division == 2) && // 管理かサブで
        (this.props.rent_contract_details.counting == 1 || this.props.rent_contract_details.counting == 2) && // 1か2で
        this.props.rent_contract_details.rent_m_guarantee_id != 2 || this.props.rent_contract_details.zenhoren_status == 2) ? //全保連じゃないか全保連承認済み

        <Box sx={{ width: '100%' }}>
          <Stepper 
            activeStep={
              (this.props.rent_contract_details.rent_m_guarantee_id == 2 ? 1 : 0) + 
              (this.props.rent_contract_workflows.status == 0 || this.props.rent_contract_workflows.status == 1 ? 1 : 0) + 
              (this.props.rent_contract_workflows.confirm_manager_user_id > 0 ? 1 : 0 ) + 
              (this.props.rent_contract_workflows.confirm_pm_user_id > 0 ? 1 : 0 )
            }
            alternativeLabel>

            {this.props.rent_contract_details.rent_m_guarantee_id == 2 && this.props.rent_contract_details.zenhoren_status == 2 ? <Step key={0}>
              <StepLabel><Alert icon={false} severity="success">全保連 審査承認済</Alert></StepLabel>
            </Step>: ""}

            <Step key={1} variant="caption" color="error">
              <StepLabel>
                <RequestContract 
                  counting={this.props.rent_contract_details.counting} 
                  workflows={this.props.rent_contract_workflows} 
                  division={this.props.rent_contract_details.division}
                />
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>
                <ManagerApproval 
                  status={this.props.rent_contract_workflows.status} 
                  division={this.props.rent_contract_details.division} 
                  confirm_manager_user_id={this.props.rent_contract_workflows.confirm_manager_user_id} 
                  special_contract_decision={this.props.rent_contract_details.special_contract_decision} 
                  rent_contract_details={this.props.rent_contract_details}
                />
              </StepLabel>
            </Step>
            {Kanri_check(this.props.rent_contract_details) == 1 ? 
             <Step key={3}>
              <StepLabel>
                <KanriApproval 
                  status={this.props.rent_contract_workflows.status} 
                  confirm_manager_user_id={this.props.rent_contract_workflows.confirm_manager_user_id} 
                  confirm_pm_user_id={this.props.rent_contract_workflows.confirm_pm_user_id} 
                  user_details={this.props.user_details}
                  update_rent_contract_workflow_pm={this.props.update_rent_contract_workflow_pm}
                  rent_contract_send_status={this.props.rent_contract_send_status}
                  rent_contract_workflows={this.props.rent_contract_workflows}
                  users={this.props.users}
                  handleRemarksChange={this.handleRemarksChange}
                />
              </StepLabel>
            </Step> : ""}
          </Stepper>
        </Box > : ""}

        {this.props.rent_contract_details.division == 3 || this.props.rent_contract_details.division == 6 ? // 一般
        <Box sx={{ width: '100%' }}>
          <Stepper 
            activeStep={
              (this.props.rent_contract_workflows.status == 0 || this.props.rent_contract_workflows.status == 1 ? 1 : 0) + 
              (this.props.rent_contract_workflows.confirm_manager_user_id > 0 ? 1 : 0 ) + 
              (this.props.rent_contract_workflows.confirm_pm_user_id > 0 ? 1 : 0 )
            }
            alternativeLabel>

            <Step key={1} variant="caption" color="error">
              <StepLabel>
                <RequestContract 
                  counting={this.props.rent_contract_details.counting} 
                  workflows={this.props.rent_contract_workflows} 
                  division={this.props.rent_contract_details.division}
                />
              </StepLabel>
            </Step>
            <Step key={2}>
              <StepLabel>
                <ManagerApproval 
                  status={this.props.rent_contract_workflows.status} 
                  division={this.props.rent_contract_details.division} 
                  confirm_manager_user_id={this.props.rent_contract_workflows.confirm_manager_user_id} 
                  special_contract_decision={this.props.rent_contract_details.special_contract_decision} 
                />
              </StepLabel>
            </Step>
            {/* {this.props.rent_contract_details.special_contract_decision ? <Step key={3}>
              <StepLabel>
                <SpecialContractDecision 
                  status={this.props.rent_contract_workflows.id ? this.props.rent_contract_workflows.status : -1} 
                  confirm_manager_user_id={this.props.rent_contract_details.confirm_manager_user_id} 
                  confirm_pm_user_id={this.props.rent_contract_details.confirm_pm_user_id} 
                />
              </StepLabel>
            </Step> : ""} */}
          </Stepper>
        </Box >
        :
        ""}

        {this.props.rent_contract_details.division == 4 || this.props.rent_contract_details.division == 5 ? // 業物
        <Alert severity="error"> 経理フォローシートのアップのみ行ってください。</Alert>:""}
      </div>

      {this.props.rent_contract_workflows.status == 1 ? <Alert style={{marginTop:10}} severity="info" action={<Button variant="contained" color="info" size="small">ダウンロード</Button>}>
      最終DL者：{this.props.rent_contract_details.contract_doc_print_user > 0 && this.props.users ? "" : ""}　　最終DL日：{this.props.rent_contract_details.contract_doc_print_date ? moment(this.props.rent_contract_details.contract_doc_print_date).format('YYYY/MM/DD HH:mm'):""}　　DL回数：{this.props.rent_contract_details.contract_doc_print_count}
      </Alert>:""}

        {/* <div style={{ display: 'inlineblock' }}>
          <Button variant="outlined" size="small" style={{ margin: '20px 20px 20px 0', color: '#fff', backgroundColor: '#5bc0de', borderColor: '#46b8da' }}><FolderIcon />　重要事項説明書</Button>
          {this.props.rent_contract_workflows.contract_doc_first_dl_confirm_at ?
            <Button size="small" variant="outlined" >事務ダウンロード確認済み{moment(this.props.rent_contract_workflows.contract_doc_first_dl_confirm_at).format('YYYY年MM月DD日　hh:mm:ss')}</Button> :
            <Button size="small" variant="outlined" >事務ダウンロード</Button>}
        </div> */}


        <Modal isOpen={this.state.modal_file} toggle={(e) => { this.setState({ "modal_file": false }) }} size="xl" fade={false}>

          <ModalHeader toggle={(e) => { this.setState({ "modal_file": false }) }}>ファイル選択</ModalHeader>
          <ModalBody>
              <ContractsZenhorenFile contract_files={this.props.contract_files_all.filter(a=>a.type == 0)} />
          </ModalBody>
          <ModalFooter>

            {this.props.zenhoren_file.type == "" || this.props.zenhoren_file.file_id.length == 0 ? <Alert severity="error" style={{ margin: 5, width:"100%" }} action={
              <Button variant="contained" size="small" color="inherit" style={{ float: "right", marginRight: 5, color:"black" }} onClick={(e) => { this.setState({ "modal_file": false }) }}>閉じる</Button>
          } >書類の種類または対象ファイルを選択してください。</Alert>:
            <span>
              <Button variant="contained" size="small" color="primary" onClick={(e) => { this.props.update_rent_contracts_zenhoren_apply();this.setState({ "modal_file" : false, }) }}>依頼</Button>
              <Button variant="contained" size="small" color="inherit" style={{ float: "right", marginLeft: 5 }} onClick={(e) => { this.setState({ "modal_file": false }) }}>閉じる</Button>
            </span>
            }
              
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.props.zenhoren_result_modal} toggle={(e) => { this.props.change_zenhoren_result_modal(false)}} size="lg" fade={false}>

          <ModalHeader toggle={(e) => { this.props.change_zenhoren_result_modal(false)}}>全保連　不備内容</ModalHeader>
          <ModalBody>
            {/* {console.log((this.props.rent_contract_details.zenhoren_result ? JSON.parse(this.props.rent_contract_details.zenhoren_result.replace(/\"/g,'"')): []))} */}
          {
            (this.props.rent_contract_details.zenhoren_result ? JSON.parse(this.props.rent_contract_details.zenhoren_result.replace(/\"/g,'"')): []).map(function (item) {
                return (
                  <div>
                  {item.message}
                </div>
                )
            })
          }

          </ModalBody>
          <ModalFooter>
              <Button variant="contained" size="small" color="inherit" style={{ float: "right", marginLeft: 5 }} onClick={(e) => { this.props.change_zenhoren_result_modal(false)}}>閉じる</Button>
          </ModalFooter>
        </Modal>

      </div>
    );
  }
}
//コンテナ
const mapStateToProps = state => {
  return {
    rent_contract_details: state.contracts.rent_contract_details,
    //customerdetail1: state.customersReducer.customerdetail1,
    //firebasetoken: state.firebaseReducer.firebasetoken,
    rent_contract_workflows: state.contracts.rent_contract_workflows,
    users: state.masterReducer.users,
    user_details: state.masterReducer.user_details,
    shops: state.masterReducer.shops,
    contract_files_all: state.files.contract_files_all,
    zenhoren_file: state.contracts.zenhoren_file,
    zenhoren_result_modal: state.contracts.zenhoren_result_modal,
    rent_contract_send_status: state.contracts.rent_contract_send_status,
  }
}
function mapDispatchToProps(dispatch) {
  return {

    update_rent_contracts_zenhoren_apply(state) {
      dispatch(Contracts.update_rent_contracts_zenhoren_apply(state))
    },
    change_zenhoren_result_modal(state) {
      dispatch(Contracts.change_zenhoren_result_modal(state))
    },
    insert_rent_contract_workflow(state) {
      dispatch(Contracts.insert_rent_contract_workflow(state))
    },
    change_rent_contract_send_status(state) {
      dispatch(Contracts.change_rent_contract_send_status(state))
    },
    update_rent_contract_workflow_manager(state) {
      dispatch(Contracts.update_rent_contract_workflow_manager(state))
    },
    update_rent_contract_workflow_pm(state) {
      dispatch(Contracts.update_rent_contract_workflow_pm(state))
    },
    // change_rent_contract_logs_old(state) {
    //   dispatch(Contracts.change_rent_contract_logs_old(state))
    // },
    // change_rent_contract_logs_ver(state) {
    //   dispatch(Contracts.change_rent_contract_logs_ver(state))
    // },
    // contracts_send_parking(state) {
    //   dispatch(Contracts.contracts_send_parking(state))
    // },
    // download_docs(state) {
    //   dispatch(Contracts.download_docs(state))
    // },
    // download_bill(state) {
    //   dispatch(Contracts.download_bill(state))
    // },
    // download_contract(state) {
    //   dispatch(Contracts.download_contract(state))
    // },
    // change_contracts_cancel_confirm(state) {
    //   dispatch(Contracts.change_contracts_cancel_confirm(state))
    // },
    // contracts_cancel(state) {
    //   dispatch(Contracts.contracts_cancel(state))
    // },
    // change_contract_detail_edit_disp(state) {
    //   dispatch(Estimates.change_contract_detail_edit_disp(state))
    // },
    // contracts_cancel(state) {
    //     dispatch(Contracts.contracts_cancel(state))
    //   },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReSend);
