import React, { Component } from 'react';

import { connect } from 'react-redux';
import Dropzone from 'react-dropzone';
import RoomSearchListComponent from '../RoomList/Index';
import * as Master from '../../Store/Master';
import * as Mail from './Saga/Mail';
import * as MapSearch from '../MapSearch/Saga/MapSearch';
import * as MailTemplate from '../MailTemplate/Saga/MailTemplate';
import Loading from '../Layout/LoadingComponent';
// import '../../Css/Loading.css';
// import  MailEditAreaComponent from './MailEditAreaComponent';
import MailCkEditorComponent from './MailCkEditorComponent';
import MailTemplateComponent from '../MailTemplate/MailTemplateComponent';

// import  MailTemplateListSelectComponent from '../MailTemplate/MailTemplateListSelectComponent.js_';
import MapSearchComponent from '../MapSearch/Index';

import EstimateListComponent from '../EstimateList/Index';
import MyPagesComponent from './MyPagesComponent';
import TemplateListComponent from './TemplateListComponent';

import '../../Css/Mail.css';
import DeleteIcon from '@mui/icons-material/Delete';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import MailEditHeaderComponent from './MailEditHeaderComponent';
import { Modal, ModalHeader, ModalFooter, Button, ModalBody } from 'reactstrap';
import ButtonMui from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';

// タイマー用
let timer;


class MailEditorComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      mode: '1',
      messages: [],
      scrollTop: 0,
      old_id: 0,
      back_date: "",
      modalsearch: false,
      modal: false,
      modalselect: false,
      files: [],
      mailid: 0,
      modalTemplateSelect: false,
      textareaHeight: 0,
      modalNoSubject: false,
      modalmismatchAddress: false,
      tooltipOpen: false,
      modalDelete: false,
      modalmap: false,
      modalmypage: false,

      modalNewTemplateSelect: false,
      modalTemplateSave: false,
      refresh:false,

      tab:0,

    };
    console.log(props.send_mail_details)
    this.toggletooltip = this.toggletooltip.bind(this);
    // props.send_mail_details.subject="お問い合わせありがとうございます。";
    // props.send_mail_details.body='<table><tbody><tr><td data-row="row-61s4"><br></td><td data-row="row-61s4"><br></td></tr><tr><td data-row="row-j3w1"><br></td><td data-row="row-j3w1"><br></td></tr><tr><td data-row="row-xdw6"><br></td><td data-row="row-xdw6"><br></td></tr></tbody></table>';
    props.get_mail_rent_introduction_header_list(props.responseid)
    props.get_mail_template_select_list()


    this.save = this.save.bind(this);
    // props.get_mails()

    this.onDrop = this.onDrop.bind(this);

    this.handleChange = this.handleChange.bind(this);
    // this.handleEditorChange = this.handleEditorChange.bind(this);

    this.downloadfile = this.downloadfile.bind(this);
    this.togglesearch = this.togglesearch.bind(this);
    this.togglemap = this.togglemap.bind(this);
    this.toggleestimates = this.toggleestimates.bind(this);

    this.toggleTemplateSelect = this.toggleTemplateSelect.bind(this);

    this.selectTatemono = this.selectTatemono.bind(this);
    this.deletefile = this.deletefile.bind(this);

    this.delete_draft = this.delete_draft.bind(this);
    this.toggleNoSubject = this.toggleNoSubject.bind(this);
    this.toggleDelete = this.toggleDelete.bind(this);
    this.togglemismatchAddress = this.togglemismatchAddress.bind(this);
    this.togglemypage = this.togglemypage.bind(this);

    this.modalRef = React.createRef()
    this.modaltemplateRef = React.createRef()
    this.handleClickEvent = this.handleClickEvent.bind(this)
    this.togglemypageclose = this.togglemypageclose.bind(this)
    this.toggleTemplateSelectClose = this.toggleTemplateSelectClose.bind(this)
    this.toggleselectclose = this.toggleselectclose.bind(this)
    this.handleClickMailTemplateSelect = this.handleClickMailTemplateSelect.bind(this)
    this.handleTemplateClose = this.handleTemplateClose.bind(this)
    this.handleClickMailTemplateSave = this.handleClickMailTemplateSave.bind(this)
    this.handleDetailChange = this.handleDetailChange.bind(this)
    this.onClickOpenTemplate = this.onClickOpenTemplate.bind(this)
    this.handleTabChange = this.handleTabChange.bind(this)
    this.handleChange2 = this.handleChange2.bind(this)
    this.onFocus = this.onFocus.bind(this)
    this.onClickBody = this.onClickBody.bind(this)
    this.handleBlur = this.handleBlur.bind(this)

    // this.parentRef = React.createRef()
    this.editorInstanceRef = React.createRef();

  }

  handleEditorReady = (editor) => {
    this.editorInstanceRef.current = editor;
  };

  componentDidMount() {
    // EventTargetに全てのClick eventをHandlingできるように登録する
    document.addEventListener('click', this.handleClickEvent)
  }

  componentWillUnmount() {
    // click eventがeventListenerに登録されたままになってしまうのでUnmount時にremoveする
    document.removeEventListener('click', this.handleClickEvent)
  }




  onDrop = (files) => {
    console.log(files)

    let sendfile = [];
    sendfile.file = files;
    sendfile.id = this.props.send_mail_details.id;
    this.props.add_mail_file(sendfile)
  };



  toggle() {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  togglemypage() {
    this.setState(prevState => ({
      modalmypage: !prevState.modalmypage,

    }));
  }

  handleClickEvent(event) {
    if (
      this.modalRef &&
      this.modalRef.current &&
      !this.modalRef.current.contains(event.target)
    ) {
      // ref内にクリックされたeventのDOMが含まれているかを確認する
      this.setState({
        modalmypage: false,

      });
    }

    if (
      this.modaltemplateRef &&
      this.modaltemplateRef.current &&
      !this.modaltemplateRef.current.contains(event.target)
    ) {
      // ref内にクリックされたeventのDOMが含まれているかを確認する
      this.setState({
        modalTemplateSelect: false,
      });
    }


  }

  togglemypageclose() {

    this.setState({
      modalmypage: false,

    });

  }
  toggleTemplateSelectClose() {

    this.setState({
      modalTemplateSelect: false,

    });

  }
  toggleselectclose() {
    this.setState({
      modalTemplateSelect: false,

    });
    this.setState({
      modalmypage: false,

    });
  }

  toggleselect = (id) => {

    this.setState(prevState => ({
      modalselect: !prevState.modalselect,
      mailid: id
    }));
  }

  togglesearch() {

    this.setState(prevState => ({
      modalsearch: !prevState.modalsearch
    }));
    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)



  }

  togglemap() {
    this.props.change_fromMapOpen(2)
    this.setState(prevState => ({
      modalmap: !prevState.modalmap
    }));
    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
  }
  toggleestimates() {

    this.setState(prevState => ({
      modalestimates: !prevState.modalestimates
    }));
    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
  }

  toggleTemplateSelect() {
    this.setState(prevState => ({
      modalTemplateSelect: !prevState.modalTemplateSelect
    }));

  }
  toggleNoSubject() {
    this.setState(prevState => ({
      modalNoSubject: !prevState.modalNoSubject
    }));

  }

  togglemismatchAddress() {
    this.setState(prevState => ({
      modalmismatchAddress: !prevState.modalmismatchAddress
    }));

  }
  save = (sendconfirm) => {

    if (sendconfirm === false && (this.props.send_mail_details.subject === "" || this.props.send_mail_details.subject === null)) {

      this.setState({
        modalNoSubject: true
      });
    } else if (sendconfirm === false && (this.props.send_mail_details.toMailall !== this.props.customerdetail.mail1)) {

      this.setState({
        modalmismatchAddress: true
      });
    } else {
      let insertdata = {};
      insertdata.FromMailAddress = "monthly@globaljuken.co.jp";
      insertdata.ToMailAddress = "globalc71@gmail.com";
      insertdata.FromName = "monthly@globaljuken.co.jp";
      insertdata.ToName = "ooshika@global-center.co.jp";
      insertdata.ToName = "ooshika@global-center.co.jp";
      insertdata.Subject = "ooshika@global-center.co.jp";
      insertdata.BodyAll = "globalc71@gmail.com";


      let send_mail_details = {...this.props.send_mail_details}
// alert(this.props.send_mail_details.sendType)
      if(parseInt(this.props.send_mail_details.sendType) == 0){

        // let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];
  
        // send_mail_details.html = editor.getData()
        // send_mail_details.htmlUtf8 = editor.getData()



        if (this.editorInstanceRef.current) {
      
          const editor = this.editorInstanceRef.current;

          send_mail_details.html = editor.getData().replace(/<\/?figure[^>]*>/g, '');;         // <figure class="table">を削除  // </figure>を削除
          send_mail_details.htmlUtf8 = editor.getData().replace(/<\/?figure[^>]*>/g, '');;         // <figure class="table">を削除  // </figure>を削除

        }

      }
      send_mail_details.html=send_mail_details.html.replace(/<\/?figure[^>]*>/g, '');
      send_mail_details.htmlUtf8=send_mail_details.htmlUtf8.replace(/<\/?figure[^>]*>/g, '');
    
      this.props.send_mail(send_mail_details)
      // this.props.send_mail(this.props.send_mail_details)

      this.setState({
        modalNoSubject: false
      });

      this.setState({
        modalmismatchAddress: false
      });
    }

  };


  handlefrommail(state) {
    this.props.send_mail_details.fromMail = state.target.value;


  }


  handleChange2(state) {
    console.log(state)
    // alert(state.target.name)
    // alert(state.target.value)
    console.log(state.target.name)
    console.log(state.target.value)
    let send_mail_details = this.props.send_mail_details;
    // alert(state.target.value)
    send_mail_details[state.target.name] = state.target.value;
    if(state.target.id == "bodyUtf8"){
      send_mail_details["body"] = state.target.value

      if(state.target.name == "bodyUtf8"){
        let selectionStart = document.getElementById('bodyUtf8').selectionStart
        // alert(selectionStart)
        this.setState({
          selectionStart: selectionStart,
        });
      }
    }

    this.props.change_send_mail_details(send_mail_details)
    this.setState({
      refresh: !this.state.refresh,
    });

  }

  onClickBody(state) {

    if(state.target.id == "bodyUtf8"){
        let selectionStart = document.getElementById('bodyUtf8').selectionStart
        // alert(selectionStart)
        this.setState({
          selectionStart: selectionStart,
        });
    }


  }

  onFocus(state) {

    this.props.change_draggable(2)


  }

  handleBlur(state) {
    
    let send_mail_details = this.props.send_mail_details;
    // alert(send_mail_details.tab)
    // //ボディが変更されたときが分かるように。
    this.props.update_send_mail_editor_state(send_mail_details)
    this.setState({
      refresh: !this.state.refresh,
    });

    this.props.change_draggable(1)
  }

  handleChange(state) {
    console.log(state)
    // alert(state.target.name)
    // alert(state.target.value)
    console.log(state.target.name)
    console.log(state.target.value)
    let send_mail_details = {...this.props.send_mail_details};
    // alert(state.target.value)
    send_mail_details[state.target.name] = state.target.value;
    // if(state.target.name == "body"){
    //   send_mail_details.tab = parseInt(state.target.value) == 0 || parseInt(state.target.value) == 2 ? 0 :1
    // }
    if(state.target.name == "sendType"){
      send_mail_details.bodyChange=1//形式が変わったとき

      this.props.change_mail_tab_change_loading(true)
      
    }
    
    this.props.change_send_mail_details(send_mail_details)
    console.log("メール",send_mail_details)
    

    clearTimeout(timer);


    timer = setTimeout(function () {
      console.log("send_mail_details", send_mail_details)
      // alert(send_mail_details[state.target.name])
      this.props.update_send_mail_editor_state(send_mail_details)

    }.bind(this), 1500);
    // this.forceUpdate();
  }

  // handleEditorChange(state){alert()
  //   // console.log(state.target.name)
  //   // console.log(state.target.value)
  //   let send_mail_details = this.props.send_mail_details;
  //   send_mail_details.body=state;
  //   this.props.change_send_mail_details(send_mail_details)
  //   // clearTimeout(timer);


  // }


  // handleEditorSave(state){


  //   // clearTimeout(timer);


  //       // timer = setTimeout(function () {
  // //  if(this.props.mail_new>=1){

  //       // }
  //       // }.bind(this), 5000);

  // }

  downloadfile(e, file) {
    e.stopPropagation();

    this.props.get_mail_file(file)
    // alert(state)
  }
  deletefile(e, file) {
    e.stopPropagation();
    this.props.delete_mail_file(file.id)
    // alert(state)
  }

  selectTatemono = (tatemonoId, roomId) => {
    // alert(this.props.responseid)
    this.props.add_mail_room({ window: window, id: 131, tatemonoId: tatemonoId, roomId: roomId, rentResponseId: this.props.responseid })
    // this.props.togglesearch();
  }

  delete_draft() {

    // alert(this.props.send_mail_details.id)
    let send_mail_details = this.props.send_mail_details;
    send_mail_details.isDeleted = true;
    console.log(send_mail_details)

    this.props.delete_draft(send_mail_details)
    this.setState(({ modalDelete: false }));
    // this.props.delete_draft(this.props.send_mail_details.id)
  }
  toggleDelete() {
    this.setState(prevState => ({
      modalDelete: !prevState.modalDelete
    }));

  }

  changesize = () => {
    this.props.change_editor_height(this.props.editor_height === 600 ? window.innerHeight : 600)
    this.props.change_editor_width(this.props.editor_width === 700 ? 1000 : 700)
    // let editor = window.CKEDITOR.instances[Object.keys(window.CKEDITOR.instances)[0]];

    // if (this.props.editor_height === 600) {

    //   // editor.resize('100%', '420', true)
    //   if(editor){
    //     editor.resize('100%', window.innerHeight - 300, true)
    //   }
      
    // } else {
    //   if(editor){
    //     editor.resize('100%', '320', true)
    //   }
    // }
  }

  toggletooltip() {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen
    });
  }

  handleClickMailTemplateSelect(template_id, uwagaki){

    let mail_template_details = this.props.mail_template_details
    let send_mail_details = this.props.send_mail_details

  
    if(!uwagaki){
      if(mail_template_details.tab == 0){//htmlが選ばれてるとき
        if(send_mail_details.tab == 1){
            alert("挿入先もHTML形式を選択してください。")
            return
        }
      }else{//text
          if(send_mail_details.tab == 0){
              alert("挿入先もテキスト形式を選択してください。")
              return
          }
          if(!this.state.selectionStart){
              alert("挿入場所が選択されていません。")
              return
          }
          // alert(this.state.selectionStart)
      }
    }
    

    if (!uwagaki && window.editorInstance2) {

        this.props.insert_mail_template_replace_and_set({template_id: template_id, uwagaki:uwagaki, window:window, selectionStart:this.state.selectionStart})
      
    }else{
      
      this.props.insert_mail_template_replace_and_set({template_id: template_id, uwagaki:uwagaki, window:window, selectionStart:this.state.selectionStart, mail_data:""})

    }
    
    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
    this.setState({ "modalNewTemplateSelect": false })
  }

  handleTemplateClose(){
    this.setState({ "modalNewTemplateSelect": false })
  }
  
  handleClickMailTemplateSave(){
    if(this.props.mail_template_details.id){
      // alert("update", this.props.mail_template_details.id)
      this.props.update_mail_template_details();
    }else{
      // alert("insert")
      this.props.insert_mail_template_details(this.props.mail_template_details)
    }
    this.setState({ "modalTemplateSave": true })
  }
  
  handleDetailChange = (event) => {
    // alert(event.target.name)
    // alert(event.target.name)
    // alert(event.target.value)
    let mail_template_details = this.props.mail_template_details

    mail_template_details.is_text = mail_template_details.is_text == 0 ? 1 : 0
// alert(mail_template_details.is_text)
    this.props.change_mail_template_details(mail_template_details)

    this.setState({ reflash: !this.state.reflash });
  }
  
  onClickOpenTemplate(){

    let mail_template_form = this.props.mail_template_form
    mail_template_form.category = this.props.mail_template_category_default
    this.props.change_mail_template_form(mail_template_form)

    this.setState({ "modalNewTemplateSelect": true });
    this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1)
  }

  handleTabChange = (event, newValue) => {

    let send_mail_details = {...this.props.send_mail_details}
    send_mail_details.tab = newValue
    this.props.change_send_mail_details(send_mail_details)

    // this.setState({ tab: newValue });
};

handleInSoto = () => {
  // const data = '<div>aaaa</div>'
  // setEditorData(data);

  if (window.editorInstance) {
    window.editorInstance.setData('<p>新しいコンテンツが挿入されました。</p>');
}

};

  render() {

    // const files = this.props.send_files.map(file => (
    //     <li className="filename" key={file.name}>
    //       {file.name} <span className="fas fa-times float-right"/>

    //     </li>

    //   ));
    console.log(this.props.mail_detail_files)

    console.log(this.props.getmails)
    return (
      <div >

        {this.state.modalNoSubject ? <div style={{ position: "absolute", zIndex: "3000", background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
          <div style={{ margin: "auto", marginTop: 200, borderRadius: "10px", width: "300px", height: "250px", padding: "20px", background: "#FFF" }}>
            件名がありませんが送信しますか？
            <Button color="primary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.save(true)}>送信する</Button>
            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={this.toggleNoSubject}>送信しない</Button>
          </div>

        </div> : ""}


        {this.state.modalmismatchAddress ? <div style={{ position: "absolute", zIndex: "3000", background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
          <div style={{ margin: "auto", marginTop: 200, borderRadius: "10px", width: "300px", height: "250px", padding: "20px", background: "#FFF" }}>
            現在開いている反響とメールアドレスが違いますが送信しますか？
            <Button color="primary" style={{ margin: "20px", marginTop: "50px" }} onClick={() => this.save(true)}>送信する</Button>
            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={this.togglemismatchAddress}>送信しない</Button>
          </div>

        </div> : ""}


        {this.props.send_mail_loading ? <div style={{ position: "absolute", zIndex: "3000", background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
          <div style={{ marginTop: "20%" }}>
            <Loading />
          </div>
        </div> : ""}
        {this.props.send_mail_alert !== 0 ? <div style={{ position: "absolute", zIndex: "3000", background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
          <div className={this.props.send_mail_alert === 1 ? "alert alert-success" : (this.props.send_mail_alert === 4 ? "alert alert-warning" : "alert alert-danger")} role="alert" style={{ margin: "auto", marginTop: 200, marginTop: "20%", width: "300px" }}>
            {this.props.send_mail_alert === 1 ? <span>送信されました。</span> : ""}
            {this.props.send_mail_alert === 2 ? <span>送信に失敗しました。</span> : ""}
            {this.props.send_mail_alert === 3 ? <span>削除しました。</span> : ""}
            {this.props.send_mail_alert === 4 ? <span>削除失敗しました。</span> : ""}
          </div>
        </div> : ""}

        {this.state.modalDelete ? <div style={{ position: "absolute", zIndex: "3000", background: "rgba(45,45,45, 0.5)", width: "100%", height: "100%" }}>
          <div style={{ margin: "auto", marginTop: 200, borderRadius: "10px", width: "300px", height: "250px", padding: "20px", background: "#FFF" }}>
            下書きを削除しますか？
            <Button color="danger" style={{ margin: "20px", marginTop: "50px" }} onClick={this.delete_draft}>削除する</Button>
            <Button color="secondary" style={{ margin: "20px", marginTop: "50px" }} onClick={this.toggleDelete}>キャンセル</Button>
          </div>

        </div> : ""}



        <div id="mailheader"><span>新規メッセージ</span>

          <span onClick={() => {this.props.update_send_mail_editor_state(this.props.send_mail_details); this.props.close_mail()}} className="fas fa-times float-right" />
          <span onClick={this.changesize} className="fas fa-arrows-alt-h float-right" />
          <span onClick={() => this.props.change_mail_minimum(1)} className="far fa-window-minimize float-right" />
        </div>




        <div className="edit" style={{ padding: 0 }}>
          <MailEditHeaderComponent

            handleChange={this.handleChange}
            send_edit_contents={this.props.send_edit_contents}
            change_send_edit_contents={this.props.change_send_edit_contents}
            send_mail_details={this.props.send_mail_details}
            change_send_mail_cc_disp={this.props.change_send_mail_cc_disp}
            change_send_mail_bcc_disp={this.props.change_send_mail_bcc_disp}
            send_mail_address_disp={this.props.send_mail_address_disp}
            send_mail_cc_disp={this.props.send_mail_cc_disp}
            send_mail_bcc_disp={this.props.send_mail_bcc_disp} /></div>
        <div className={"col-sm-12"} id="mail_editor_contents" style={{borderTop: "solid #dcdcdc 1px"}}>


        {this.props.mail_tab_change_loading ? <Loading />:
        <>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs className='mail_tab' value={this.props.send_mail_details.tab} onChange={this.handleTabChange} aria-label="basic tabs example" style={{minHeight: "30px", height: "30px"}} 

              variant="scrollable"
              scrollButtons="auto"
          >
              {parseInt(this.props.send_mail_details.sendType) == 0 || parseInt(this.props.send_mail_details.sendType) == 2 ? <Tab label="HTML形式"  value={0} />:null}
              {parseInt(this.props.send_mail_details.sendType) == 1 || parseInt(this.props.send_mail_details.sendType) == 2 ? <Tab label="テキスト形式" value={1} />:null}

          </Tabs>
        </Box>
        
          {this.props.send_mail_details.tab == 0 ? <MailCkEditorComponent
            onEditorReady={this.handleEditorReady}
            // props = {this.props}
            // handleEditorChange={this.handleEditorChange} 
            // handleEditorSave={this.handleEditorSave} 
            change_send_edit_contents={this.props.change_send_edit_contents}
            togglemypageclose={this.togglemypageclose}
            toggleTemplateSelectClose={this.toggleTemplateSelectClose}
            toggleselectclose={this.toggleselectclose}

            // editor_textarea_height={312 + this.state.textareaHeight}
            editor_textarea_height={this.props.editor_height === window.innerHeight ? window.innerHeight - 300 : 340}
            editorState={this.props.editorState}
            send_mail_details={this.props.send_mail_details} />

          :
            <TextField
              style={{marginTop:3}}
              fullWidth
              // onChange={this.handleDetailChange}
              // onChange={(e) => { this.setState({ "template_name": e.target.value }); }}
              onChange={this.handleChange2}
              onBlur={this.handleBlur}
              onClick={this.onClickBody}
              onFocus={this.onFocus}
              value={this.props.send_mail_details.bodyUtf8 ? this.props.send_mail_details.bodyUtf8 : ""}
              margin="dense"
              // label="内容"
              name="bodyUtf8"
              id="bodyUtf8"
              variant="outlined"
              multiline
              // maxRows={30}
              maxRows={this.props.editor_height === window.innerHeight ? 30 : 15}
              minRows={30}
              InputLabelProps={{ shrink: true }}
            />
          }
        </>}

          <div className={"col-sm-6 float-left"} style={{ padding: "3px", height: "75px" }}>
            {this.props.get_mail_detail_files_loading === 1 ? <button type="button" className="btn btn-primary" id="sendbutton"
              disabled
            >送信</button> :
              <button type="button" className="btn btn-primary" id="sendbutton"
                onClick={() => this.save(false)}
              >送信</button>}


            <button type="button" className="btn btn-sm btn-success float-left"
              onClick={this.togglesearch} style={{ margin: "2px" }}>物件検索</button>

            <button type="button" className="btn btn-sm btn-success float-left"
              onClick={this.togglemap} style={{ margin: "2px" }}>地図検索</button>

            <button type="button" className="btn btn-sm btn-warning float-left"
              onClick={this.toggleestimates} style={{ margin: "2px" }}>見積</button>

            <span ref={this.modaltemplateRef} >
              {this.state.modalTemplateSelect ? <div style={{ position: "absolute", bottom: "30px", filter: "drop-shadow(10px 10px 10px rgba(0,0,0,0.6))" }}>
                <TemplateListComponent toggleTemplateSelectClose={this.toggleTemplateSelectClose} /></div> : ""}
              <button type="button" className="btn btn-sm btn-success float-left" onClick={(e) => this.onClickOpenTemplate()} style={{ margin: "2px" }}>テンプレート</button>
              {/* <button type="button" className="btn btn-sm btn-success float-left"　onClick={this.toggleTemplateSelect} style={{ margin: "2px" }}>テンプレート</button> */}
            </span>

              <Modal isOpen={this.state.modalNewTemplateSelect } zIndex={1051} toggle={(e) => { this.setState({ "modalNewTemplateSelect": false });this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1) }} size="xxl" fade={false}>
                <ModalHeader toggle={(e) => { this.setState({ "modalNewTemplateSelect": false });this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1) }}>
                メールテンプレート
                  {/* <IconButton aria-label="close" onClick={} yystyle={{float: 'right'}}>
                    <CloseIcon />
                  </IconButton> */}
                </ModalHeader>
                <MailTemplateComponent handleTemplateClose={this.handleTemplateClose} />
                <ModalFooter>
                <div style={{position:"absolute", left:"10px", bottom:"10px"}}>
                  {/* <ButtonMui variant="contained" onClick={()=>this.handleDetailChange()} style={{marginRight:5}}>テキスト切替</ButtonMui> */}
                  {/* <ButtonMui variant="outlined" color="success" style={{marginRight:5}} onClick={()=>{this.props.insert_mail_template_details(this.props.mail_template_details);this.setState({ "modalTemplateSave": true })}} >別名で保存</ButtonMui>
                  <ButtonMui variant="contained" color="success" onClick={()=>this.handleClickMailTemplateSave()} >保存</ButtonMui> */}
                  </div>
                  {/* <Button variant="contained" onClick={this.handleInSoto}>外からin</Button> */}
                  <ButtonMui startIcon={<PersonAddAlt1Icon />} variant="contained" color="primary" onClick={()=>this.handleClickMailTemplateSelect(355, 0)} style={{marginRight:5}} disabled={this.props.send_mail_details.tab==1}>スタッフ紹介を追加する</ButtonMui>
                  <ButtonMui startIcon={<PlaylistAddCheckIcon />} variant="contained" color="primary" disabled={this.props.mail_template_details.id > 0 ? false : true} onClick={()=>this.handleClickMailTemplateSelect(this.props.mail_template_details.id, 1)} style={{marginRight:5}}>メールに上書きする</ButtonMui>
                  <ButtonMui startIcon={<PlaylistAddIcon />} variant="contained" color="primary" disabled={this.props.mail_template_details.id > 0 ? false : true} onClick={()=>this.handleClickMailTemplateSelect(this.props.mail_template_details.id, 0)}>メールに追加する</ButtonMui>
                </ModalFooter>
              </Modal>

              
                {/* <button type="button" className="btn btn-sm btn-success float-right" onClick={(e) => { this.setState({ "modalNewTemplateSelect": true });this.props.draggable === 1 ? this.props.change_draggable(2) : this.props.change_draggable(1) }}>テンプレート(準備中)</button> */}
              
              <Dialog open={this.state.modalTemplateSave } style={{zIndex:1052}} onClose={(e) => { this.setState({ "modalTemplateSave": false });}}>
                <DialogTitle>
                  保存しました。
                </DialogTitle>
                {/* <DialogActions>
                  <ButtonMui variant="contained" color="inherit" onClick={()=>this.setState({ "modalTemplateSave": false })}>閉じる</ButtonMui>
                </DialogActions> */}
              </Dialog>
              
              {/* <button type="button" className="btn btn-sm btn-success float-left"
                onClick={(e) => { this.setState({ "modalNewTemplateSelect": true }) }} style={{ margin: "2px" }}>新テンプレート</button> */}
            {/* <button type="button" className="btn btn-sm btn-info float-left"  style={{margin:"2px"}}
        onClick={()=>this.props.add_mypage_link({window:window,id:179,rentResponseId:this.props.responseid})}>マイページ</button> */}
            <span ref={this.modalRef} >
              {this.state.modalmypage ? <div style={{ position: "absolute", bottom: "30px", filter: "drop-shadow(10px 10px 10px rgba(0,0,0,0.6))" }}>
                <MyPagesComponent togglemypageclose={this.togglemypageclose} /></div> : ""}
              <button type="button" className="btn btn-sm btn-info float-left" style={{ margin: "2px" }}
                onClick={() => this.togglemypage()}>マイページ</button></span>
          </div>


          <div className="col-sm-6 float-left">

            <div className=" float-left" style={{ overflowY: "scroll", height: "75px", background: "#555", width: "85%" }}>
              {this.props.get_mail_detail_files_loading === 1 ? <Loading /> : <Dropzone

                // noClick={true}
                onDrop={this.onDrop}>
                {({ getRootProps, getInputProps }) => (

                  <div {...getRootProps({ className: 'dropzone' })} style={{ background: "#f5f5f5" }}>

                    <input {...getInputProps()} />

                    <div style={{ width: 320 }}>
                      <p>ファイル添付　クリックまたはドラッグ</p>
                      <ul style={{ padding: 0, marginTop: 3 }}  >
                        {this.props.mail_detail_files.map(file => (
                          <li className="filename" key={file.id} >
                            <span onClick={(e) => this.downloadfile(e, file)}>{file.name}</span> <span onClick={(e) => this.deletefile(e, file)} className="fas fa-times float-right" />

                          </li>))}

                      </ul>
                    </div>

                  </div>

                )}
              </Dropzone>}

            </div>
            <Tooltip title="下書き削除" id="mailDeleteTooltip">
              <IconButton
                aria-label="delete float-righ"
                style={{ fontSize: "18px", color: "#444" }}
                onClick={this.toggleDelete}
                size="large">
                <DeleteIcon className="" />
              </IconButton>
            </Tooltip>
            {/* <i class="fas fa-trash-alt float-right" onClick={this.toggleDelete} style={{fontSize:"18px",color:"#444",margin:"3px"}} id="TooltipDelete"></i> */}
            {/* <Tooltip title="Delete" >aa</Tooltip>  */}
            {/* <Tooltip placement="top"  isOpen={this.state.tooltipOpen} target="TooltipDelete"  toggle={this.toggletooltip} style={{zIndex:3050}}> */}
            {/* 下書き削除 */}
            {/* </Tooltip> */}
          </div>


          {/* <span className="fas fa-times"></span>
                <button type="button" className="btn btn-sm btn-danger" onClick={this.toggleTemplateSelect}>削除</button>
                  </div> */}
        </div>

        {/* <Draggable enableUserSelectHack={false} disabled={this.props.draggable===2?false:true}> */}
        <Modal isOpen={this.state.modalsearch} zIndex={3000} toggle={this.modalsearch} size="xxl" fade={false}>
          <ModalHeader toggle={this.togglesearch}>物件検索</ModalHeader>
          {/* <ModalBody> */}

          <RoomSearchListComponent selectTatemono={this.selectTatemono} rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} togglesearch={this.togglesearch} mail={true} />

          {/* </ModalBody> */}

          {/* <ModalFooter>
    
          </ModalFooter> */}

        </Modal>
        {/* </Draggable> */}

        <Modal isOpen={this.state.modalmap} zIndex={3000} toggle={this.togglemap} size="xxl" fade={false}>
          <ModalHeader toggle={this.togglemap}>地図検索</ModalHeader>
          <MapSearchComponent rent_response_id={this.props.responseid} responsedetail={this.props.responsedetail} selectsheet={this.selectsheet} mail={true} />

        </Modal>












        {/* <Modal isOpen={this.state.modalTemplateSelect}  zIndex={3000} toggle={this.toggleTemplateSelect} size="sm" fade={false}>

                        <MailTemplateListSelectComponent toggleTemplateSelect={this.toggleTemplateSelect}/>

                        </Modal> */}


        <Modal isOpen={this.state.modalestimates} zIndex={3000} toggle={this.toggleestimates} size="lg" fade={false}>
          <ModalHeader toggle={this.toggleestimates}>見積</ModalHeader>
          <ModalBody >

            <EstimateListComponent rentResponseId={this.props.responseid} />
          </ModalBody >
        </Modal>


        {/* <Modal isOpen={this.state.modalmypage} zIndex={3000} toggle={this.togglemypage} size="sm" fade={false}>
                        <ModalHeader toggle={this.togglemypage}>マイページ選択</ModalHeader>
                        <MyPagesComponent />
                
                </Modal> */}



      </div>
    );

  }
}




//コンテナ
const mapStateToProps = state => {
  return {

    send_mail_details: state.mailReducer.send_mail_details,
    send_mail_editor_state: state.mailReducer.send_mail_editor_state,
    send_mail_cc_disp: state.mailReducer.send_mail_cc_disp,
    send_mail_bcc_disp: state.mailReducer.send_mail_bcc_disp,
    send_mail_address_disp: state.mailReducer.send_mail_address_disp,

    mail_detail_files: state.mailReducer.mail_detail_files,
    send_edit_contents: state.mailReducer.send_edit_contents,
    mail_new: state.mailReducer.mail_new,
    mail_minimum: state.mailReducer.mail_minimum,
    responseid: state.responsesReducer.responseid,
    customerdetail: state.customersReducer.customerdetail,
    editor_height: state.mailReducer.editor_height,
    editor_width: state.mailReducer.editor_width,
    send_mail_loading: state.mailReducer.send_mail_loading,
    send_mail_alert: state.mailReducer.send_mail_alert,
    get_mail_detail_files_loading: state.mailReducer.get_mail_detail_files_loading,
    get_mail_details_loading: state.mailReducer.get_mail_details_loading,
    draggable: state.masterReducer.draggable,

    mail_template_details: state.mailtemplate.mail_template_details,
    mail_template_form: state.mailtemplate.mail_template_form,
    mail_template_category_default: state.mailtemplate.mail_template_category_default,

    mail_tab_change_loading: state.mailReducer.mail_tab_change_loading,
  }
}
function mapDispatchToProps(dispatch) {
  return {
    // get_mails(state) {
    //   dispatch(Mail.get_mails(state))
    // },
    add_mail_file(state) {
      dispatch(Mail.add_mail_file(state))
    },
    change_mail_new(state) {
      dispatch(Mail.change_mail_new(state))
    },
    change_mail_new_success(state) {
      dispatch(Mail.change_mail_new_success(state))
    },
    change_mail_minimum(state) {
      dispatch(Mail.change_mail_minimum(state))
    },

    change_send_mail_cc_disp(state) {
      dispatch(Mail.change_send_mail_cc_disp(state))
    }, change_send_mail_bcc_disp(state) {
      dispatch(Mail.change_send_mail_bcc_disp(state))
    },
    send_mail(state) {
      dispatch(Mail.send_mail(state))
    },
    change_send_edit_contents(state) {
      dispatch(Mail.change_send_edit_contents(state))
    }, change_send_to_addresses(state) {
      dispatch(Mail.change_send_to_addresses(state))
    },
    change_send_mail_details(state) {
      dispatch(Mail.change_send_mail_details(state))
    },
    update_send_mail_editor_state(state) {
      dispatch(Mail.update_send_mail_editor_state(state))
    },
    add_mail_room(state) {
      dispatch(Mail.add_mail_room(state))
    },
    change_editor_height(state) {
      dispatch(Mail.change_editor_height(state))
    },
    change_editor_width(state) {
      dispatch(Mail.change_editor_width(state))
    },
    get_mail_file(state) {
      dispatch(Mail.get_mail_file(state))
    },
    delete_mail_file(state) {
      dispatch(Mail.delete_mail_file(state))
    },
    add_mypage_link(state) {
      dispatch(Mail.add_mypage_link(state))
    },
    delete_draft(state) {
      dispatch(Mail.delete_draft(state))
    },
    change_draggable(state) {
      dispatch(Master.change_draggable(state))
    },
    change_fromMapOpen(state) {
      dispatch(MapSearch.change_fromMapOpen(state))
    },
    close_mail(state) {
      dispatch(Mail.close_mail(state))
    },
    get_mail_rent_introduction_header_list(state) {
      dispatch(Mail.get_mail_rent_introduction_header_list(state))
    },
    get_mail_template_select_list(state) {
      dispatch(Mail.get_mail_template_select_list(state))
    },
    update_mail_template_details(state) {
      dispatch(MailTemplate.update_mail_template_details(state))
    },
    insert_mail_template_details(state) {
      dispatch(MailTemplate.insert_mail_template_details(state))
    },
    insert_mail_template_replace_and_set(state) {
      dispatch(MailTemplate.insert_mail_template_replace_and_set(state))
    },
    change_mail_template_details(state) {
      dispatch(MailTemplate.change_mail_template_details(state))
    },
    change_mail_template_form(state) {
      dispatch(MailTemplate.change_mail_template_form(state))
    },
    change_mail_tab_change_loading(state) {
      dispatch(Mail.change_mail_tab_change_loading(state))
    },
  };
}


export default connect(mapStateToProps, mapDispatchToProps)(MailEditorComponent);
