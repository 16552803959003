import React, { Component } from 'react';

import { connect } from 'react-redux';

import moment from 'moment'
import * as Responses from './Saga/Responses';

import '../../Css/Table.css';
import Button from '@mui/material/Button';
import DownloadIcon from '@mui/icons-material/Download';
import LoadingButton from '@mui/lab/LoadingButton';

class ResponseInfoDisp extends Component {

    constructor(props) {
        super(props);

        this.state = {

            startDate: new Date(),

        };
    }

    componentDidMount() {



    }



    render() {
        const userid = this.props.responsedetail.user_id;
        const user = this.props.users.filter(function (user) {
            return user.id === userid;
        });
        const primaryUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.primary_user_id
        }, this);
        const comingUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.coming_user_id
        }, this);
        const goingUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.going_user_id
        }, this);
        const webUser = this.props.users.filter(function (user) {
            return user.id === this.props.responsedetail.web_user_id
        }, this);

        const firstContactSection = this.props.shops.filter(function (shop) {
            return shop.tenpoNo === this.props.responsedetail.first_contact_section_id
        }, this);
        const sectionId = this.props.responsedetail.section_id;
        const shop = this.props.shops.filter(function (shops) {
            return shops.tenpoNo === sectionId;
        });
        const rentmmediumid = this.props.responsedetail.rent_m_medium_id;

        const rentmmedium = this.props.rentMMedeas.filter(function (rentmmedium) {
            return rentmmedium.id === rentmmediumid;
        });

        const rentmagentid = this.props.responsedetail.rent_m_agent_id;
        const rentmagent = this.props.rent_m_agents.length > 0 && rentmagentid > 0 ? this.props.rent_m_agents.filter(a=>a.id == rentmagentid) : []
        // let rentmagent = []
        // if(this.props.rent_m_agents.length > 0){
        //     rentmagent = this.props.rent_m_agents.filter(function (rentmagent) {
        //         return rentmagent.id === rentmagentid;
        //     });
        // }
        // const rentmagent = this.props.rentAgents.filter(function (rentmagent) {
        //     return rentmagent.rentMAgentId === rentmagentid;
        // });

        const rentmreasonid = this.props.responsedetail.rent_m_reason_id;
        const rentmreason = this.props.rentMReasons.filter(function (rentmreason) {
            return rentmreason.id === rentmreasonid;
        });


        console.log(this.props.responsedetail)

        return (

            <div>
                <table className='basicList'>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody>

                        <tr >
                            <th>反響日</th>
                            <td colSpan={3}>{this.props.responsedetail.date !== null ? moment(this.props.responsedetail.date).format("YYYY/MM/DD") : ""}
   
                                {/* <Button size="small" startIcon={<DownloadIcon />} style={{ color: "white", backgroundColor: "#28a745", float: 'right', marginLeft:3 }} variant="contained" color="primary" onClick={() => this.props.download_response_preparation_sheet()}>
                                    準備シート
                                </Button> */}
                                {/* <LoadingButton
                                    size="small"
                                    color="secondary"
                                    style={{ color: "white", backgroundColor: "#28a745", float: 'right', marginLeft:3 }}
                                    onClick={() => this.props.download_response_preparation_sheet()}
                                    loading={this.props.response_preparation_sheet_loading}
                                    loadingPosition="start"
                                    startIcon={<DownloadIcon />}
                                    variant="contained"
                                >
                                    <span>準備シート</span>
                                </LoadingButton> */}
                                <Button size="small" style={{ display: (this.props.notedit === 1) ? 'none' : '', float: 'right' }} variant="contained" color="primary" onClick={() => this.props.open_response_edit(1)}>
                                    編集
                                </Button>
                                
                                
                            </td>
                        </tr>

                        <tr style={{ display: (String(this.props.responsedetail.rent_m_way_id) === "3") ? '' : 'none' }}>
                            <th>代行会社</th>
                            <td colSpan="3">
                                {rentmagent[0] === undefined ? "" : rentmagent[0].name}
                                {rentmagent[0] && rentmagent[0].id == 5 && this.props.responsedetail.agent_etc ? " "+ this.props.responsedetail.agent_etc : ""}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.rent_m_way_id !== null && this.props.responsedetail.rent_m_way_id !== "") ? '' : 'none' }}>
                            <th>反響経路</th>
                            <td >
                                {String(this.props.responsedetail.rent_m_way_id) === "1" ? "TEL" : String(this.props.responsedetail.rent_m_way_id) === "2" ? "メール" : String(this.props.responsedetail.rent_m_way_id) === "3" ? "社宅斡旋" : String(this.props.responsedetail.rent_m_way_id) === "5" ? "飛込" : String(this.props.responsedetail.rent_m_way_id) === "6" ? "LINE" : String(this.props.responsedetail.rent_m_way_id) === "7" ? "SNS" : ""}
                                {this.props.responsedetail.rent_m_way_id === 2 ? "(" + (this.props.responsedetail.mail_is_tel ? "TELあり" : "TEL無") + ")" : ""}
                            </td>
                            <th>メディア</th>
                            <td >
                                {rentmmedium[0] === undefined ? "" : rentmmedium[0].name}
                            </td>

                        </tr>
                    </tbody>
                </table>

                <table className='basicList' style={{marginTop:10}}>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody>

                        <tr style={{ display: (this.props.responsedetail.tenpo_no !== null && this.props.responsedetail.tenpo_no !== "") ? '' : 'none' }}>
                            <th>店舗</th>
                            <td >
                                {shop[0] === undefined ? "" : shop[0].tenpoName}
                            </td>

                            <th>初回対応部署</th>
                            <td >
                                {firstContactSection[0] === undefined ? "" : firstContactSection[0].tenpoName}
                            </td>
                        </tr>
                        <tr >
                            <th>反響担当</th>
                            <td >
                                {user[0] === undefined ? "" : user[0].userNameAll}
                            </td>
                            {/* <th>追客担当</th>
                            <td >
                                {primaryUser[0] === undefined ? "" : primaryUser[0].userNameAll}
                            </td> */}
                            <th>来店担当</th>
                            <td >
                                {comingUser[0] === undefined ? "" : comingUser[0].userNameAll}
                            </td>
                        </tr>
                        
                        <tr >
                            <th>案内担当</th>
                            <td colSpan={3}>
                                {goingUser[0] === undefined ? "" : goingUser[0].userNameAll}
                            </td>
                            {/* <th>{this.props.user_details.amSectionId == 10 || this.props.user_details.amSectionId == 26 || this.props.user_details.amSectionId == 37 || this.props.user_details.amSectionId == 55 ? "Web課追客担当" : ""}</th> */}
                            {/* <td >
                                {this.props.user_details.amSectionId == 10 || this.props.user_details.amSectionId == 26 || this.props.user_details.amSectionId == 37 || this.props.user_details.amSectionId == 55 ? webUser[0] === undefined ? "" : webUser[0].userNameAll : ""}
                            </td> */}

                        </tr>
                    </tbody>
                </table>

                <table className='basicList' style={{marginTop:10}}>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody>

                        <tr style={{ display: (this.props.responsedetail.responsed_buildings !== null && this.props.responsedetail.responsed_buildings !== "") ? '' : 'none' }}>
                            <th>問合物件</th>
                            <td colSpan="3">{this.props.responsedetail.responsed_buildings}</td>
                        </tr>
                        <tr>
                            <th>類型</th>
                            <td>
                                {this.props.responsedetail.student_independent === 1 ? "社会人" : this.props.responsedetail.student_independent === 2 ? this.props.responsedetail.gakusei_yoyaku === 1 ? "学生（学生予約）" + (this.props.responsedetail.gakusei_yoyaku_date ? "来店日:" + moment(this.props.responsedetail.gakusei_yoyaku_date).format("YYYY/MM/DD") : "") : "学生" : ""}
                            </td>
                            <th>単身・ファミリー</th>
                            <td >
                                {this.props.responsedetail.student_independent === 1 ? this.props.responsedetail.alone_family === 1 ? "単身" : this.props.responsedetail.alone_family === 2 ? "ファミリー" : "" : ""}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.student_independent !== 2) ? 'none' : (this.props.responsedetail.rent_m_school_id !== null && this.props.responsedetail.rent_m_school_id !== 0) ? '' : 'none' }}>
                            <th>学校名</th>
                            <td colSpan="3">
                            {this.props.rent_m_schools.filter(a=> a.id == parseInt(this.props.responsedetail.rent_m_school_id)).length > 0 ? this.props.rent_m_schools.filter(a=> a.id == parseInt(this.props.responsedetail.rent_m_school_id))[0].name : ""}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.student_independent !== 2) ? 'none' : (this.props.responsedetail.highschool !== null && this.props.responsedetail.highschool !== "") ? '' : 'none' }}>
                            <th>出身校</th>
                            <td colSpan="3">
                                {this.props.responsedetail.highschool}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.student_independent !== 2) ? 'none' : (this.props.responsedetail.gakunen !== null && this.props.responsedetail.gakunen !== 0) ? '' : 'none' }}>
                            <th>学年</th>
                            <td colSpan="3">
                                {(() => {
                                    if (this.props.responsedetail.gakunen === "0") {
                                        return "-";
                                    } else if (this.props.responsedetail.gakunen === 1) {
                                        return "新入学";
                                    } else if (this.props.responsedetail.gakunen === 2) {
                                        return "1回生";
                                    } else if (this.props.responsedetail.gakunen === 3) {
                                        return "2回生";
                                    } else if (this.props.responsedetail.gakunen === 4) {
                                        return "3回生";
                                    } else if (this.props.responsedetail.gakunen === 5) {
                                        return "4回生";
                                    } else if (this.props.responsedetail.gakunen === 6) {
                                        return "院生";
                                    }
                                })()}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.student_independent === 2 && this.props.responsedetail.gakunen === 1) ? '' : 'none' }}>
                            <th>受験予定</th>
                            <td>
                                {

                                    this.props.responsedetail.jyukenyotei ? this.props.responsedetail.jyukenyotei.split(',').map(function (item) {
                                        return (
                                            <span>
                                                {item === "1" ? "推薦 " : ""}
                                                {item === "2" ? "前期 " : ""}
                                                {item === "3" ? "後期 " : ""}
                                                {item === "4" ? "一般 " : ""}
                                            </span>
                                        )
                                    }) : ""
                                }
                            </td>
                            <th>合否</th>
                            <td>
                                {(() => {
                                    if (this.props.responsedetail.gouhi === 1) {
                                        return "推薦合格";
                                    } else if (this.props.responsedetail.gouhi === 2) {
                                        return "前期合格";
                                    } else if (this.props.responsedetail.gouhi === 3) {
                                        return "後期合格";
                                    } else if (this.props.responsedetail.gouhi === 4) {
                                        return "不合格";
                                    }

                                })()}
                            </td>
                        </tr>
                        <tr style={{ display: (this.props.responsedetail.student_independent) !== 2 ? 'none' : (this.props.responsedetail.reserveroom !== null && this.props.responsedetail.reserveroom !== "") ? '' : 'none' }}>
                            <th>予約物件</th>
                            <td colSpan="3">
                                {this.props.responsedetail.reserveroom}
                            </td>
                        </tr>

                        {/* <tr>
                            <th>現住居</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_residence}
                            </td>
                        </tr>
                        <tr>
                            <th>引越し理由</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_moving_reason ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_moving_reason.length ? this.props.responsedetail.free_moving_reason.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>人物属性その他</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_person_attributes ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_person_attributes.length ? this.props.responsedetail.free_person_attributes.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>通勤通学先</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_office_school ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_office_school.length ? this.props.responsedetail.free_office_school.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>入居人数</th>
                            <td>
                                {this.props.responsedetail.free_number_of_people}
                            </td>
                            <th>間取り目安</th>
                            <td>
                                {this.props.responsedetail.free_madori}
                            </td>
                            
                        </tr>
                        <tr>
                            <th>賃料目安</th>
                            <td>
                                {this.props.responsedetail.free_rent}
                            </td>
                            <th>賃料規定あれば</th>
                            <td>
                                {this.props.responsedetail.free_rent_remarks}
                            </td>
                        </tr>
                        <tr>
                            <th>エリア目安</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_area ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_area.length ? this.props.responsedetail.free_area.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>入居時期</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_move_in_period ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_move_in_period.length ? this.props.responsedetail.free_move_in_period.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr>
                        <tr>
                            <th>補足事項</th>
                            <td colSpan={3}>
                                {this.props.responsedetail.free_remarks ? <span style={{ textAlign: "left" }} dangerouslySetInnerHTML={{ __html: this.props.responsedetail.free_remarks.length ? this.props.responsedetail.free_remarks.replace(/\r?\n/g, '<br/>') : '' }}></span>:""}
                            </td>
                        </tr> */}

                    {/* </tbody>
                </table>  


                <table className='basicList' style={{marginTop:10}}>
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <colgroup width='20%' />
                    <colgroup width='30%' />
                    <tbody> */}

                        <tr style={{ display: ((this.props.responsedetail.request_move_date !== null && this.props.responsedetail.request_move_date !== "") || (this.props.responsedetail.rent_m_reason_id !== null && this.props.responsedetail.rent_m_reason_id !== "")) ? '' : 'none' }}>
                            <th>引越時期</th>
                            <td>
                                {/* {this.props.responsedetail.request_move_date != null ? moment(this.props.responsedetail.request_move_date).format("YYYY/MM/DD") : ""} */}

                                {(() => {
                                    if (this.props.responsedetail.request_moving_time === 0) {
                                        return "-";
                                    } else if (this.props.responsedetail.request_moving_time === 1) {
                                        return "今月中";
                                    } else if (this.props.responsedetail.request_moving_time === 2) {
                                        return "来月中";
                                    } else if (this.props.responsedetail.request_moving_time === 3) {
                                        return "2ヶ月後";
                                    } else if (this.props.responsedetail.request_moving_time === 4) {
                                        return "3ヶ月後";
                                    } else if (this.props.responsedetail.request_moving_time === 5) {
                                        return "4ヶ月後";
                                    } else if (this.props.responsedetail.request_moving_time === 6) {
                                        return "5ヶ月後";
                                    } else if (this.props.responsedetail.request_moving_time === 7) {
                                        return "半年後";
                                    } else if (this.props.responsedetail.request_moving_time === 8) {
                                        return "1年以内";
                                    } else if (this.props.responsedetail.request_moving_time === 9) {
                                        return "未定いいのがあれば";
                                    }

                                })()}
                            </td>
                            <th>引越理由</th>
                            <td>
                                {rentmreason[0] === undefined ? "" : rentmreason[0].name}
                            </td>
                        </tr>
                        <tr >

                            <th>希望築年数</th>
                            <td >
                                {(this.props.responsedetail.request_year !== null && this.props.responsedetail.request_year !== 0) ? this.props.responsedetail.request_year + "年以内" : ""}
                            </td>
                            <th>希望金額</th>
                            <td >
                                {this.props.responsedetail.request_from_cost ? this.props.responsedetail.request_from_cost + "円～" : ""}

                                {this.props.responsedetail.request_to_cost ? this.props.responsedetail.request_to_cost + "円" : ""}
                            </td>
                        </tr>

                        

                        <tr >


                            <th>希望種別</th>
                            <td >
                                {

                                    this.props.responsedetail.request_tatemono_types ? this.props.responsedetail.request_tatemono_types.split(',').map(function (item) {
                                        return (
                                            <span>
                                                {item === "1" ? "アパート " : ""}
                                                {item === "2" ? "マンション " : ""}
                                                {item === "7" ? "戸建て " : ""}

                                            </span>
                                        )
                                    }) : ""
                                }
                            </td>

                            <th>希望間取</th>
                            <td>
                                {(() => {
                                    if (String(this.props.responsedetail.request_from_plan) === "0") {
                                        return "-～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "2") {
                                        return "1K～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "3") {
                                        return "1DK～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "5") {
                                        return "1LDK～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "8") {
                                        return "2R～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "9") {
                                        return "2DK～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "11") {
                                        return "2LDK～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "15") {
                                        return "3DK～";
                                    } else if (String(this.props.responsedetail.request_from_plan) === "17") {
                                        return "3LDK～";
                                    }
                                })()}

                                {(() => {
                                    if (String(this.props.responsedetail.request_to_plan) === "0") {
                                        return "-";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "2") {
                                        return "1K";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "3") {
                                        return "1DK";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "5") {
                                        return "1LDK";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "8") {
                                        return "2R";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "9") {
                                        return "2DK";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "11") {
                                        return "2LDK";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "15") {
                                        return "3DK";
                                    } else if (String(this.props.responsedetail.request_to_plan) === "17") {
                                        return "3LDK";
                                    } else {
                                        return "";
                                    }
                                })()}
                            </td>

                        </tr>

                        <tr style={{ display: (this.props.responsedetail.areas || this.props.responsedetail.request_chomei) ? '' : 'none' }}>
                            <th>地域</th>
                            <td colSpan="3">
                                {

                                    this.props.responsedetail.areas ? this.props.responsedetail.areas.split(',').map(function (item, key) {
                                        return (
                                            <span key={key}>
                                                {item === "1" ? "高松市中心部," : ""}
                                                {item === "2" ? "レインボー・サンフラワー周辺," : ""}
                                                {item === "3" ? "高松市南部," : ""}
                                                {item === "4" ? "高松市東部," : ""}
                                                {item === "5" ? "高松市西部," : ""}
                                                {item === "6" ? "香川町　三木町," : ""}
                                                {item === "7" ? "庵治町・牟礼町," : ""}
                                                {item === "8" ? "高松市全域," : ""}
                                            </span>
                                        )
                                    }) : ""
                                }

                                {this.props.responsedetail.request_chomei && this.props.chomeis.length > 0 ? this.props.responsedetail.request_chomei.split(',').map(function (item) {

                                    return parseInt(item) > 0 ? this.props.chomeis.filter(a => a.id === parseInt(item))[0].townName : (item === "-1" ? "東かがわ市" : (item === "-2" ? "さぬき市" : ""))

                                }, this).join(',') : ""
                                }
                            </td>
                        </tr>


                        <tr style={{ display: this.props.responsedetail.request_shougakkou ? '' : 'none' }}>
                            <th>小学校区</th>
                            <td colSpan="3">


                                {this.props.responsedetail.request_shougakkou ? this.props.responsedetail.request_shougakkou.split(',').map(function (item) {

                                    return this.props.shougakkous.filter(a => a.shougakkouNo === parseInt(item))[0] ? this.props.shougakkous.filter(a => a.shougakkouNo === parseInt(item))[0].shougakkouName : ""

                                }, this).join(',') : ""
                                }
                            </td>
                        </tr>

                        <tr style={{ display: this.props.responsedetail.request_chuugakkou ? '' : 'none' }}>
                            <th>中学校区</th>
                            <td colSpan="3">
                                {this.props.responsedetail.request_chuugakkou ? this.props.responsedetail.request_chuugakkou.split(',').map(function (item) {

                                    return this.props.chuugakkous.filter(a => a.chuugakkouNo === parseInt(item))[0] ? this.props.chuugakkous.filter(a => a.chuugakkouNo === parseInt(item))[0].chuugakkouName : ""

                                }, this).join(',') : ""
                                }
                            </td>
                        </tr>






                        <tr style={{ display: this.props.responsedetail.response_requests ? '' : 'none' }}>
                            <th>こだわり</th>
                            <td colSpan="3">


                                {this.props.responsedetail.response_requests ? this.props.responsedetail.response_requests.split(',').map(function (value, index) {
                                    for (var stationery in this) {
                                        if (this[stationery].setsubiNo === Number(value)) return this[stationery].setsubiName;
                                    }
                                }, this.props.setsubi_master).join(',') : "" //第2引数にオブジェクトを指定
                                }
                            </td>
                        </tr>

                    </tbody>
                </table>

            </div>
        );
    }
}




//containers
const mapStateToProps = state => {
    return {
        // responseedit: state.responsesReducer.responseedit,
        //customerdetail: state.customersReducer.customerdetail,
        // responsedetail: state.responsesReducer.responsedetail,
        rentMMedeas: state.masterReducer.rentMMedeas,
        users: state.masterReducer.users,
        rentAgents: state.masterReducer.rentAgents,
        rentMReasons: state.masterReducer.rentMReasons,
        shops: state.masterReducer.shops,
        setsubi_master: state.masterReducer.setsubi_master,
        chomeis: state.masterReducer.chomeis,
        shougakkous: state.masterReducer.shougakkous,
        chuugakkous: state.masterReducer.chuugakkous,

        user_details: state.masterReducer.user_details,
        rent_m_agents: state.responsesReducer.rent_m_agents,
        response_preparation_sheet_loading: state.responsesReducer.response_preparation_sheet_loading,

        rent_m_schools: state.responsesReducer.rent_m_schools,

    }
}
function mapDispatchToProps(dispatch) {
    return {
        responseeditchange(state) {
            dispatch(Responses.responseeditchange(state))
        },
        open_response_edit(state) {
            dispatch(Responses.open_response_edit(state))
        },
        download_response_preparation_sheet(state) {
            dispatch(Responses.download_response_preparation_sheet(state))
        },

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResponseInfoDisp);




